import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import mixpanel from 'mixpanel-browser';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import './index.css'
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import "@fontsource/montserrat/100.css"
import "@fontsource/montserrat/200.css"
import "@fontsource/montserrat/300.css"
import "@fontsource/montserrat/400.css"
import "@fontsource/montserrat/500.css"
import "@fontsource/montserrat/600.css"
import "@fontsource/montserrat/700.css"
import "@fontsource/montserrat/800.css"
import "@fontsource/montserrat/900.css"

mixpanel.init('13267ea88f422a99636c878b0d7c9827', { debug: false });
mixpanel.track('Sign up');
const root = ReactDOM.createRoot(document.getElementById('root'));

Sentry.init({
  dsn: "https://3a868b5dde0e4c979b29ad13535ec1d5@o4504770973335552.ingest.sentry.io/4504770974842880",
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
  integrations: [new BrowserTracing()],
});

const theme = extendTheme({
  colors: {
    primary: {
     main: "#FFB088",
     50: "#FFB088",
     100: "#FFB088",
     200: "#FFB088",
     300: "#FFB088",
     400: "#FFB088",
     500: "#FFB088",
     600: "#FFB088",
     700: "#FFB088",
     800: "#FFB088",
     900: "#FFB088"
   }
 },
 breakpoints: {
  sm: '320px', //MOBILE
  preprexsm: '335px', //Breakpoint especificamente hecho para el navbar fixed de tareas de participantes
  prexsm: '378px', //Breakpoint especificamente hecho para el navbar fixed de tareas de participantes
  xsm: '390px',
  prexxsm: '432px', //Breakpoint especificamente hecho para el navbar fixed de tareas de participantes
  xxsm: '480px', //MOBILE
  xxxsm: '511px', //Breakpoint especificamente hecho para el navbar fixed de tareas de participantes
  premd: '600px', //MOBILE
  prepremd: '624px', //Breakpoint especificamente hecho para el navbar fixed de tareas de participantes
  md: '768px', //TABLET
  xmd: '900px', //TABLET
  xxmd: '930px', //Breakpoint especificamente hecho para el navbar fixed de tareas de participantes
  lg: '1201px', //DESKTOP
  xl: '1400px', //DESKTOP
  '2xl': '1536px', //DESKTOP
},
fonts: {
  heading: `'Montserrat', sans-serif`,
  body: `'Montserrat', sans-serif`,
},
});

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);




root.render(
  <ChakraProvider theme={theme}>
    <App />
  </ChakraProvider>,
)
