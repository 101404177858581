import { useState, useEffect } from 'react'
import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import Register from "./front/pages/Register";
import { AuthProvider } from "./front/context/authContext";
import { TestProvider } from "./front/context/testContext";
import { TestDbProvider } from './front/context/testsDbContext'
import { ProtectedTest } from "./front/components/ProtectedTest";
import SitioEnConstruccion from './front/pages/SitioEnConstruccion';
import RegisterUx from './front/pages/RegisterUx';
import TestFinalizado from './front/pages/TestFinalizado';
import ComprobacionTestPersonalizado from './front/pages/ComprobacionTestPersonalizado';
import TestComponent from './front/components/Tests/TestComponent';
import SizeErrorPage from './front/pages/SizeErrorPage';
import ErrorCompartirPantalla from './front/pages/ErrorCompartirPantalla';



function App() {

  const [width, setWidth] = useState(window.innerWidth);
  const [deviceType, setDeviceType] = useState('');



  useEffect(() => {


    function handleResize() {
      
      const isMobile = window.screen.width < 1024 && window.screen.height < 1024;
      const deviceType = isMobile ? 'mobile' : 'desktop';

      setDeviceType(deviceType);
      setWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <AuthProvider>
      <TestProvider>
        <TestDbProvider>
          <Router>

            <Routes>


              <Route path='/registro/:pais' element={<Register />} />
              <Route path='/registro' element={<Navigate to='/registro/argentina' />} />
              <Route path='/registro-ux' element={<RegisterUx />} />
              <Route path='/registracion' element={<SitioEnConstruccion />} />


              <Route path='/:id' element={<ComprobacionTestPersonalizado previsualizador={false} />}/>
              {/* <Route path='/:id' element={<Comprobacion />}/> */}

              <Route path='/t/:id/' element={
                <ProtectedTest>
                  {width > 1023 ? <TestComponent /> : <SizeErrorPage />}
                </ProtectedTest>}
              />

              {/* <Route path="/p/:id" element={
                <ProtectedTest>
                  <TestPrototypeComponent />
                </ProtectedTest>
              } /> */}

              <Route path="/previsualizar/:id" element={
                  <ComprobacionTestPersonalizado previsualizador={true} />
              } />

              <Route path='/fin-test' element={<TestFinalizado />} />

              {/* <Route path='/development' element={<Pruebas />} /> */}

              <Route path='/error-compartir-pantalla' element={<ErrorCompartirPantalla />} />

              <Route path="*" element={<SitioEnConstruccion />} />

            </Routes>

          </Router>
        </TestDbProvider>
      </TestProvider>
    </AuthProvider>

  )
}

export default App;
