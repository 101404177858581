import { Flex, Stack, Center, Text } from '@chakra-ui/react'
import React, { useState, useEffect, useRef } from 'react'
//CONTEXT
import { useParams } from 'react-router-dom'
import Recorder from './Recorder'
import Tareas from './Tareas'
import { useTestDbContext } from '../../context/testsDbContext'
import ProgressBarContainer from './ProgressBarContainer'
import NavBarSinUser from '../NavBarSinUser'
import generateRandomCode from '../../functions/randomCode'
import useUnload from '../../hooks/useUnload'
import { useNavigate } from 'react-router-dom'
import { isTypeSupported } from '../../functions/isTypeSupported'
import { getOperatingSystem } from '../../functions/getOperatingSystem'



const TestComponent = () => {

    //STATE
    const [recorder, setRecorder] = useState() // RECORDER PARA GRABAR VIDEO / AUDIO
    const [respuestaTareas, setRespuestaTareas] = useState([]) // ESTADO PARA LAS RESPUESTAS DE LAS TAREAS
    const [test, setTest] = useState() // ESTADO PARA RECUPERAR EL TEST DEL LOCALSTORAGE
    const [idVideo, setIdVideo] = useState(null) // ESTADO PARA EL ID DEL VIDEO
    const [cicleTest, setCicleTest] = useState(0) // ESTADO PARA EL CICLO DEL TEST
    const [cliente, setCliente] = useState('')


    //STATES BANDERAS
    const [access, setAccess] = useState(true) // EL USUARIO TIENE ACCESO AL TEST?
    const [cargando, setCargando] = useState(false) // ESTADO PARA VER SI ESTA CARGANDO
    const [disabledButton, setDisabledButton] = useState(true) // PARA BOTON DE CONTINUAR
    const [disabledButtonAbrirSitioWeb, setDisabledButtonAbrirSitioWeb] = useState(false) // PARA BOTON ABRIR SITIO WEB (TEST 2 PESTAÑAS)
    const [disabledTarea, setDisabledTarea] = useState(false) //PARA TAREAS EN GENERAL, SE DISPARA CUANDO EL TEST SE PAUSA
    const [abrioElSitioWeb, setAbrioElSitioWeb] = useState(false) //PARA VERIFICAR SI EL USUARIO ABRIO EL SITIO WEB
    const [showTasks, setShowTasks] = useState(true) //PARA BARRA DE TAREAS MOBILE


    //STATE HEATMAP (SOLO PROTOTIPO DE FIGMA)
    const [pointsByNodeId, setPointsByNodeId] = useState({}); // SOLO FUNCIONA EN PROTOTIPOS DE FIGMA
    const [linkFigma, setLinkFigma] = useState('') // LINK DE FIGMA

    //CONTEXT
    const { audioR, video, contexto } = useTestDbContext()


    //PARAMS
    const { id } = useParams()


    //USE REF
    const recordingRunning = useRef(false)
    const i = useRef(1)
    const seconds = useRef(0)
    const intervalTime = useRef() //PARA TRANSCRIPCION DE CADA TAREA

    const intervalTimeIframe = useRef() //PARA VIDEO DE MOBILE DE PROTOTIPO
    const secondsIframe = useRef() //PARA VIDEO DE MOBILE DE PROTOTIPO
    const cicleForIframe = useRef(0) //PARA VIDEO DE MOBILE DE PROTOTIPO

    const navigate = useNavigate()

    const remainingTime = useRef(7000);
    const intervalForTimeout = useRef();



    useUnload(async e => {
        e.preventDefault()
        localStorage.removeItem('test')
        i.current = 'final'
        recorder.state === 'recording' && await recorder.stop()
        return e.returnValue
    })

    useEffect(() => {
        const isMobile = getOperatingSystem()

        if (isMobile) {

            const combine = new MediaStream([...audioR.getTracks()],)
            const mimeType = isTypeSupported()

            if (mimeType !== undefined) {
                setRecorder(new MediaRecorder(combine, {
                    mimeType: mimeType,
                    videoBitsPerSecond: 625000,
                    audioBitsPerSecond: 16000,
                }))
            } else {
                //TODO: mostrar mensaje de error
            }

        } else {

            const combine = new MediaStream(
                [...audioR.getTracks(), ...video.getTracks()],)

            const mimeType = isTypeSupported()

            if (mimeType !== undefined) {
                setRecorder(new MediaRecorder(combine, {
                    mimeType: mimeType,
                    videoBitsPerSecond: 625000,
                    audioBitsPerSecond: 16000,
                }))
            } else {
                //TODO: mostrar mensaje de error
            }

        }

    }, [audioR, video])

    useEffect(() => {

        if (recorder) {
            recorder.start()
            recordingRunning.current = true
        }

        return () => {
            if (recorder) {
                i.current = 'final'
                recorder.state === 'recording' && recorder.stop()
            }
        }

    }, [recorder])

    useEffect(() => {
        idVideo === null && setIdVideo(generateRandomCode())
    }, [idVideo])

    useEffect(() => {
        const getTestFromLocalStorage = async () => {

            if (test === undefined) {
                const testFromLocalStorage = JSON.parse(localStorage.getItem('test'))

                if (testFromLocalStorage === null) {
                    setAccess(false)
                    return
                }

                if (testFromLocalStorage.cliente) {
                    setCliente(testFromLocalStorage.cliente)
                } else {
                    setAccess(false)
                }

                if (testFromLocalStorage.contexto) {
                    contexto.current = testFromLocalStorage.contexto
                }

                setTest(testFromLocalStorage)



            }

        }

        getTestFromLocalStorage()

    }, [test, cliente, contexto, id])



    return (
        <>
            <NavBarSinUser />
            {
                access ?
                    <Flex
                        flexDirection={'column'}
                        bgGradient={'linear(to-b,#292643,#41416D)'}
                        color={'white'}
                        minH={'90vh'}
                        align={'center'}
                        justify={'center'}
                        padding={['0px', '0px', '30px', '60px']}
                    >

                        <Center
                            w={'auto'}
                            display={'flex'}
                            flexDirection={'column'}
                        >

                            {recorder && !cargando &&
                                <>

                                    <Recorder
                                        setDisabledButtonAbrirSitioWeb={setDisabledButtonAbrirSitioWeb}
                                        disabledTarea={disabledTarea}
                                        setDisabledTarea={setDisabledTarea}
                                        abrioElSitioWeb={abrioElSitioWeb}
                                        cicleTest={cicleTest}
                                        setDisabledButton={setDisabledButton}
                                        disabledButton={disabledButton}
                                        idVideo={idVideo} recorder={recorder}
                                        recordingRunning={recordingRunning}
                                        i={i}
                                        cliente={cliente}
                                        id={id}
                                        test={test}
                                        intervalTime={intervalTime}
                                        seconds={seconds}
                                        typeTest={test.tipoDeTest}
                                        showTasks={showTasks}
                                        intervalTimeIframe={intervalTimeIframe}
                                        secondsIframe={secondsIframe}
                                        cicleForIframe={cicleForIframe}
                                        respuestaTareas={respuestaTareas}
                                        setRespuestaTareas={setRespuestaTareas}
                                        remainingTime={remainingTime}
                                        intervalForTimeout={intervalForTimeout}

                                    />

                                    <Tareas
                                        disabledButtonAbrirSitioWeb={disabledButtonAbrirSitioWeb}
                                        setDisabledButtonAbrirSitioWeb={setDisabledButtonAbrirSitioWeb}
                                        disabledTarea={disabledTarea}
                                        setDisabledTarea={setDisabledTarea}
                                        abrioElSitioWeb={abrioElSitioWeb}
                                        setAbrioElSitioWeb={setAbrioElSitioWeb}
                                        cicleTest={cicleTest}
                                        setCicleTest={setCicleTest}
                                        disabledButton={disabledButton}
                                        setDisabledButton={setDisabledButton}
                                        test={test}
                                        setTest={setTest}
                                        recorder={recorder}
                                        idVideo={idVideo}
                                        recordingRunning={recordingRunning}
                                        setRecorder={setRecorder}
                                        i={i}
                                        cargando={cargando}
                                        setCargando={setCargando}
                                        intervalTime={intervalTime}
                                        seconds={seconds}
                                        cliente={cliente}
                                        showTasks={showTasks}
                                        setShowTasks={setShowTasks}
                                        respuestaTareas={respuestaTareas}
                                        setRespuestaTareas={setRespuestaTareas}
                                        remainingTime={remainingTime}
                                        intervalForTimeout={intervalForTimeout}
                                        pointsByNodeId={pointsByNodeId}


                                    />
                                </>
                            }

                            {cargando &&
                                <>
                                    <ProgressBarContainer />
                                </>
                            }

                        </Center>

                    </Flex>
                    :
                    <Flex flexDirection={'column'} bgGradient={'linear(to-b,#292643,#41416D)'} color={'white'} minH={'90vh'} align={'center'} justify={'center'} padding={['0px', '0px', '30px', '60px']}>
                        <Stack>
                            <Text fontSize={'2xl'}>No tienes acceso a este test</Text>
                        </Stack>
                    </Flex>
            }
        </>
    )
}

export default TestComponent