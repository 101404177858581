import { Box, Text } from '@chakra-ui/react'
import React, { useEffect } from 'react'

const SiNo = ({
    disabledTarea,
    tareas,
    cicleTest,
    typeTest,
    respuestaTareas,
    setRespuestaTareas,
    setDisabledButton,
    disabledButton
}) => {


    const handleChange = (e) => {
        switch (e.target.tagName) {
            case 'INPUT':
                document.querySelector('.selected') && document.querySelector('.selected').classList.remove('selected')
                e.target.parentElement.classList.add('selected')
                setRespuestaTareas(respuestaTareas, respuestaTareas[cicleTest] = e.target.value)
                disabledButton && setDisabledButton(false)
                break;
            case 'LABEL':
                break;
            case 'DIV':
                document.querySelector('.selected') && document.querySelector('.selected').classList.remove('selected')
                e.target.classList.add('selected')
                e.target.children[0].checked = true;
                setRespuestaTareas(respuestaTareas, respuestaTareas[cicleTest] = e.target.children[0].value)
                disabledButton && setDisabledButton(false)
                break;
            default:
                break;
        }
    }


    useEffect(() => {
        document.querySelectorAll('input').forEach(e => e.checked = false)
    }, [cicleTest])


    return (
        <>
            <Text
                w={{ sm: '100%', md: '100%', lg: '600px', xl: '600px' }}
                fontSize={'1rem'}
                fontWeight={'500'}
                color={'#6C5280'}
                lineHeight={'1.5rem'}
                style={{ whiteSpace: "break-spaces" }}
                textAlign={typeTest === 'prototipo-de-figma' ? 'left' : 'center'}
                disabled={disabledTarea}
                _disabled={{ opacity: '0.5' }}
            >
                {tareas[cicleTest].text}
            </Text>
            <Box
                disabled={disabledTarea}
                _disabled={{ opacity: '0.5' }}
                id="yesornot"
            >
                <div onClick={handleChange}>
                    <input name="yesornot" type="radio" id="si" value="Sí" />
                    <label htmlFor="si">Sí</label>
                </div>
                <div onClick={handleChange}>
                    <input name="yesornot" type="radio" id="no" value="No" />
                    <label htmlFor="no">No</label>
                </div>
            </Box>
        </>
    )
}

export default SiNo