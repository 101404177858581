import { Center, Flex } from '@chakra-ui/react'
import React from 'react'
import '../css/eclipseLoader.css'


const Loader = ({ color }) => {
    return (
        <Flex
            flexDirection={'column'}
            h={'10vh'}
            color={color ? color : 'white'}
        >
            <Center h={'10vh'}>
                <span className="loader"></span>
            </Center>
        </Flex>
    )
}

export default Loader