import { Box, VStack, Button, HStack, Spinner, Flex } from '@chakra-ui/react'
import React, { useState, useRef, useEffect } from 'react'
//CONTEXT
import { useTestDbContext } from '../../context/testsDbContext'
import QuizTarea from './QuizTarea'
import ArrowIconDown from '../../icons/svg/ArrowIconDown'
import ArrowIconUp from '../../icons/svg/ArrowIconUp'


const Tareas = ({
    // recordingRunning,
    recorder,
    i,
    setCargando,
    test,
    setDisabledButton,
    disabledButton,
    disabledButtonAbrirSitioWeb,
    cicleTest,
    setCicleTest,
    setAbrioElSitioWeb,
    setDisabledTarea,
    disabledTarea,
    intervalTime,
    seconds,
    showTasks,
    setShowTasks,
    setRespuestaTareas,
    respuestaTareas,
    remainingTime,
    intervalForTimeout,
    pointsByNodeId,
    setPointsByNodeId,
    textForBtnHide,
    setTextForBtnHide,
    arrayOrden,
    textBtnContinue,
    setTextBtnContinue,
    setHidden,
    renderForMobile,
    nodesAndTimeOfFigma,
    isMobile,
    dataAvailableSource,
    hideTasks,
    linkProto,
    soyTareaPrototipo

}) => {

    const { acumTimerTarea, setAcumTimerTarea, cantResponses } = useTestDbContext()
    const [tareas, setTareas] = useState([])
    const [timerTarea, setTimerTarea] = useState()

    const [isPaused, setIsPaused] = useState(false)
    // const [timePaused, setTimePaused] = useState(null)
    const timePaused = useRef(null)
    const acumTimePaused = useRef(null)

    const { configuracion } = test
    const { tipoPrueba } = configuracion
    const typetest = tipoPrueba
    const URL_REGEX = /https:\/\/([\w\.-]+\.)?figma.com\/(file|proto)\/([0-9a-zA-Z]{22,128})(?:\/.*)?$/

    const intervalForTranscriptionTask = useRef()
    const secondsForTranscriptionTask = useRef(0)
    let starting = ''


    function startTimer() {
        intervalTime.current = setInterval(function () {
            seconds.current++
            // var minutes = Math.floor(seconds / 60);
            let remainingSeconds = seconds.current % 60;
            if (remainingSeconds < 10) {
                remainingSeconds = "0" + remainingSeconds;
            }
        }, 1000);
    }

    const handleTareas = () => {
        cicleTest === 0 && startTimer()
        setTimerTarea(new Date())

        const handleTextTareas = test.tareas.map((element) => {
            element.text = element.text.replaceAll('\\n', '\n')
            return element
        })

        setTareas(handleTextTareas)
    }

    const savePointsForActionTask = async () => {
        const { tareas } = test

        if (tareas[cicleTest].tipoTarea === 'accion' || tareas[cicleTest].tipoTarea === 'pruebaFigma' || tareas[cicleTest].tipoTarea === 'pruebaPrototipo') {
            tareas[cicleTest].axisHeatmap = pointsByNodeId
            setPointsByNodeId({})
        }
    }

    const saveOrderFromNodes = async () => {
        const { tareas } = test
        tareas[cicleTest].order = arrayOrden.current
    }

    const saveNodes = (test, cicleTest) => {
        if (nodesAndTimeOfFigma.current.length > 0) nodesAndTimeOfFigma.current[nodesAndTimeOfFigma.current.length - 1].end = seconds.current

        // const starting = test.tareas[cicleTest].urlFigma && new URL(test.tareas[cicleTest].urlFigma).searchParams.get('starting-point-node-id')

        if (test.tareas[cicleTest].tipoTarea === 'pruebaPrototipo') {
            starting = test.tareas[cicleTest].urlFigma && new URL(linkProto).searchParams.get('starting-point-node-id')
        }

        const objectForRenderMobile = {
            init: cicleTest === 0 ? 0 : renderForMobile.current[cicleTest - 1].end,
            end: seconds.current,
            typeTask: test.tareas[cicleTest].tipoTarea,
            inputTask: test.tareas[cicleTest].text,
            responseTask: respuestaTareas[cicleTest] || 'respuesta verbal',
            nodeIds: test.tareas[cicleTest].tipoTarea === 'pruebaPrototipo' ? (nodesAndTimeOfFigma.current.length == 0 ? [{ nodeId: starting, init: 0 }] : nodesAndTimeOfFigma.current) : [],
            protoId: test.tareas[cicleTest].tipoTarea === 'pruebaPrototipo' ? linkProto.match(URL_REGEX)[3] : '',
        }
        renderForMobile.current.push(objectForRenderMobile)
        nodesAndTimeOfFigma.current = []
    }

    const handleCicle = async (e) => {
        const { tareas } = test;
        if (isMobile) saveNodes(test, cicleTest)
        await handleTimer()

        // if(tareas.length - 1 === cicleTest){
        if (e.target.dataset.condition === 'finish') {
            // await handleTimer()
            await savePointsForActionTask()
            await saveOrderFromNodes()
            arrayOrden.current = []

            // recordingRunning.current = false
            dataAvailableSource.current = 'stop'
            cantResponses.current = i.current
            i.current = 'final'
            if (e.target.textContent.toLowerCase() === 'detener tarea') tareas[cicleTest].resultado = 'abandono'

            setCargando(true)
            await recorder.state === 'recording' && recorder.stop()
            setHidden(true)
            setCicleTest(cicleTest + 1)

        } else {
            // await handleTimer()
            remainingTime.current = 7000
            removeFocusOrSelectedClass('focus')
            removeFocusOrSelectedClass('selected')
            await savePointsForActionTask()
            await saveOrderFromNodes()
            if (tareas[cicleTest].tipoTarea === 'pruebaPrototipo') arrayOrden.current = []
            if (e.target.textContent.toLowerCase() === 'detener tarea') tareas[cicleTest].resultado = 'abandono'
            setHidden(true)
            setTimerTarea(new Date())
            setCicleTest(cicleTest + 1)
            setAbrioElSitioWeb(false)
            setDisabledButton(true)
        }
    }

    const handleTimer = async () => {
        const time = new Date();
        const timeDiff = new Date();


        timeDiff.setHours(
            time.getHours() - timerTarea.getHours(),
            time.getMinutes() - timerTarea.getMinutes(),
            time.getSeconds() - timerTarea.getSeconds(),
            time.getMilliseconds() - timerTarea.getMilliseconds()
        )

        if (acumTimePaused.current !== null) {

            const timeDiffWithoutTimePaused = new Date();

            timeDiffWithoutTimePaused.setHours(
                timeDiff.getHours() - acumTimePaused.current.getHours(),
                timeDiff.getMinutes() - acumTimePaused.current.getMinutes(),
                timeDiff.getSeconds() - acumTimePaused.current.getSeconds(),
                timeDiff.getMilliseconds() - acumTimePaused.current.getMilliseconds(),
            )

            setAcumTimerTarea(acumTimerTarea, acumTimerTarea[cicleTest] = `${timeDiffWithoutTimePaused.getHours()}:${timeDiffWithoutTimePaused.getMinutes()}:${timeDiffWithoutTimePaused.getSeconds()}:${timeDiffWithoutTimePaused.getMilliseconds()}`)

            timePaused.current = null
            acumTimePaused.current = null

        } else {
            setAcumTimerTarea(acumTimerTarea, acumTimerTarea[cicleTest] = `${timeDiff.getHours()}:${timeDiff.getMinutes()}:${timeDiff.getSeconds()}:${timeDiff.getMilliseconds()}`)

        }


        // setTimerTarea(new Date())
        // setAcumTimerTarea(acumTimerTarea, acumTimerTarea[cicleTest] = `${timeDiff.getHours()}:${timeDiff.getMinutes()}:${timeDiff.getSeconds()}:${timeDiff.getMilliseconds()}`)
    }

    const handleAbrirPaginaWeb = () => {
        window.open(tareas[cicleTest].link)
        setAbrioElSitioWeb(true)
    }

    const removeFocusOrSelectedClass = (clase) => {
        const focusOrSelected = document.querySelectorAll(`.${clase}`)
        focusOrSelected.forEach(element => {
            element.classList.remove(clase)
        })
    }


    useEffect(() => {

        if (recorder) {

            if (recorder.state === 'inactive') return


            if (recorder.state === 'paused') {
                setIsPaused(true)
            } else if (recorder.state === 'recording') {
                isPaused && setIsPaused(false)
            }


        }
    }, [recorder ? recorder.state : ''])


    useEffect(() => {
        if (isPaused) {
            if (recorder.state === 'inactive') return

            timePaused.current = new Date()


        } else {
            if (recorder.state === 'inactive') return

            const time = new Date()
            const timeDiff = new Date();



            timeDiff.setHours(
                time?.getHours() - timePaused.current?.getHours(),
                time?.getMinutes() - timePaused.current?.getMinutes(),
                time?.getSeconds() - timePaused.current?.getSeconds(),
                time?.getMilliseconds() - timePaused.current?.getMilliseconds()
            )


            if (acumTimePaused.current) {
                //TENGO QUE SUMAR EL TIEMPO QUE SE PAUSO A LO ACUMULADO

                acumTimePaused.current.setHours(
                    acumTimePaused.current.getHours() + timeDiff.getHours(),
                    acumTimePaused.current.getMinutes() + timeDiff.getMinutes(),
                    acumTimePaused.current.getSeconds() + timeDiff.getSeconds(),
                    acumTimePaused.current.getMilliseconds() + timeDiff.getMilliseconds(),
                )


            } else {
                //NO HAY TIEMPO ACUMULADO DE PAUSA

                acumTimePaused.current = timeDiff

            }

        }

    }, [isPaused])

    return (
        <>
            {test
                ?
                (<>

                    {typetest === 'website' &&
                        <VStack
                            minW={'900px'}
                            minH={'500px'}
                            paddingX={'100px'}
                            paddingY={'40px'}
                            bgGradient={'linear(to-b,#7A628C,transparent)'}
                            borderRadius={'30px'}
                            justifyContent={'space-around'}>

                            <>
                                {test ? (
                                    (tareas.length > 0 && tareas !== cicleTest) ?
                                        <QuizTarea
                                            recorder={recorder}
                                            tareas={tareas}
                                            cicleTest={cicleTest}
                                            setDisabledButton={setDisabledButton}
                                            test={test}
                                            disabledButton={disabledButton}
                                            setDisabledTarea={setDisabledTarea}
                                            disabledTarea={disabledTarea}
                                            setRespuestaTareas={setRespuestaTareas}
                                            respuestaTareas={respuestaTareas}
                                            remainingTime={remainingTime}
                                            intervalForTimeout={intervalForTimeout}
                                            setTextBtnContinue={setTextBtnContinue}
                                        />
                                        :
                                        handleTareas()
                                ) :
                                    <Spinner />}

                                <HStack w={'100%'} display={'flex'} justifyContent={'space-around'} marginY={'80px'}>
                                    {tareas.length > 0 && (

                                        tareas[cicleTest].link &&

                                        <Button
                                            px={'35px'}
                                            borderRadius={'30px'}
                                            marginY={'20px'}
                                            marginX={'20px'}
                                            w={'240px'}
                                            height={'45px'}
                                            fontSize={'20px'}
                                            colorScheme={'orange'}
                                            backgroundColor={'transparent'}
                                            disabled={disabledButtonAbrirSitioWeb}
                                            onClick={() => { handleAbrirPaginaWeb() }}>
                                            Abrir sitio web →
                                        </Button>


                                    )}

                                    <Box>
                                        <Button
                                            px={'35px'}
                                            borderRadius={'30px'}
                                            marginY={'20px'}
                                            marginX={'20px'}
                                            w={'240px'}
                                            height={'45px'}
                                            fontSize={'20px'}
                                            colorScheme={'orange'}
                                            backgroundColor={'#E5773F'}
                                            data-condition={cicleTest === tareas.length - 1 ? 'finish' : 'next'}
                                            disabled={disabledButton}
                                            onClick={tareas.length > 0 ? handleCicle : handleTareas}
                                        // _hover={
                                        //     { background: 'rgb(150 6 59)' }
                                        // }
                                        >Continuar</Button>
                                    </Box>
                                </HStack>
                            </>

                        </VStack>}


                    {typetest === 'prototipo-de-figma' &&
                        <Flex
                            w={showTasks ? { sm: '80%', xsm: '80%', xxsm: '80%', md: '70%', lg: soyTareaPrototipo ? 'auto' : '600px', xl: soyTareaPrototipo ? 'auto' : '600px', base: 'auto' } : 'auto'}
                            h={'max-content'}
                            flexDirection={{ sm: 'column', md: 'column', lg: soyTareaPrototipo ? 'row' : 'column', xl: soyTareaPrototipo ? 'row' : 'column', base: 'column' }}
                            justifyContent={'space-around'}
                            minHeight={test.tipoDeTest === 'prototipo' ? '100%' : 'auto'}
                            alignItems={soyTareaPrototipo ? 'center' : 'flex-start'}
                            padding={showTasks ? '0px' : { md: '13px', lg: '13px', xl: '13px', base: '13px' }}
                            marginLeft={'0px'}
                            translate='no'
                            className='tareas-and-buttons'
                        >
                            <Box
                                w={'100%'}
                                className={'tareas'}
                                alignSelf={'flex-start'}
                                h={'90%'}
                                flexDirection={'column'}
                                justifyContent={'flex-start'}
                                alignItems={'flex-start'}
                                disabled={disabledTarea}
                                marginBottom={soyTareaPrototipo ? (showTasks ? '20px' : '0px') : '60px'}
                                _disabled={{ opacity: '0.5', cursor: 'not-allowed', pointerEvents: 'none' }}
                                transition={'0.5s'}
                                animation={showTasks ? 'showTareas 0.5s forwards' : 'hideTareas 0.5s forwards'}
                            >

                                {(tareas.length > 0 && tareas !== cicleTest) ?
                                    <QuizTarea
                                        recorder={recorder}
                                        tareas={tareas}
                                        cicleTest={cicleTest}
                                        setDisabledButton={setDisabledButton}
                                        test={test}
                                        disabledButton={disabledButton}
                                        setDisabledTarea={setDisabledTarea}
                                        disabledTarea={disabledTarea}
                                        setRespuestaTareas={setRespuestaTareas}
                                        respuestaTareas={respuestaTareas}
                                        typeTest={typetest}
                                        remainingTime={remainingTime}
                                        intervalForTimeout={intervalForTimeout}
                                        setTextBtnContinue={setTextBtnContinue}
                                        showTasks={showTasks}
                                        hideTasks={hideTasks}
                                        textForBtnHide={textForBtnHide}
                                        textBtnContinue={textBtnContinue}
                                        handleCicle={handleCicle}
                                        handleTareas={handleTareas}
                                    />
                                    :
                                    handleTareas()}
                            </Box>


                            {/* <Flex
                                w={{ sm: '100%', md: '100%', lg: showTasks ? 'auto' : '50%', xl: 'auto', base: 'auto' }}
                                h={'10%'}
                                display={'flex'}
                                margin={'0px'}
                                flexDirection={'row'}
                                justifyContent={{ sm: showTasks ? 'space-between' : 'flex-start', md: showTasks ? 'space-between' : 'center', lg: 'center', xl: 'center', '2xl': 'flex-start' }}
                                alignItems={'baseline'}
                                className='button'
                                disabled={disabledTarea}
                                _disabled={{ opacity: '0.5', cursor: 'not-allowed', pointerEvents: 'none', margin: '0px' }}
                            >

                                {tareas &&
                                    tareas.length > 0 &&
                                    tareas[cicleTest] &&
                                    tareas[cicleTest].tipoTarea === 'pruebaPrototipo' &&

                                    <Box
                                        marginLeft={showTasks ? '0px' : { sm: '15%', xsm: '20%', xxsm: '25%', premd: '30%', md: '0px', lg: '30%', xl: '10px', base: '10px' }}
                                        marginRight={showTasks ? { base: '10px' } : '0px'}
                                    >
                                        <Button
                                            px={'15px'}
                                            h={'40px'}
                                            w={showTasks ? { sm: '108px', xxsm: '128px', premd: '128px', md: '128px', lg: '240px', xl: 'auto', base: 'auto' } : { sm: '128px', xxsm: '128px', premd: '128px', md: '128px', lg: '240px', xl: 'auto', base: 'auto' }}
                                            maxW={{ sm: '128px', xxsm: '128px', premd: '128px', md: 'none', lg: 'none', xl: 'none', base: '128px' }}
                                            background={'rgb(211, 189, 209, 30%)'}
                                            borderRadius={'30px'}
                                            border={'none'}
                                            color={'white'}
                                            display={{ sm: 'flex', md: 'flex', lg: 'none', xl: 'none' }}
                                            fontSize={{ sm: '15px', md: '15px', lg: '18px', xl: '18px', base: '15px' }}
                                            fontWeight={'600'}
                                            colorScheme={'none'}
                                            rightIcon={showTasks ? <ArrowIconUp w={'14px'} h={'6px'} /> : <ArrowIconDown w={'14px'} h={'6px'} />}
                                            _hover={{ background: 'rgb(211, 189, 209, 20%)', transition: '0.5s' }}
                                            onClick={hideTasks}
                                        >
                                            {textForBtnHide}
                                        </Button>
                                    </Box>}

                                <Box
                                    width={'100%'}
                                >
                                    <Button
                                        w={{ sm: '110px', xsm: '150px', md: '180px', lg: '200px', xl: '200px', base: 'auto' }}
                                        height={'50px'}
                                        display={showTasks ? 'inline-flex' : 'none'}
                                        borderRadius={'30px'}
                                        fontSize={{ sm: '15px', md: '15px', lg: '1.125rem', xl: '1.125rem', base: '15px' }}
                                        fontWeight={'600'}
                                        backgroundColor={'#F28853'}
                                        data-condition={cicleTest === tareas.length - 1 ? 'finish' : 'next'}
                                        disabled={disabledButton}
                                        _disabled={{ cursor: 'not-allowed', backgroundColor: '#EAE9ED', color: '#9A96A7' }}
                                        _hover={!disabledButton && { backgroundColor: '#F57535', transition: '0.5s' }}
                                        _active={!disabledButton && { backgroundColor: '#F57535', transition: '0.5s' }}
                                        _activeLink={!disabledButton && { backgroundColor: '#F57535', transition: '0.5s' }}
                                        _focus={!disabledButton && { backgroundColor: '#F57535', transition: '0.5s' }}
                                        onClick={tareas.length > 0 ? handleCicle : handleTareas}
                                        id={'btn-continue'}
                                        alignSelf={'center'}
                                        animation={showTasks ? 'showTareas 0.5s forwards' : 'hideTareas 0.5s forwards'}
                                    >
                                        {textBtnContinue}
                                    </Button>
                                </Box>
                            </Flex> */}

                        </Flex>

                    }

                </>)
                :
                <Spinner />
            }
        </>
    )
}

export default Tareas