const PauseIcon = ({ w, h }) => {


    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={w || "18"} height={h || "18"} viewBox="0 0 18 18" fill="none">
            <path d="M7.95537 5.13281H6.29883V12.6907H7.95537V5.13281Z" fill="#6C5280" />
            <path d="M11.3665 5.13281H9.70996V12.6907H11.3665V5.13281Z" fill="#6C5280" />
            <path d="M16.6946 8.91132C16.6946 13.2617 13.1679 16.7883 8.81757 16.7883C4.46721 16.7883 0.94054 13.2617 0.94054 8.91132C0.94054 4.56096 4.46721 1.03429 8.81757 1.03429C13.1679 1.03429 16.6946 4.56096 16.6946 8.91132Z" stroke="#6C5280" strokeWidth="1.88108" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default PauseIcon