import React, { useState, useEffect } from 'react'
import {
    Radio,
    RadioGroup,
    Text,
    Textarea,
    Box,
    Input,
    Flex,
    Spinner,
    Button,
} from '@chakra-ui/react'
import '../../css/quizTarea.css'
import '../../css/multipleChoice.css'
import TextComponent from './componentForQuizTarea/TextComponent'
import RespuestaEscrita from './componentForQuizTarea/RespuestaEscrita'
import MultipleChoice from './componentForQuizTarea/MultipleChoice'
import SiNo from './componentForQuizTarea/SiNo'
import ArrowIconUp from '../../icons/svg/ArrowIconUp'
import ArrowIconDown from '../../icons/svg/ArrowIconDown'
import { isMobile } from 'react-device-detect'

const QuizTarea = ({
    tareas,
    cicleTest,
    setDisabledButton,
    test,
    recorder,
    disabledButton,
    disabledTarea,
    respuestaTareas,
    setRespuestaTareas,
    typeTest,
    remainingTime,
    intervalForTimeout,
    setTextBtnContinue,
    showTasks,
    hideTasks,
    textForBtnHide,
    textBtnContinue,
    handleCicle,
    handleTareas
}) => {


    // const [stateTextarea, setStateTextarea] = useState('')
    const [stateWidthTags, setStateWidthTags] = useState('100%')
    const [stateHeightTags, setStateHeightTags] = useState('100%')
    // const [responseMultipleChoice, setResponseMultipleChoice] = useState([])

    const handleValueOpinionScale = (e) => {
        if (e.target.tagName === 'DIV') {
            setRespuestaTareas(respuestaTareas, respuestaTareas[cicleTest] = e.target.id)
        } else if (e.target.tagName === 'P') {
            setRespuestaTareas(respuestaTareas, respuestaTareas[cicleTest] = e.target.parentElement.id)
        }

    }

    // const handleTaskRespuestaMultipleChoice = (task, value, respuesta) => {
    //     const existe = respuesta.includes(value);

    //     if (existe) {
    //         respuesta = respuesta.filter(e => e !== value);
    //     } else {
    //         respuesta.push(value);
    //     }

    //     setResponseMultipleChoice(respuesta);
    //     setRespuestaTareas(respuestaTareas, respuestaTareas[cicleTest] = respuesta);
    //     setDisabledButton(respuesta.length === 0);

    // }

    const handleChange = (e) => {
        switch (tareas[cicleTest].tipoTarea) {

            case 'escalaOpinion':
                handleClassOpinion(e)
                handleValueOpinionScale(e)
                recorder.state === 'recording' && setDisabledButton(false)
                break;
            // case 'multipleChoiceCheckbox':
            //     console.log(disabledButton)
            //     switch (e.target.tagName) {
            //         case 'INPUT':
            //             handleTaskRespuestaMultipleChoice(tareas[cicleTest], e.target.value, responseMultipleChoice)
            //             if (e.target.checked) {
            //                 e.target.parentElement.classList.add('selected')
            //             } else {
            //                 e.target.parentElement.classList.remove('selected')
            //             }

            //             break;
            //         case 'LABEL':
            //             break;
            //         case 'DIV':
            //             handleTaskRespuestaMultipleChoice(tareas[cicleTest], e.target.children[0].value, responseMultipleChoice)
            //             if (e.target.children[0].checked) {
            //                 e.target.children[0].checked = false;
            //                 e.target.classList.remove('selected')

            //             } else {
            //                 e.target.children[0].checked = true;
            //                 e.target.classList.add('selected')
            //             }

            //             break;
            //         default:
            //             break;
            //     }

            //     break;
            // case 'multipleChoiceRadio':
            //     switch (e.target.tagName) {
            //         case 'INPUT':
            //             document.querySelector('.selected') && document.querySelector('.selected').classList.remove('selected')
            //             e.target.parentElement.classList.add('selected')
            //             setRespuestaTareas(respuestaTareas, respuestaTareas[cicleTest] = e.target.value)
            //             setStateTextarea(e.target.value)
            //             disabledButton && setDisabledButton(false)
            //             break;
            //         case 'LABEL':
            //             break;
            //         case 'DIV':
            //             document.querySelector('.selected') && document.querySelector('.selected').classList.remove('selected')
            //             e.target.classList.add('selected')
            //             e.target.children[0].checked = true;
            //             setRespuestaTareas(respuestaTareas, respuestaTareas[cicleTest] = e.target.children[0].value)
            //             setStateTextarea(e.target.children[0].value)
            //             disabledButton && setDisabledButton(false)
            //             break;
            //         default:
            //             break;
            //     }

            //     break;
            // case 'respuestaEscritaTextArea':
            //     setRespuestaTareas(respuestaTareas, respuestaTareas[cicleTest] = e.target.value)
            //     setStateTextarea(e.target.value)

            //     if (recorder.state === 'recording') {
            //         if (e.target.value !== '') {
            //             setDisabledButton(false)
            //         } else {
            //             setDisabledButton(true)
            //         }
            //     }

            //     break;
            case 'siNo':

                switch (e.target.tagName) {
                    case 'INPUT':
                        document.querySelector('.selected') && document.querySelector('.selected').classList.remove('selected')
                        e.target.parentElement.classList.add('selected')
                        setRespuestaTareas(respuestaTareas, respuestaTareas[cicleTest] = e.target.value)
                        // setStateTextarea(e.target.value)
                        disabledButton && setDisabledButton(false)
                        break;
                    case 'LABEL':
                        break;
                    case 'DIV':
                        document.querySelector('.selected') && document.querySelector('.selected').classList.remove('selected')
                        e.target.classList.add('selected')
                        e.target.children[0].checked = true;
                        setRespuestaTareas(respuestaTareas, respuestaTareas[cicleTest] = e.target.children[0].value)
                        // setStateTextarea(e.target.children[0].value)
                        disabledButton && setDisabledButton(false)
                        break;
                    default:
                        break;
                }

                break;
            // case 'respuestaEscritaInput':
            // setRespuestaTareas(respuestaTareas, respuestaTareas[cicleTest] = e.target.value)
            // setStateTextarea(e.target.value)
            // if (recorder.state === 'recording') {
            //     if (e.target.value !== '') {
            //         setDisabledButton(false)
            //     } else {
            //         setDisabledButton(true)
            //     }
            // }
            // break;
            default:
                break;
        }

    }

    const handleClassOpinion = (e) => {
        for (let element of document.getElementsByClassName('focus')) {
            element.classList.remove('focus')
        }
        if (e.target.tagName === 'DIV') {
            e.target.classList.add('focus')
        } else {
            e.target.parentElement.classList.add('focus')
        }
    }

    const handleAlign = (index) => {
        switch (index) {
            case 0:
                const alignLeft = { sm: 'center', md: 'left', lg: 'left', xl: 'left', base: 'center' }
                return alignLeft
            case 1:

                const alignCenter = { sm: 'center', md: 'center', lg: 'center', xl: 'center', base: 'center' }

                return alignCenter
            case 2:

                const alignRight = { sm: 'center', md: 'right', lg: 'right', xl: 'right', base: 'center' }

                return alignRight
            default:
                break;
        }
    }

    const handleWidth = () => {
        if (document.querySelector('#opinionScale')) {
            setStateWidthTags(document.querySelector('#opinionScale').clientWidth)
        }
    }

    useEffect(() => {
        // setStateTextarea('')
        document.querySelector('.selected') && document.querySelector('.selected').classList.remove('selected')

        document.querySelector('input') && document.querySelector('input').checked && (document.querySelector('input').checked = false)

        if (document.querySelector('#opinionScale')) {
            setStateWidthTags(document.querySelector('#opinionScale').clientWidth)
            setStateHeightTags(document.querySelector('#opinionScale').clientHeight)
        }
        window.addEventListener('resize', handleWidth)
        window.addEventListener('resize', handleHeight)

        const { tipoTarea } = tareas[cicleTest]

        if (tipoTarea === 'contexto' || tipoTarea === 'accion' || tipoTarea === 'respuestaVerbal' || tipoTarea === 'action' || tipoTarea === 'pruebaFigma' || tipoTarea === 'pruebaPrototipo') {

            intervalForTimeout.current = setInterval(() => {
                remainingTime.current -= 1000
                if (remainingTime.current <= 0) {
                    remainingTime.current = 0
                    setDisabledButton(false)
                    clearInterval(intervalForTimeout.current)
                }
            }, 1000)
        }




        if (tipoTarea === 'pruebaFigma' || tipoTarea === 'accion' || tipoTarea === 'pruebaPrototipo') {
            setTextBtnContinue('Detener tarea')
        } else {

            setTextBtnContinue('Continuar')
        }


    }, [cicleTest, tareas, intervalForTimeout, remainingTime, setDisabledButton, setTextBtnContinue])

    const handleHeight = () => {
        if (document.querySelector('#opinionScale')) {
            setStateHeightTags(document.querySelector('#opinionScale').clientHeight)
        }
    }

    return (
        <>

            {tareas[cicleTest].tipoTarea !== 'pruebaPrototipo' &&

                <Text
                    color={'#6C5280'}
                    fontSize={typeTest === 'prototipo-de-figma' ? '1rem' : '22px'}
                    fontWeight={'700'}
                    style={{ whiteSpace: "break-spaces" }}
                    disabled={disabledTarea}
                    _disabled={{ opacity: '0.5' }}
                    display={showTasks ? 'block' : 'none'}
                >
                    {`Tarea ${cicleTest + 1} de ${tareas.length}`}
                </Text>
            }


            {tareas[cicleTest].tipoTarea === 'respuestaEscrita' &&
                <Flex
                    flexDirection={'column'}
                >
                    <RespuestaEscrita
                        typeTest={typeTest}
                        tarea={tareas[cicleTest]}
                        stateRecorder={recorder.state}
                        setDisabledButton={setDisabledButton}
                        setRespuestaTareas={setRespuestaTareas}
                        disabledTarea={disabledTarea}
                        cicleTest={cicleTest}
                        respuestaTareas={respuestaTareas}
                    />
                    <Flex
                        w={{ sm: '100%', md: '100%', lg: showTasks ? 'auto' : '50%', xl: 'auto', base: 'auto' }}
                        h={'10%'}
                        display={'flex'}
                        margin={'0px'}
                        flexDirection={'row'}
                        justifyContent={{ sm: showTasks ? 'space-between' : 'flex-start', md: showTasks ? 'space-between' : 'center', lg: 'center', xl: 'center', '2xl': 'flex-start' }}
                        alignItems={'baseline'}
                        marginTop={'40px'}
                        className='button'
                        disabled={disabledTarea}
                        _disabled={{ opacity: '0.5', cursor: 'not-allowed', pointerEvents: 'none' }}
                    >
                        <Box
                            width={'100%'}
                        >
                            <Button
                                id={'btn-continue'}
                                w={'150px'}
                                height={'50px'}
                                display={showTasks ? 'inline-flex' : 'none'}
                                borderRadius={'30px'}
                                fontSize={'1rem'}
                                fontWeight={'600'}
                                backgroundColor={'#F69260'}
                                color={'#FFFFFF'}
                                alignSelf={'center'}
                                data-condition={cicleTest === tareas.length - 1 ? 'finish' : 'next'}
                                disabled={disabledButton}
                                _disabled={{ cursor: 'not-allowed', backgroundColor: '#EAE9ED', color: '#9A96A7' }}
                                _hover={!disabledButton && { backgroundColor: '#F57535', transition: '0.5s' }}
                                _active={!disabledButton && { backgroundColor: '#F57535', transition: '0.5s' }}
                                _activeLink={!disabledButton && { backgroundColor: '#F57535', transition: '0.5s' }}
                                _focus={!disabledButton && { backgroundColor: '#F57535', transition: '0.5s' }}
                                onClick={tareas.length > 0 ? handleCicle : handleTareas}
                            >
                                {textBtnContinue}
                            </Button>
                        </Box>
                    </Flex>
                </Flex>
            }

            {tareas[cicleTest].tipoTarea === 'multipleChoice' &&
                <Flex
                    flexDirection={'column'}
                >
                    <MultipleChoice
                        tarea={tareas[cicleTest]}
                        disabledTarea={disabledTarea}
                        typeTest={typeTest}
                        respuestaTareas={respuestaTareas}
                        setRespuestaTareas={setRespuestaTareas}
                        cicleTest={cicleTest}
                        setDisabledButton={setDisabledButton}
                        disabledButton={disabledButton}
                    />
                    <Flex
                        w={{ sm: '100%', md: '100%', lg: showTasks ? 'auto' : '50%', xl: 'auto', base: 'auto' }}
                        h={'10%'}
                        display={'flex'}
                        margin={'0px'}
                        flexDirection={'row'}
                        justifyContent={{ sm: showTasks ? 'space-between' : 'flex-start', md: showTasks ? 'space-between' : 'center', lg: 'center', xl: 'center', '2xl': 'flex-start' }}
                        alignItems={'baseline'}
                        marginTop={'40px'}
                        className='button'
                        disabled={disabledTarea}
                        _disabled={{ opacity: '0.5', cursor: 'not-allowed', pointerEvents: 'none' }}
                    >
                        <Box
                            width={'100%'}
                        >
                            <Button
                                // w={{ sm: '110px', xsm: '150px', md: '180px', lg: '200px', xl: '200px', base: 'auto' }}
                                id={'btn-continue'}
                                w={'150px'}
                                height={'50px'}
                                display={showTasks ? 'inline-flex' : 'none'}
                                borderRadius={'30px'}
                                fontSize={'1rem'}
                                fontWeight={'600'}
                                backgroundColor={'#F69260'}
                                color={'#FFFFFF'}
                                alignSelf={'center'}
                                data-condition={cicleTest === tareas.length - 1 ? 'finish' : 'next'}
                                disabled={disabledButton}
                                _disabled={{ cursor: 'not-allowed', backgroundColor: '#EAE9ED', color: '#9A96A7' }}
                                _hover={!disabledButton && { backgroundColor: '#F57535', transition: '0.5s' }}
                                _active={!disabledButton && { backgroundColor: '#F57535', transition: '0.5s' }}
                                _activeLink={!disabledButton && { backgroundColor: '#F57535', transition: '0.5s' }}
                                _focus={!disabledButton && { backgroundColor: '#F57535', transition: '0.5s' }}
                                onClick={tareas.length > 0 ? handleCicle : handleTareas}
                            >
                                {textBtnContinue}
                            </Button>
                        </Box>
                    </Flex>
                </Flex>
            }

            {tareas[cicleTest].tipoTarea === 'escalaOpinion' &&
                <Flex
                    flexDirection={'column'}
                >
                    <Text
                        w={{ sm: '100%', md: '100%', lg: '600px', xl: '600px' }}
                        fontSize={'1rem'}
                        fontWeight={'500'}
                        textAlign={'left'}
                        lineHeight={'1.5rem'}
                        color={'#6C5280'}
                        style={{ whiteSpace: "break-spaces" }}
                        disabled={disabledTarea}
                        _disabled={{ opacity: '0.5' }}
                    >
                        {tareas[cicleTest].text}
                    </Text>
                    <Box
                        gap={'10px'}
                        fontWeight={'500'}
                        display={'flex'}
                        marginY={'10px'}
                        flexDirection={{ sm: 'row', md: 'column', lg: 'column', xl: 'column', base: 'row' }}
                        w={'100%'}
                        maxW={{ sm: 'none', md: '95%', lg: '100%', xl: '100%', base: 'none' }}
                        minW={{ sm: '70%', md: '70%', lg: 'none', xl: 'none', base: '70%' }}
                    >
                        <Flex
                            id={'opinionScale'}
                            gap={'13px'}
                            w={{ sm: '100%', md: 'fit-content', lg: 'fit-content', xl: 'fit-content', base: '100%' }}
                            h={{ sm: 'fit-content', md: '100%', lg: '100%', xl: '100%', base: 'fit-content' }}
                            flexDirection={{ sm: 'column', md: 'row', lg: 'row', xl: 'row', base: 'column' }}
                            marginBottom={{ sm: '10px', md: 0, lg: 0, xl: 0, base: '10px' }}
                        >
                            {tareas[cicleTest].escala.map((element, index) => {
                                return (
                                    <Flex
                                        w={{ sm: '100%', md: '100%', lg: '40px', xl: '40px', base: '100%' }}
                                        h={'40px'}
                                        minW={{ sm: 'unset', md: '30px', lg: 'unset', xl: 'unset', base: 'unset' }}
                                        maxW={{ sm: 'unset', md: '33px', lg: 'unset', xl: 'unset', base: 'unset' }}
                                        backgroundColor={'#E1D0DE'}
                                        textAlign={'center'}
                                        borderRadius={{ sm: '8.922px', md: '10px', lg: '10px', xl: '10px', base: '8.922px' }}
                                        alignItems={'center'}
                                        justifyContent={{ sm: 'space-between', md: 'center', lg: 'center', xl: 'center', base: 'space-between' }}
                                        px={{ sm: '20px', md: '0px', lg: '0px', xl: '0px', base: '20px' }}
                                        py={{ sm: '5px', md: '0px', lg: '0', xl: '0', base: '5px' }}
                                        boxSizing={'border-box'}
                                        key={index}
                                        className='no-webkit'
                                        id={element}
                                        onClick={handleChange}
                                        _hover={{ cursor: 'pointer', backgroundColor: '#FFD9C6', transition: '0.5s' }}
                                        disabled={disabledTarea}
                                        _disabled={{ opacity: '0.5' }}
                                    >
                                        <Text
                                            fontSize={'1.125rem'}
                                            fontWeight={'700'}
                                            color={'#5E4670'}
                                            lineHeight={'1.625rem'}
                                        >
                                            {element}
                                        </Text>
                                    </Flex>
                                )
                            })}
                        </Flex>
                        <Flex
                            w={{ sm: '30%', md: stateWidthTags, lg: stateWidthTags, xl: stateWidthTags, base: '30%' }}
                            disabled={disabledTarea}
                            _disabled={{ opacity: '0.5' }}
                            color={'#D3BDD1'}
                            flexDirection={{ sm: 'column', lg: 'row', md: 'row', xl: 'row', base: 'column' }}
                            h={{ sm: stateHeightTags, md: 'auto', lg: 'auto', xl: 'auto', base: stateHeightTags }}
                            justifyContent={'space-between'}
                        >
                            {tareas[cicleTest].etiquetas.map((element, index) => {
                                return (
                                    <Text
                                        w={{ sm: 'auto', lg: '33%', md: '33%', xl: '33%', base: 'auto' }}
                                        fontSize={'1rem'}
                                        lineHeight={'1.625rem'}
                                        color={'#6C5280'}
                                        fontWeight={'600'}
                                        key={index}
                                        textAlign={() => { return handleAlign(index) }}
                                    >
                                        {element}
                                    </Text>
                                )
                            })}
                        </Flex>
                    </Box>
                    <Flex
                        w={{ sm: '100%', md: '100%', lg: showTasks ? 'auto' : '50%', xl: 'auto', base: 'auto' }}
                        h={'10%'}
                        display={'flex'}
                        margin={'0px'}
                        flexDirection={'row'}
                        justifyContent={{ sm: showTasks ? 'space-between' : 'flex-start', md: showTasks ? 'space-between' : 'center', lg: 'center', xl: 'center', '2xl': 'flex-start' }}
                        alignItems={'baseline'}
                        marginTop={'40px'}
                        className='button'
                        disabled={disabledTarea}
                        _disabled={{ opacity: '0.5', cursor: 'not-allowed', pointerEvents: 'none' }}
                    >
                        <Box
                            width={'100%'}
                        >
                            <Button
                                id={'btn-continue'}
                                w={'150px'}
                                height={'50px'}
                                display={showTasks ? 'inline-flex' : 'none'}
                                borderRadius={'30px'}
                                fontSize={'1rem'}
                                fontWeight={'600'}
                                backgroundColor={'#F69260'}
                                color={'#FFFFFF'}
                                alignSelf={'center'}
                                data-condition={cicleTest === tareas.length - 1 ? 'finish' : 'next'}
                                disabled={disabledButton}
                                _disabled={{ cursor: 'not-allowed', backgroundColor: '#EAE9ED', color: '#9A96A7' }}
                                _hover={!disabledButton && { backgroundColor: '#F57535', transition: '0.5s' }}
                                _active={!disabledButton && { backgroundColor: '#F57535', transition: '0.5s' }}
                                _activeLink={!disabledButton && { backgroundColor: '#F57535', transition: '0.5s' }}
                                _focus={!disabledButton && { backgroundColor: '#F57535', transition: '0.5s' }}
                                onClick={tareas.length > 0 ? handleCicle : handleTareas}
                            >
                                {textBtnContinue}
                            </Button>
                        </Box>
                    </Flex>
                </Flex>
            }

            {tareas[cicleTest].tipoTarea === 'siNo' &&
                <Flex
                    flexDirection={'column'}
                >
                    <SiNo
                        disabledTarea={disabledTarea}
                        typeTest={typeTest}
                        cicleTest={cicleTest}
                        tareas={tareas}
                        respuestaTareas={respuestaTareas}
                        setRespuestaTareas={setRespuestaTareas}
                        disabledButton={disabledButton}
                        setDisabledButton={setDisabledButton}
                    />
                    <Flex
                        w={{ sm: '100%', md: '100%', lg: showTasks ? 'auto' : '50%', xl: 'auto', base: 'auto' }}
                        h={'10%'}
                        display={'flex'}
                        margin={'0px'}
                        flexDirection={'row'}
                        justifyContent={{ sm: showTasks ? 'space-between' : 'flex-start', md: showTasks ? 'space-between' : 'center', lg: 'center', xl: 'center', '2xl': 'flex-start' }}
                        alignItems={'baseline'}
                        marginTop={'40px'}
                        className='button'
                        disabled={disabledTarea}
                        _disabled={{ opacity: '0.5', cursor: 'not-allowed', pointerEvents: 'none' }}
                    >
                        <Box
                            width={'100%'}
                        >
                            <Button
                                // w={{ sm: '110px', xsm: '150px', md: '180px', lg: '200px', xl: '200px', base: 'auto' }}
                                id={'btn-continue'}
                                w={'150px'}
                                height={'50px'}
                                display={showTasks ? 'inline-flex' : 'none'}
                                borderRadius={'30px'}
                                fontSize={'1rem'}
                                fontWeight={'600'}
                                backgroundColor={'#F69260'}
                                color={'#FFFFFF'}
                                alignSelf={'center'}
                                data-condition={cicleTest === tareas.length - 1 ? 'finish' : 'next'}
                                disabled={disabledButton}
                                _disabled={{ cursor: 'not-allowed', backgroundColor: '#EAE9ED', color: '#9A96A7' }}
                                _hover={!disabledButton && { backgroundColor: '#F57535', transition: '0.5s' }}
                                _active={!disabledButton && { backgroundColor: '#F57535', transition: '0.5s' }}
                                _activeLink={!disabledButton && { backgroundColor: '#F57535', transition: '0.5s' }}
                                _focus={!disabledButton && { backgroundColor: '#F57535', transition: '0.5s' }}
                                onClick={tareas.length > 0 ? handleCicle : handleTareas}
                            >
                                {textBtnContinue}
                            </Button>
                        </Box>
                    </Flex>
                </Flex>
            }

            {tareas[cicleTest].tipoTarea === 'respuestaVerbal' &&
                <Box>
                    <Text
                        as={'p'}
                        w={{ sm: 'auto', md: 'auto', lg: '450px', xl: '450px' }}
                        fontSize={typeTest === 'prototipo-de-figma' ? '16px' : '20px'}
                        color={'#6C5280'}
                        fontWeight={'500'}
                        textAlign={'left'}
                        style={{ whiteSpace: "break-spaces" }}
                        disabled={disabledTarea}
                        _disabled={{ opacity: '0.5' }}
                    >
                        {tareas[cicleTest].text}
                    </Text>
                    <Text
                        as={'p'}
                        fontSize={'1rem'}
                        color={'#6C5280'}
                        fontWeight={'500'}
                        disabled={disabledTarea}
                        _disabled={{ opacity: '0.5' }}
                    >
                        Antes de continuar, responde en voz alta esta pregunta.
                    </Text>
                    <Flex
                        w={{ sm: '100%', md: '100%', lg: 'auto', xl: 'auto', base: 'auto' }}
                        h={'10%'}
                        display={'flex'}
                        margin={'0px'}
                        flexDirection={'row'}
                        justifyContent={{ sm: showTasks ? 'space-between' : 'flex-start', md: showTasks ? 'space-between' : 'center', lg: 'center', xl: 'center', '2xl': 'flex-start' }}
                        alignItems={'baseline'}
                        marginTop={'40px'}
                        className='button'
                        disabled={disabledTarea}
                        _disabled={{ opacity: '0.5', cursor: 'not-allowed', pointerEvents: 'none' }}
                    >
                        <Box
                            width={'100%'}
                        >
                            <Button
                                // w={{ sm: '110px', xsm: '150px', md: '180px', lg: '200px', xl: '200px', base: 'auto' }}
                                id={'btn-continue'}
                                w={'auto'}
                                height={'50px'}
                                display={showTasks ? 'inline-flex' : 'none'}
                                borderRadius={'30px'}
                                fontSize={'1rem'}
                                fontWeight={'600'}
                                backgroundColor={'#F69260'}
                                color={'#FFFFFF'}
                                alignSelf={'center'}
                                data-condition={cicleTest === tareas.length - 1 ? 'finish' : 'next'}
                                disabled={disabledButton}
                                _disabled={{ cursor: 'not-allowed', backgroundColor: '#EAE9ED', color: '#9A96A7' }}
                                _hover={!disabledButton && { backgroundColor: '#F57535', transition: '0.5s' }}
                                _active={!disabledButton && { backgroundColor: '#F57535', transition: '0.5s' }}
                                _activeLink={!disabledButton && { backgroundColor: '#F57535', transition: '0.5s' }}
                                onClick={tareas.length > 0 ? handleCicle : handleTareas}
                            >
                                Ya respondí, deseo continuar
                            </Button>
                        </Box>
                    </Flex>
                </Box>
            }

            {tareas[cicleTest].tipoTarea === 'contexto' &&
                <Flex
                    flexDirection={'column'}
                >
                    <TextComponent tareas={tareas} disabledTarea={disabledTarea} cicleTest={cicleTest} typeTest={typeTest} />
                    <Flex
                        w={{ sm: '100%', md: '100%', lg: 'auto', xl: 'auto', base: 'auto' }}
                        h={'10%'}
                        display={'flex'}
                        margin={'0px'}
                        flexDirection={'row'}
                        justifyContent={{ sm: showTasks ? 'space-between' : 'flex-start', md: showTasks ? 'space-between' : 'center', lg: 'center', xl: 'center', '2xl': 'flex-start' }}
                        alignItems={'baseline'}
                        marginTop={'40px'}
                        className='button'
                        disabled={disabledTarea}
                        _disabled={{ opacity: '0.5', cursor: 'not-allowed', pointerEvents: 'none' }}
                    >
                        <Box
                            width={'100%'}
                        >
                            <Button
                                // w={{ sm: '110px', xsm: '150px', md: '180px', lg: '200px', xl: '200px', base: 'auto' }}
                                id={'btn-continue'}
                                w={'150px'}
                                height={'50px'}
                                display={showTasks ? 'inline-flex' : 'none'}
                                borderRadius={'30px'}
                                fontSize={'1rem'}
                                fontWeight={'600'}
                                backgroundColor={'#F69260'}
                                color={'#FFFFFF'}
                                alignSelf={'center'}
                                data-condition={cicleTest === tareas.length - 1 ? 'finish' : 'next'}
                                disabled={disabledButton}
                                _disabled={{ cursor: 'not-allowed', backgroundColor: '#EAE9ED', color: '#9A96A7' }}
                                _hover={!disabledButton && { backgroundColor: '#F57535', transition: '0.5s' }}
                                _active={!disabledButton && { backgroundColor: '#F57535', transition: '0.5s' }}
                                _activeLink={!disabledButton && { backgroundColor: '#F57535', transition: '0.5s' }}
                                _focus={!disabledButton && { backgroundColor: '#F57535', transition: '0.5s' }}
                                onClick={tareas.length > 0 ? handleCicle : handleTareas}
                            >
                                {textBtnContinue}
                            </Button>
                        </Box>
                    </Flex>
                </Flex>
            }

            {tareas[cicleTest].tipoTarea === 'accion' &&
                <TextComponent tareas={tareas} disabledTarea={disabledTarea} cicleTest={cicleTest} typeTest={typeTest} />
            }

            {tareas[cicleTest].tipoTarea === 'abrirPrototipo' &&
                <Spinner />
            }

            {(tareas[cicleTest].tipoTarea === 'pruebaFigma' || tareas[cicleTest].tipoTarea === 'pruebaPrototipo') &&
                <Flex
                    alignItems={'center'}
                    flexDirection={{ sm: 'column', md: 'column', lg: 'row', xl: 'row', base: 'column' }}
                >



                    <Flex
                        flexDir={'column'}
                    >
                        <Text
                            color={'#6C5280'}
                            fontSize={typeTest === 'prototipo-de-figma' ? '1rem' : '22px'}
                            fontWeight={'700'}
                            style={{ whiteSpace: "break-spaces" }}
                            disabled={disabledTarea}
                            _disabled={{ opacity: '0.5' }}
                            display={showTasks ? 'block' : 'none'}
                        >
                            {`Tarea ${cicleTest + 1} de ${tareas.length}`}
                        </Text>
                        <TextComponent tareas={tareas} disabledTarea={disabledTarea} cicleTest={cicleTest} typeTest={typeTest} showTasks={showTasks} />
                    </Flex>

                    <Flex
                    >

                        <Flex
                            w={{ sm: '100%', md: '100%', lg: 'auto', xl: 'auto', base: 'auto' }}
                            h={'10%'}
                            marginTop={showTasks ? { sm: '20px', md: '20px', lg: '0px', xl: '0px', base: '20px' } : '0px'}
                            marginLeft={showTasks ? { sm: '0px', md: '0px', lg: '20px', xl: '20px', base: '0px' } : '0px'}
                            flexDirection={'row'}
                            flexWrap={{ sm: 'wrap-reverse', md: 'nowrap', lg: 'nowrap', xl: 'nowrap', base: 'wrap' }}
                            justifyContent={{ sm: showTasks ? 'space-between' : 'flex-start', md: showTasks ? 'space-between' : 'center', lg: 'center', xl: 'center', '2xl': 'flex-start' }}
                            alignItems={'center'}
                            className='button'
                            disabled={disabledTarea}
                            _disabled={{ opacity: '0.5', cursor: 'not-allowed', pointerEvents: 'none' }}
                        >
                            {tareas &&
                                tareas.length > 0 &&
                                tareas[cicleTest] &&
                                tareas[cicleTest].tipoTarea === 'pruebaPrototipo' &&
                                <Box
                                    marginRight={showTasks ? { base: '10px' } : '0px'}
                                    height={'100%'}
                                    display={'flex'}
                                >
                                    <Button
                                        w={'auto'}
                                        paddingX={'30px'}
                                        height={'50px'}
                                        fontSize={'1rem'}
                                        marginTop={showTasks ? { sm: '10px', md: '0px', lg: '0px', xl: '0px', base: '10px' } : '0px'}
                                        color={'#6C5280'}
                                        backgroundColor={'#E1D0DE'}
                                        borderRadius={'30px'}
                                        fontWeight={'600'}
                                        cursor={'pointer'}
                                        rightIcon={showTasks ? <ArrowIconUp w={'14px'} h={'6px'} /> : <ArrowIconDown w={'14px'} h={'6px'} />}
                                        _hover={!disabledButton && { backgroundColor: '#E2CFDF', transition: '0.5s' }}
                                        _active={!disabledButton && { backgroundColor: '#E2CFDF', transition: '0.5s' }}
                                        _activeLink={!disabledButton && { backgroundColor: '#E2CFDF', transition: '0.5s' }}
                                        onClick={hideTasks}
                                    >
                                        {textForBtnHide}
                                    </Button>
                                </Box>}
                            <Box
                                width={'100%'}
                            >
                                <Button
                                    w={'auto'}
                                    paddingX={'30px'}
                                    height={'50px'}
                                    display={showTasks ? 'inline-flex' : 'none'}
                                    borderRadius={'30px'}
                                    fontSize={'1rem'}
                                    fontWeight={'600'}
                                    backgroundColor={'#F28853'}
                                    color={'#FFFFFF'}
                                    cursor={'pointer'}
                                    data-condition={cicleTest === tareas.length - 1 ? 'finish' : 'next'}
                                    disabled={disabledButton}
                                    _disabled={{ cursor: 'not-allowed', backgroundColor: '#EAE9ED', color: '#9A96A7' }}
                                    _hover={!disabledButton && { backgroundColor: '#F57535', transition: '0.5s' }}
                                    _active={!disabledButton && { backgroundColor: '#F57535', transition: '0.5s' }}
                                    _activeLink={!disabledButton && { backgroundColor: '#F57535', transition: '0.5s' }}
                                    _focus={!disabledButton && { backgroundColor: '#F57535', transition: '0.5s' }}
                                    onClick={tareas.length > 0 ? handleCicle : handleTareas}
                                    id={'btn-continue'}
                                    alignSelf={'center'}
                                >
                                    {textBtnContinue}
                                </Button>
                            </Box>
                        </Flex>
                    </Flex>
                </Flex>

            }
        </>
    )
}

export default QuizTarea