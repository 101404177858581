import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from 'firebase/auth';
import { getFirestore } from "firebase/firestore";


const firebaseConfigParticipantes = {
  apiKey:process.env.REACT_APP_API_KEY,
  authDomain:process.env.REACT_APP_AUTH_DOMAIN,
  projectId:process.env.REACT_APP_PROJECT_ID,
  storageBucket:process.env.REACT_APP_BUCKET,
  messaginSenderId:process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId:process.env.REACT_APP_APP_ID,
  measurementId:process.env.REACT_APP_MEASUREMENT_ID
}

// const firebaseConfigClientes = {
//   apiKey:process.env.REACT_APP_API_KEY_CLIENTES,
//   authDomain:process.env.REACT_APP_AUTH_DOMAIN_CLIENTES,
//   projectId:process.env.REACT_APP_PROJECT_ID_CLIENTES,
//   storageBucket:process.env.REACT_APP_BUCKET_CLIENTES,
//   messaginSenderId:process.env.REACT_APP_MESSAGING_SENDER_ID_CLIENTES,
//   appId:process.env.REACT_APP_APP_ID_CLIENTES,
//   measurementId:process.env.REACT_APP_MEASUREMENT_ID_CLIENTES
// }


// Initialize Firebase for participantes
export const app_participantes = initializeApp(firebaseConfigParticipantes, 'participantes');
export const analytics_participantes = getAnalytics(app_participantes);
export const auth_participantes = getAuth(app_participantes)
export const db_participantes = getFirestore(app_participantes);


// // Initialize Firebase for clientes
// export const app_clientes = initializeApp(firebaseConfigClientes, 'clientes');
// export const analytics_clientes = getAnalytics(app_clientes);
// export const auth_clientes = getAuth(app_clientes)
// export const db_clientes = getFirestore(app_clientes);

