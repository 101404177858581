import React from 'react'

const AlertaIcon = ({ stroke }) => {
    return (

        <svg width="122" height="122" viewBox="0 0 122 122" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M61.0001 111.833C89.0746 111.833 111.833 89.0738 111.833 60.9993C111.833 32.9249 89.0746 10.166 61.0001 10.166C32.9256 10.166 10.1667 32.9249 10.1667 60.9993C10.1667 89.0738 32.9256 111.833 61.0001 111.833Z" stroke={stroke ? stroke : "#ED9B72"} strokeWidth="10.1667" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M61 38.666V63.9993" stroke={stroke ? stroke : "#ED9B72"} strokeWidth="10.1667" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M61 84.334H61.05" stroke={stroke ? stroke : "#ED9B72"} strokeWidth="10.1667" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )
}

export default AlertaIcon