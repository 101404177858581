// export const isTypeSupported = (isMobile) => {

//     const regex = /^(?!.*(?:Mozilla|Firefox)).*$/;

//     const codecsVideo = [
//         'video/webm;codecs=vp8',
//         'video/webm',
//         'video/webm;codecs=avc1',
//         'video/webm;codecs=vp9',
//         'video/mp4;codecs=avc1.42E01E,mp4a.40.2',
//         'video/mp4;codecs=avc1.58A01E,mp4a.40.2',
//         'video/mp4;codecs=avc1.4D401E,mp4a.40.2',
//         'video/mp4;codecs=h264,aac',
//         'video/3gpp;codecs=h264,aac',
//     ];

//     const codecsAudio = [
//         'audio/webm',
//         'audio/webm;codecs=opus',
//         'audio/webm;codecs=vorbis',
//         'audio/ogg;codecs=opus',
//         'audio/mp4;codecs=mp4a.40.2',
//         'audio/mpeg'
//     ];


//     if (isMobile) {
//         for (let i = 0; i < codecsAudio.length; i++) {
//             if (MediaRecorder.isTypeSupported(codecsAudio[i])) {
//                 return codecsAudio[i]
//             }
//         }
//     } else {
//         for (let i = 0; i < codecsVideo.length; i++) {
//             if (MediaRecorder.isTypeSupported(codecsVideo[i])) {
//                 return codecsVideo[i]
//             }
//         }
//     }


// }

export const isTypeSupported = (isMobile) => {
    // const regex = /^(?!.*(?:Mozilla|Firefox)).*$/;
    const codecsVideo = [
        'video/webm;codecs=vp8',
        'video/webm',
        'video/webm;codecs=avc1',
        'video/webm;codecs=vp9',
        'video/mp4;codecs=avc1.42E01E,mp4a.40.2',
        'video/mp4;codecs=avc1.58A01E,mp4a.40.2',
        'video/mp4;codecs=avc1.4D401E,mp4a.40.2',
        'video/mp4;codecs=h264,aac',
        'video/3gpp;codecs=h264,aac',
    ];
    const codecsAudio = [
        'audio/mp3',
        'audio/webm',
        'audio/webm;codecs=opus',
        'audio/webm;codecs=vorbis',
        'audio/ogg;codecs=opus',
        'audio/mp4;codecs=mp4a.40.2',
        'audio/mpeg'
    ];

    const isFirefox = /firefox/i.test(navigator.userAgent);

    if (isMobile) {
        for (let i = 0; i < codecsAudio.length; i++) {
            if (MediaRecorder.isTypeSupported(codecsAudio[i])) {
                return codecsAudio[i];
            }
        }
    } else {
        for (let i = 0; i < codecsVideo.length; i++) {
            if (MediaRecorder.isTypeSupported(codecsVideo[i]) && (!isFirefox || codecsVideo[i] !== 'video/webm;codecs=vp8')) {
                return codecsVideo[i];
            }
        }
    }
};

