import { Input, Select, Text, Box, Checkbox, CheckboxGroup, RadioGroup, Radio, SimpleGrid, Button, Stack, Link, InputGroup, InputRightElement } from '@chakra-ui/react'
import React, { useState, useEffect } from 'react'
import { useAuth } from '../../context/authContext'
import { FiEyeOff, FiEye } from 'react-icons/fi'


const QuizItem = ({ elemento }, setError) => {

    const [lenguajes, setLenguajes] = useState([])

    const { userNew, setUserNew } = useAuth()

    const [viewPassword, setViewPassword] = useState('password')




    useEffect(() => {
        if (userNew.lenguajes) {
            setLenguajes(userNew.lenguajes)
        }
    }, [userNew.lenguajes])


    const handleSelect = () => {

        if (elemento.name === 'lenguajes') {
            return (
                <Select
                    marginTop={'10px'}
                    minHeight={'50px'}
                    borderRadius={'10px'}
                    color={'#6C5280'}
                    background={'white'}
                    border={'2px solid #C1A8BE !important'}
                    _hover={{ borderColor: '#C1A8BE' }}
                    _active={{ borderColor: '#C1A8BE' }}
                    _focusVisible={{ borderColor: '#C1A8BE' }}
                    value={lenguajes[lenguajes.length - 1] || ''}
                    id={elemento.id}
                    isRequired={elemento.obligatorio}
                    onChange={handleChangeInput}
                    name={elemento.name}
                >
                    {elemento.opciones.map((el, index) =>
                        <option key={index} value={el}>{el}</option>
                    )}
                </Select>
            )
        } else if (elemento.name === 'industria') {
            return (
                <Select
                    marginTop={'10px'}
                    minHeight={'50px'}
                    borderRadius={'10px'}
                    color={'#6C5280'}
                    background={'white'}
                    border={'2px solid #C1A8BE !important'}
                    _hover={{ borderColor: '#C1A8BE' }}
                    _active={{ borderColor: '#C1A8BE' }}
                    _focusVisible={{ borderColor: '#C1A8BE' }}
                    value={userNew[elemento.name] || ''}
                    id={elemento.id}
                    isRequired={elemento.obligatorio}
                    onChange={handleChangeInput}
                    isDisabled={elemento.obligatorio && userNew['tipoEmpleo'] === 'Desempleado/a' ? true : false}
                    name={elemento.name}
                >
                    {elemento.opciones.map((el, index) =>
                        <option key={index} value={el}>{el}</option>
                    )}
                </Select>
            )
        }
        else {
            return (
                <Select
                    marginTop={'10px'}
                    minHeight={'50px'}
                    borderRadius={'10px'}
                    color={'#6C5280'}
                    background={'white'}
                    border={'2px solid #C1A8BE !important'}
                    _hover={{ borderColor: '#C1A8BE' }}
                    _active={{ borderColor: '#C1A8BE' }}
                    _focusVisible={{ borderColor: '#C1A8BE' }}
                    value={userNew[elemento.name] || ''}
                    id={elemento.id}
                    isRequired={elemento.obligatorio}
                    onChange={handleChangeInput}
                    name={elemento.name}
                >
                    {elemento.opciones.map((el, index) =>
                        <option key={index} value={el}>{el}</option>
                    )}
                </Select>
            )
        }
    }

    const handleCheckbox = () => {
        return (
            <CheckboxGroup required defaultValue={userNew[elemento.name]}>
                <Box id={elemento.id} marginY={'10px'} display={'flex'} flexDirection={'column'}>
                    {elemento.opciones.map((el, index) =>
                        elemento.name === 'terminos' ?
                            <Checkbox
                                textDecoration={'underline white'}
                                className={elemento.id}
                                onChange={handleChangeInput}
                                key={index}
                                value={el}
                                color={'#6C5280'}
                                borderColor={'#6C5280'}
                                colorScheme='orange'
                                _focusVisible={{ borderColor: '#C1A8BE' }}
                                textDecorationColor={'#6C5280'}
                            >
                                <a href='https://www.simulacr.com/terminos-de-servicio-del-testeador/' target={'_blank'} rel='noreferrer'>{el}</a>
                            </Checkbox>
                            :
                            <Checkbox
                                className={elemento.id}
                                onChange={handleChangeInput}
                                key={index}
                                value={el}
                                color={'#6C5280'}
                                borderColor={'#6C5280'}
                                colorScheme='orange'
                                _focusVisible={{ borderColor: '#C1A8BE' }}
                            >
                                {el}
                            </Checkbox>
                    )}
                </Box>
            </CheckboxGroup>

        )
    }

    const handleRadio = () => {
        return (
            <RadioGroup defaultValue={userNew[elemento.name]} id={elemento.id} marginY={'10px'} display={'flex'} flexDirection={'column'}>
                {elemento.opciones.map((el, index) =>
                    <Radio
                        name={elemento.id}
                        onChange={handleChangeInput}
                        key={index}
                        value={el}
                        borderColor={'#6C5280'}
                        colorScheme='orange'
                        _focusVisible={{ borderColor: '#C1A8BE' }}
                    >
                        {el}
                    </Radio>
                )}
            </RadioGroup>

        )
    }


    const handleLenguajesRender = (lengua) => {
        if (lengua !== '-') {
            const filtrado = lenguajes.filter(element => element === lengua)
            if (filtrado) {
                setLenguajes([...lenguajes, lengua])
                setUserNew({ ...userNew, lenguajes: [...lenguajes, lengua] })
            }
        }


    }


    const handleChangeInput = (e) => {

        if (elemento.name === 'lenguajes') {
            handleLenguajesRender(e.target.value)
        } else if (elemento.name === 'dispositivos') {
            setUserNew({ ...userNew, dispositivos: [...userNew.dispositivos, e.target.value] })
        } else if (e.target.name === 'caracteristica') {
            setUserNew({ ...userNew, [e.target.name]: e.target.value })
        } else {
            setUserNew({ ...userNew, [elemento.name]: e.target.value })
        }

    }

    const eliminarLenguaje = (e) => {
        //delete e.target.dataset.name from array lenguajes
        const index = lenguajes.indexOf(e.target.dataset.name)
        lenguajes.splice(index, 1)
        setLenguajes([...lenguajes])
    }

    const handleViewPassword = () => {
        viewPassword === 'password' ? setViewPassword('text') : setViewPassword('password')
    }


    return (
        <Box
            marginBottom={'30px'}
            fontSize={'1.2rem'}
            w={'75%'}
            marginX={'auto'}
            fontWeight={500}
        >
            {elemento.label &&
                <Text
                    opacity={'100%'}
                    color={'#6C5280'}
                >
                    {elemento.label}{elemento.obligatorio && <span style={{ color: '#c0005a' }}>*</span>}
                </Text>}

            {elemento.type === 'text' &&
                <Input
                    marginTop={'10px'}
                    minHeight={'50px'}
                    borderRadius={'10px'}
                    color={'#6C5280'}
                    background={'white'}
                    border={'2px solid #C1A8BE !important'}
                    opacity={'100%'}
                    _hover={{ borderColor: '#C1A8BE' }}
                    _active={{ borderColor: '#C1A8BE' }}
                    _focusVisible={{ borderColor: '#C1A8BE' }}
                    value={userNew[elemento.name] || ''}
                    isRequired={elemento.obligatorio}
                    id={elemento.id}
                    name={elemento.name}
                    onChange={handleChangeInput}
                />}

            {elemento.type === 'text-laboral' &&
                <Input
                    marginTop={'10px'}
                    minHeight={'50px'}
                    borderRadius={'10px'}
                    color={'#6C5280'}
                    background={'white'}
                    border={'2px solid #C1A8BE !important'}
                    _hover={{ borderColor: '#C1A8BE' }}
                    _active={{ borderColor: '#C1A8BE' }}
                    _focusVisible={{ borderColor: '#C1A8BE' }}
                    value={userNew[elemento.name] || ''}
                    isRequired={elemento.obligatorio}
                    id={elemento.id}
                    isDisabled={elemento.obligatorio && userNew['tipoEmpleo'] === 'Desempleado/a' ? true : false}
                    name={elemento.name}
                    onChange={handleChangeInput}
                />}

            {elemento.type === 'textarea' &&
                <Input
                    as={'textarea'}
                    marginTop={'10px'}
                    minHeight={'50px'}
                    borderRadius={'10px'}
                    color={'#6C5280'}
                    background={'white'}
                    border={'2px solid #C1A8BE !important'}
                    _hover={{ borderColor: '#C1A8BE' }}
                    _active={{ borderColor: '#C1A8BE' }}
                    _focusVisible={{ borderColor: '#C1A8BE' }}
                    value={userNew[elemento.name] || ''}
                    isRequired={elemento.obligatorio}
                    id={elemento.id}
                    name={elemento.name}
                    onChange={handleChangeInput}
                />}

            {elemento.type === 'select' && handleSelect()}

            {elemento.type === 'password' &&
                <>
                    <InputGroup
                        display={'flex'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        marginY={'10px'}
                    >
                        <Input
                            background={'#ffffff'}
                            value={userNew[elemento.name] || ''}
                            isRequired={elemento.obligatorio}
                            id={elemento.id}
                            name={elemento.name}
                            onChange={handleChangeInput}
                            color={'#6C5280'}
                            border={'2px solid #C1A8BE'}
                            borderColor={'#C1A8BE'}
                            borderRadius={'10px'}
                            height={'50px'}
                            type={viewPassword}
                            _hover={{ borderColor: '#C1A8BE' }}
                            _active={{ borderColor: '#C1A8BE' }}
                            _focusVisible={{ borderColor: '#C1A8BE' }}
                        />
                        <InputRightElement
                            top={'unset'}
                            cursor={'pointer'}
                        >
                            {viewPassword === 'password' ?
                                <FiEye
                                    color='#750b72cc'
                                    onClick={handleViewPassword} /> :
                                <FiEyeOff
                                    color='#750b72cc'
                                    onClick={handleViewPassword} />
                            }
                        </InputRightElement>
                    </InputGroup>
                    <Text className='password-invalido'>
                        Contraseña invalida.
                        <br />
                        Tu contraseña debe contener 6 o más carácteres, una mayúscula y un número</Text>
                </>}

            {elemento.type === 'email' &&
                <>
                    <Input
                        type={'email'}
                        marginTop={'10px'}
                        minHeight={'50px'}
                        borderRadius={'10px'}
                        color={'#6C5280'}
                        background={'white'}
                        border={'2px solid #C1A8BE !important'}
                        opacity={'100%'}
                        _hover={{ borderColor: '#C1A8BE' }}
                        _active={{ borderColor: '#C1A8BE' }}
                        _focusVisible={{ borderColor: '#C1A8BE' }}
                        value={userNew[elemento.name] || ''}
                        isRequired={elemento.obligatorio}
                        isInvalid={false}
                        id={elemento.id}
                        name={elemento.name}
                        onChange={handleChangeInput}
                    />
                    <Text className='email-invalido'>Email invalido</Text>
                    <Text className='email-usado'>El email ya está en uso</Text>
                </>}

            {elemento.type === 'date' &&
                <Input
                    type={'date'}
                    marginTop={'10px'}
                    minHeight={'50px'}
                    borderRadius={'10px'}
                    color={'#6C5280'}
                    background={'white'}
                    border={'2px solid #C1A8BE !important'}
                    _hover={{ borderColor: '#C1A8BE' }}
                    _active={{ borderColor: '#C1A8BE' }}
                    _focusVisible={{ borderColor: '#C1A8BE' }}
                    value={userNew[elemento.name] || ''}
                    isRequired={elemento.obligatorio}
                    id={elemento.id}
                    name={elemento.name}
                    onChange={handleChangeInput}
                />}

            {elemento.type === 'checkbox' && handleCheckbox()}

            {elemento.type === 'radio' && handleRadio()}

            {elemento.type === 'parrafo' &&
                <Text
                    padding={'10px'}
                    border={'2px solid #C1A8BE !important'}
                    textAlign={'center'}
                    fontSize={'1rem'}
                    color={'#6C5280'}
                >
                    {elemento.parrafo}
                </Text>
            }

            {elemento.type === 'telefono' &&
                <Box display={'flex'}>
                    <Input
                        marginTop={'10px'}
                        minHeight={'50px'}
                        borderRadius={'10px'}
                        color={'#6C5280'}
                        background={'white'}
                        border={'2px solid #C1A8BE !important'}
                        type='tel'
                        w={'30%'}
                        _hover={{ borderColor: '#C1A8BE' }}
                        _active={{ borderColor: '#C1A8BE' }}
                        _focusVisible={{ borderColor: '#C1A8BE' }}
                        value={userNew['caracteristica'] || ''}
                        isRequired={elemento.obligatorio}
                        id={'caracteristica'}
                        name={'caracteristica'}
                        onChange={handleChangeInput}
                    />
                    <Input
                        marginTop={'10px'}
                        marginLeft={'5px'}
                        minHeight={'50px'}
                        borderRadius={'10px'}
                        color={'#6C5280'}
                        background={'white'}
                        border={'2px solid #C1A8BE !important'}
                        type='tel'
                        _hover={{ borderColor: '#C1A8BE' }}
                        _active={{ borderColor: '#C1A8BE' }}
                        _focusVisible={{ borderColor: '#C1A8BE' }}
                        value={userNew[elemento.name] || ''}
                        isRequired={elemento.obligatorio}
                        id={elemento.id}
                        name={elemento.name}
                        onChange={handleChangeInput}
                    />
                </Box>
            }

            {elemento.name === 'linkedin' &&
                <Link
                    href='https://www.linkedin.com/in/'
                    isExternal
                    display={'flex'}
                    marginTop={'10px'}
                    backgroundColor={'#F57535'}
                    color={'#FFFFFF'}
                    borderRadius={'30px'}
                    textDecoration={'none'}
                    height={'40px'}
                    paddingX={'30px'}
                    width={'max-content'}
                    alignItems={'center'}
                    fontSize={'1rem'}
                    _hover={{ textDecoration: 'none', backgroundColor: '#F57535' }}
                >
                    Abrir LinkedIn
                </Link>
            }

            {lenguajes.length > 0 && elemento.name === 'lenguajes' &&
                <SimpleGrid columns={{ sm: 2, md: 3 }} justifyContent={{ md: 'center', sm: 'left' }} alignContent={'center'} spacing={5}>
                    {lenguajes.map((el, index) =>
                        el !== '-' && el !== '' &&
                        <Box key={index} fontSize={'md'} display={'flex'} justifyContent={'center'} alignItems={'center'} p={'10px'} background={'#c0005a'}>
                            <Text textAlign={'center'} key={index} marginBottom={'0px !important'}>{el} <span data-name={el} onClick={eliminarLenguaje}>X</span></Text>
                        </Box>
                    )}
                </SimpleGrid>
            }

            <Text data-target={elemento.id} className='invalid'>Este campo es obligatorio</Text>
        </Box>

    )
}

export default QuizItem