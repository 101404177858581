const ArrowIconUp = ({ w, h }) => {
    const styles = '.st0 {fill: #6C5280;}';

    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 26.9 13.1"
            style={{ enableBackground: 'new 0 0 26.9 13.1' }} xmlSpace="preserve" width={w || 'auto'} height={h || 'auto'}>
            <style type="text/css">
                {styles}
            </style>
            <polygon className="st0" points="13.4,0 0,10.8 1.9,13.1 13.4,3.8 25,13.1 26.9,10.8 " />
        </svg>
    )
}

export default ArrowIconUp