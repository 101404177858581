import { Flex, Stack, Text, Box, Container, Center, Heading } from '@chakra-ui/react'
import React, { useState, useEffect, useRef, useCallback } from 'react'
//CONTEXT
import { useParams, useNavigate } from 'react-router-dom'
import Recorder from './Recorder'
import Tareas from './Tareas'
import { useTestDbContext } from '../../context/testsDbContext'
import ProgressBarContainer from './ProgressBarContainer'
import generateRandomCode from '../../functions/randomCode'
import useUnload from '../../hooks/useUnload'
import { getOperatingSystem } from '../../functions/getOperatingSystem'
import IframeComponent from './IframeComponent'
import { isTypeSupported } from '../../functions/isTypeSupported'
import Permisos from '../Comprobacion/Permisos'
import PruebaMic from '../Comprobacion/PruebaMic'
import Pantalla from '../Comprobacion/Pantalla'
import { isIOS } from 'react-device-detect';
// import '../../css/iframeBug.css'
import LogoNularVioleta from '../../icons/svg/LogoNularVioleta'
import AlertaCruz from '../../icons/svg/AlertaCruz'
import '../../css/css-for-accessibility.css'




const TestPrototypeComponent = ({ isMobile, typeTest, prueba, bienvenida, titulo, previsualizador, prototipo }) => {

    //STATE
    const [recorder, setRecorder] = useState()
    const [mimeType, setMimeType] = useState()
    const [respuestaTareas, setRespuestaTareas] = useState([])
    const [combineMediaStream, setCombineMediaStream] = useState()

    // const [test, setTest] = useState()
    const [idVideo, setIdVideo] = useState(null)
    const [cicleTest, setCicleTest] = useState(0)
    const [cicleComprobacion, setCicleComprobacion] = useState(0)
    const [soyTareaPrototipo, setSoyTareaPrototipo] = useState(null)

    //STATES BANDERAS
    const [access, setAccess] = useState(true)
    const [cargando, setCargando] = useState(false)
    const [disabledButton, setDisabledButton] = useState(true) // PARA BOTON DE CONTINUAR
    const [disabledButtonAbrirSitioWeb, setDisabledButtonAbrirSitioWeb] = useState(false) // PARA BOTON ABRIR SITIO WEB (TEST 2 PESTAÑAS)
    const [disabledTarea, setDisabledTarea] = useState(false) //PARA TAREAS EN GENERAL, SE DISPARA CUANDO EL TEST SE PAUSA
    const [abrioElSitioWeb, setAbrioElSitioWeb] = useState(false) //PARA VERIFICAR SI EL USUARIO ABRIO EL SITIO WEB

    const [comprobacion, setComprobacion] = useState(false) //PARA VERIFICAR SI EL USUARIO HIZO LA COMPROBACION DE MICROFONO Y PANTALLA
    const [hidden, setHidden] = useState(true) //PARA OCULTAR O NO EL IFRAME
    // const minHeight = isSafari ? '-webkit-fill-available' : '100vh';


    // BARRA DE TAREAS MOBILE
    const [showTasks, setShowTasks] = useState(true) //PARA BARRA DE TAREAS MOBILE
    const [textForBtnHide, setTextForBtnHide] = useState('Ocultar')
    const [textBtnContinue, setTextBtnContinue] = useState('Continuar')



    //STATE HEATMAP
    const [pointsByNodeId, setPointsByNodeId] = useState([]);



    // const [linkFigma, setLinkFigma] = useState('')
    const [cliente, setCliente] = useState('')

    //CONTEXT
    const { audioR, video, contexto } = useTestDbContext()
    const { id } = useParams()


    //USE REF
    // const recordingRunning = useRef(false)
    const i = useRef(1)
    const seconds = useRef(0)
    const intervalTime = useRef() //PARA TRANSCRIPCION DE CADA TAREA

    const intervalTimeIframe = useRef() //PARA VIDEO DE MOBILE DE PROTOTIPO
    const secondsIframe = useRef() //PARA VIDEO DE MOBILE DE PROTOTIPO
    const cicleForIframe = useRef(0) //PARA VIDEO DE MOBILE DE PROTOTIPO

    const arrayOrden = useRef([]); //ORDEN DE LOS NODE-ID EN LA TAREA DE ACCION

    const navigate = useNavigate()

    const remainingTime = useRef(7000);
    const intervalForTimeout = useRef();

    const renderForMobile = useRef([])
    const nodesAndTimeOfFigma = useRef([])

    const dataAvailableSource = useRef('requestData')

    // ▼ Este useEffect es para chequear si la tarea que se está viendo es tarea en prototipo de figma o no ▼
    useEffect(() => {
        prueba?.tareas[cicleTest]?.tipoTarea === "pruebaPrototipo" ? setSoyTareaPrototipo(true) : setSoyTareaPrototipo(false)
    }, [cicleTest])

    const setearRecorder = (mimeType, combine) => {

        if (mimeType !== undefined) {
            setRecorder(new MediaRecorder(combine, {
                mimeType: mimeType,
                videoBitsPerSecond: 625000,
                audioBitsPerSecond: 320000,
            }))
        } else {
            //TODO: mostrar mensaje de error
        }

    }

    useUnload(e => {
        e.preventDefault()
        dataAvailableSource.current = 'stop'
        if (comprobacion) {
            recorder.state === 'recording' && recorder.stop()
        }
        previsualizador ? navigate(`/test/${id}/previsualizador`) : navigate(`/test/${id}/finalizado`)
        return e.returnValue
    })

    useEffect(() => {

        // setTest(prueba)

        if (comprobacion) {

            // const isMobile = getOperatingSystem()

            if (isMobile) {

                const combine = new MediaStream([...audioR.getTracks()],)
                const mimeType = isTypeSupported(isMobile)
                console.log('vamos a usar: ', mimeType)


                setearRecorder(mimeType, combine)
                setMimeType(mimeType)
                setCombineMediaStream(combine)

            } else {

                const combine = new MediaStream(
                    [...audioR.getTracks(), ...video.getTracks()],)


                const mimeType = isTypeSupported()
                console.log('vamos a usar: ', mimeType)
                setearRecorder(mimeType, combine)
                setMimeType(mimeType)
                setCombineMediaStream(combine)

            }
        }

    }, [comprobacion])

    useEffect(() => {

        if (recorder) {
            recorder.start()
            // recordingRunning.current = true
        }

        return () => {
            if (recorder) {
                recorder.state === 'recording' && recorder.stop()
            }
        }

    }, [recorder])

    useEffect(() => {
        idVideo === null && setIdVideo(generateRandomCode())
        prueba.uid = id
    }, [idVideo])

    // useEffect(() => {
    //     if (prueba) {
    //         const links = prueba.tareas.map((tarea, i) => {
    //             if (tarea.tipoTarea === 'pruebaFigma' || tarea.tipoTarea === 'pruebaPrototipo') {
    //                 return tarea.urlFigma
    //             }
    //         })
    //         setLinkFigma(links.filter(el => el !== undefined)) //TODO: CAMBIAR PARA QUE TOME TODOS LOS LINKS
    //     }
    // }, [prueba])

    useEffect(() => {
        if (comprobacion) {
            if (prueba === null) {
                setAccess(false)
                return
            }

            if (prueba.cliente) {
                setCliente(prueba.cliente)
            } else {
                setAccess(false)
            }

            // if (test.contexto) {
            //     contexto.current = test.contexto
            // }
        }
    }, [comprobacion])

    const handlePrototypeTest = () => {
        if (isMobile) {
            switch (cicleComprobacion) {
                case 0:
                    return (
                        //PERMISOS
                        <Permisos typeTest={typeTest} setCicleComprobacion={setCicleComprobacion} cicleComprobacion={cicleComprobacion} bienvenida={bienvenida} titulo={titulo} />
                    )
                case 1:
                    return (
                        <PruebaMic setCicleComprobacion={setCicleComprobacion} cicleComprobacion={cicleComprobacion} comprobacion={comprobacion} setComprobacion={setComprobacion} typeTest={typeTest} id={id} />
                    )

                default:
                    return (
                        <Stack margin={'auto'} as={'section'} className='container-error-comprobacion'>
                            <Container>
                                <Center display={'flex'} flexDirection={'column'} h={'100%'} textAlign={'center'}>
                                    <AlertaCruz />
                                    <Heading marginY={'20px'} fontSize={'2rem'} color={'#F69260'}> Algo salió mal...</Heading>
                                    <Text fontSize={'1.125rem'} color={'#6C5280'} fontWeight={'600'}>Por favor, recarga la página o contáctate con soporte@nular.co</Text>
                                </Center>
                            </Container>
                        </Stack>
                    )
            }
        } else {
            switch (cicleComprobacion) {

                case 0:
                    return (
                        //PERMISOS
                        <Permisos setCicleComprobacion={setCicleComprobacion} cicleComprobacion={cicleComprobacion} typeTest={typeTest} bienvenida={bienvenida} titulo={titulo} />
                    )

                case 1:
                    //PANTALLA
                    return (
                        <Pantalla setCicleComprobacion={setCicleComprobacion} cicleComprobacion={cicleComprobacion} typeTest={typeTest} />
                    )

                case 2:
                    //MIC
                    return (<PruebaMic setCicleComprobacion={setCicleComprobacion} cicleComprobacion={cicleComprobacion} comprobacion={comprobacion} setComprobacion={setComprobacion} typeTest={typeTest} id={id} />)

                default:
                    return (
                        <Stack margin={'auto'} as={'section'} className='container-error-comprobacion'>
                            <Container>
                                <Center display={'flex'} flexDirection={'column'} h={'100%'} textAlign={'center'}>
                                    <AlertaCruz />
                                    <Heading marginY={'20px'} fontSize={'2rem'} color={'#F69260'}> Algo salió mal...</Heading>
                                    <Text fontSize={'1.125rem'} color={'#6C5280'} fontWeight={'600'}>Por favor, recarga la página o contáctate con soporte@nular.co</Text>
                                </Center>
                            </Container>
                        </Stack>
                    )

            }
        }
    }


    const containerRef = useRef(null);
    const [heightBarraDeTareas, setHeightBarraDeTareas] = useState(0);

    const updateHeight = () => {
        if (containerRef.current) {
            const container = containerRef.current;
            const newHeight = showTasks ? container.clientHeight : containerRef?.current?.clientHeight;
            setHeightBarraDeTareas(newHeight);
        }
    };

    useEffect(() => {
        updateHeight(); // Actualiza la altura al cargar

        window.addEventListener('resize', updateHeight);

        return () => {
            window.removeEventListener('resize', updateHeight);
        };
    }, [showTasks]);

    const hideTasks = useCallback(() => {
        if (textForBtnHide === 'Ocultar') {
            setShowTasks(false);
            setTextForBtnHide('Ver tarea');
        } else {
            setTextForBtnHide('Ocultar');
            setShowTasks(true);
        }
    }, [textForBtnHide]);

    useEffect(() => {
        setHeightBarraDeTareas(containerRef?.current?.clientHeight);
    }, [containerRef?.current?.clientHeight, showTasks]);

    return (
        <Flex
            as={'main'}
            flexDirection={'column'}
            backgroundColor={'#F4EEF3'}
            height={'100%'}
            minHeight={isMobile ? (isIOS ? '-webkit-fill-available' : `calc(var(--vh, 1vh) * 100)`) : '100vh'}
            overflow={heightBarraDeTareas === 0 ? 'unset' : 'hidden'}
            position={'relative'}
            justifyContent={'center'}
            className='main-test-iframe'
        >
            {!comprobacion ?
                //ACÁ ESTAN TODAS LAS PANTALLAS DE COMPROBACIÓN
                <Flex
                    as={'section'}
                    className='section-comprobaciones'
                    height={isMobile ? (isIOS ? '100%' : `calc(var(--vh, 1vh) * 100)`) : '100%'}
                    width={'100%'}
                    flexDirection={'column'}
                    justifyContent={'center'}
                    position={'relative'}
                >
                    <Flex
                        className='flex-container-comprobaciones'
                        justifyContent={'center'}
                        height={'100%'}
                        width={'100%'}
                        padding={{ sm: '15px', md: '15px', lg: '0px', xl: '0px' }}
                        overflowY={'auto'}
                        sx={{
                            '::-webkit-scrollbar': {
                                width: '0px',
                            },
                            '::-webkit-scrollbar-track': {
                                background: 'transparent',
                            },
                            '::-webkit-scrollbar-thumb': {
                                background: 'transparent',
                                borderRadius: '3px',
                            },
                            '::-webkit-scrollbar-thumb:hover': {
                                background: 'transparent',
                            },
                        }}
                    >
                        {handlePrototypeTest()}
                    </Flex>
                    {!isMobile &&
                        <Flex
                            as={'footer'}
                            className='footer-creado-con'
                            justifyContent={'flex-end'}
                            alignItems={'center'}
                            position={'absolute'}
                            bottom={'0'}
                            right={'0'}
                            marginBottom={'40px'}
                            marginRight={'55px'}
                        >
                            <a
                                href="https://www.nular.co/"
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    textDecoration: 'none',
                                }}
                            >
                                <span className="visually-hidden">Página creada por Nular. Visita nuestra página.</span>
                                <Text
                                    color={'#9983AA'}
                                    fontSize={'0.875rem'}
                                    fontWeight={'600'}
                                    lineHeight={'1.7635rem'}
                                    style={{ ariaHidden: 'true' }}
                                >
                                    Creado con
                                </Text>
                                <Box
                                    marginLeft={'4px'}
                                    style={{ ariaHidden: 'true' }}
                                >
                                    <LogoNularVioleta />
                                </Box>
                            </a>
                        </Flex>
                    }
                </Flex>
                //ACÁ FINALIZAN TODAS LAS PANTALLAS DE COMPROBACIÓN
                : (access ?
                    //ACÁ ARRANCAN TODAS LAS PANTALLAS DE TAREAS CON EL RECORDER
                    <Flex
                        as={'section'}
                        width={'100%'}
                        height={soyTareaPrototipo ? 'auto' : '100%'}
                        minHeight={'auto'}
                        backgroundColor={'#F4EEF3'}
                        justifyContent={cargando ? 'center' : (isMobile ? (showTasks ? 'space-between' : 'flex-start') : 'center')}
                        flexDirection={'row'}
                        className='section-tareas-recorder'
                        id='navbar-tareas-recorder'
                        ref={containerRef}
                        transitionProperty={'all'}
                        position={'fixed'}
                        top={soyTareaPrototipo ? 0 : '50%'}
                        left={soyTareaPrototipo ? 0 : '50%'}
                        transform={soyTareaPrototipo ? 'none' : 'translate(-50%, -50%)'}
                        zIndex={1000}
                    >
                        {recorder && !cargando &&

                            <Box
                                as='article'
                                className='article-recorder-tareas'
                                maxWidth={soyTareaPrototipo ? '1450px' : '1000px'}
                                width={'100%'}
                                minWidth={isMobile ? (isIOS ? '-webkit-fill-available' : '100vw') : 'unset'}
                                height={'100%'}
                                maxHeight={'100vh'}
                                display={'flex'}
                                alignItems={soyTareaPrototipo ? 'flex-start' : 'center'}
                                justifyContent={'center'}
                                flexDirection={'row'}
                                transition={'all 0.3s ease-out'}
                                overflow={'auto'}
                                sx={{
                                    '::-webkit-scrollbar': {
                                        width: '0px',
                                    },
                                    '::-webkit-scrollbar-track': {
                                        background: 'transparent',
                                    },
                                    '::-webkit-scrollbar-thumb': {
                                        background: 'transparent',
                                        borderRadius: '3px',
                                    },
                                    '::-webkit-scrollbar-thumb:hover': {
                                        background: 'transparent',
                                    },
                                }}
                            >
                                <Flex
                                    className='container-flex-recorder-tareas'
                                    width={'100%'}
                                    paddingTop={soyTareaPrototipo ? 'unset' : '0px 20px 0px'}
                                    paddingBottom={soyTareaPrototipo ? '0px' : '0px 20px 0px'}
                                    height={soyTareaPrototipo ? 'auto' : '100%'}
                                    justifyContent={'center'}
                                    backgroundColor={'#F4EEF3'}
                                    alignItems={'center'}
                                    transition={'all 0.3s ease-out'}
                                >
                                    <Flex
                                        className='box-flex-recorder-tareas'
                                        maxHeight={'100%'}
                                        width={'100%'}
                                        height={'auto'}
                                        overflow={'auto'}
                                        paddingX={'20px'}
                                        justifyContent={{ sm: 'space-between', md: 'center', lg: 'center', xl: 'center', base: 'space-between' }}
                                        paddingTop={soyTareaPrototipo ? (showTasks ? '20px' : 'unset') : '20px'}
                                        alignItems={soyTareaPrototipo ? (showTasks ? 'normal' : 'center') : 'normal'}
                                        sx={{
                                            '::-webkit-scrollbar': {
                                                width: '0px',
                                            },
                                            '::-webkit-scrollbar-track': {
                                                background: 'transparent',
                                            },
                                            '::-webkit-scrollbar-thumb': {
                                                background: 'transparent',
                                                borderRadius: '3px',
                                            },
                                            '::-webkit-scrollbar-thumb:hover': {
                                                background: 'transparent',
                                            },
                                        }}
                                        transition={'all 0.3s ease-out'}

                                    >

                                        <Recorder
                                            recorder={recorder}
                                            i={i}
                                            cliente={cliente}
                                            id={id}
                                            idVideo={idVideo}
                                            setDisabledButton={setDisabledButton}
                                            setDisabledButtonAbrirSitioWeb={setDisabledButtonAbrirSitioWeb}
                                            cicleTest={cicleTest}
                                            test={prueba}
                                            setDisabledTarea={setDisabledTarea}
                                            disabledTarea={disabledTarea} // NO LO ESTA USANDO PORQUE COMENTAMOS EL DE QUE SE TRATA ESTA PRUEBA
                                            intervalTime={intervalTime}
                                            seconds={seconds}
                                            typeTest={typeTest}
                                            showTasks={showTasks}
                                            secondsIframe={secondsIframe}
                                            respuestaTareas={respuestaTareas}
                                            remainingTime={remainingTime}
                                            intervalForTimeout={intervalForTimeout}
                                            isMobile={isMobile}
                                            renderForMobile={renderForMobile}
                                            mimeType={mimeType}
                                            dataAvailableSource={dataAvailableSource}
                                            bienvenida={bienvenida} //  NO LO ESTA USANDO PORQUE COMENTAMOS EL DE QUE SE TRATA ESTA PRUEBA
                                            previsualizador={previsualizador}
                                            combineMediaStream={combineMediaStream}
                                        />

                                        <Tareas
                                            recorder={recorder}
                                            i={i}
                                            setCargando={setCargando}
                                            test={prueba}
                                            setDisabledButton={setDisabledButton}
                                            disabledButton={disabledButton}
                                            disabledButtonAbrirSitioWeb={disabledButtonAbrirSitioWeb}
                                            cicleTest={cicleTest}
                                            setCicleTest={setCicleTest}
                                            setAbrioElSitioWeb={setAbrioElSitioWeb}
                                            setDisabledTarea={setDisabledTarea}
                                            disabledTarea={disabledTarea}
                                            intervalTime={intervalTime}
                                            seconds={seconds}
                                            showTasks={showTasks}
                                            setShowTasks={setShowTasks}
                                            setRespuestaTareas={setRespuestaTareas}
                                            respuestaTareas={respuestaTareas}
                                            remainingTime={remainingTime}
                                            intervalForTimeout={intervalForTimeout}
                                            pointsByNodeId={pointsByNodeId}
                                            setPointsByNodeId={setPointsByNodeId}
                                            textForBtnHide={textForBtnHide}
                                            setTextForBtnHide={setTextForBtnHide}
                                            arrayOrden={arrayOrden}
                                            textBtnContinue={textBtnContinue}
                                            setTextBtnContinue={setTextBtnContinue}
                                            setHidden={setHidden}
                                            renderForMobile={renderForMobile}
                                            nodesAndTimeOfFigma={nodesAndTimeOfFigma}
                                            isMobile={isMobile}
                                            dataAvailableSource={dataAvailableSource}
                                            hideTasks={hideTasks}
                                            soyTareaPrototipo={soyTareaPrototipo}
                                            linkProto={prototipo}
                                        />
                                    </Flex>
                                </Flex>
                            </Box>
                        }
                    </Flex>
                    //ACÁ FINALIZAN TODAS LAS PANTALLAS DE TAREAS CON EL RECORDER
                    :
                    <Stack>
                        <Text fontSize={'2xl'}>No tienes acceso a este test</Text>
                    </Stack>
                )}
            {comprobacion && access && cargando &&
                <Flex
                    as={'section'}
                    className='progress-bar-section'
                    flexDirection={'column'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    width={'100%'}
                    id='SOYYO'
                >
                    <ProgressBarContainer prev={previsualizador} />
                </Flex>
            }
            {
                prototipo !== '' && !cargando &&
                <IframeComponent
                    link={prototipo}
                    test={prueba}
                    cicleTest={cicleTest}
                    recorder={recorder}
                    disabledTarea={disabledTarea}
                    pointsByNodeId={pointsByNodeId}
                    setPointsByNodeId={setPointsByNodeId}
                    showTasks={showTasks}
                    setShowTasks={setShowTasks}
                    setTextForBtnHide={setTextForBtnHide}
                    i={i}
                    hidden={hidden}
                    setHidden={setHidden}
                    comprobacion={comprobacion}
                    arrayOrden={arrayOrden}
                    setTextBtnContinue={setTextBtnContinue}
                    nodesAndTimeOfFigma={nodesAndTimeOfFigma}
                    seconds={seconds}
                    heightBarraDeTareas={heightBarraDeTareas}
                    updateHeight={updateHeight}
                />
            }
            {!isMobile &&
                <Flex
                    as={'footer'}
                    className='footer-creado-con'
                    justifyContent={'flex-end'}
                    alignItems={'center'}
                    position={'absolute'}
                    bottom={'0'}
                    right={'0'}
                    marginBottom={'40px'}
                    marginRight={'55px'}
                    display={(recorder && !soyTareaPrototipo) ? { sm: 'none', md: 'none', lg: 'flex', xl: 'flex', base: 'none' } : 'none'}
                    zIndex={1000}
                >
                    <a
                        href="https://www.nular.co/"
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            textDecoration: 'none',
                        }}
                    >
                        <span className="visually-hidden">Página creada por Nular. Visita nuestra página. (Se abrirá en la misma pestaña)</span>
                        <Text
                            color={'#9983AA'}
                            fontSize={'0.875rem'}
                            fontWeight={'600'}
                            lineHeight={'1.7635rem'}
                            style={{ ariaHidden: 'true' }}
                        >
                            Creado con
                        </Text>
                        <Box
                            marginLeft={'4px'}
                            style={{ ariaHidden: 'true' }}
                        >
                            <LogoNularVioleta />
                        </Box>
                    </a>
                </Flex>
            }

        </Flex>

    )
}


export default TestPrototypeComponent


