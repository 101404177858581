import {useEffect, useState} from 'react';

const useGanancias = () => {

    const [ganancias, setGanancias] = useState([]);
    
    const getAllGanancias = async () => {
        const url = '/gananciasRegistro.json';
        const response = await fetch(url);
        const data = await response.json();
        const items = data.map(item => ({
            currency: item.currency,
            idPais: item.idPais,
            maxEntregado: item.maxEntregado,
            precio1: item.precio1,
            precio2: item.precio2
        }))
        setGanancias(items);
    }

    useEffect(() => {
        getAllGanancias();
    }, []);

    return {
        ganancias
    }
}

export default useGanancias;