import { Box, Center, Flex, Text } from '@chakra-ui/react'
import React, { useState, useEffect } from 'react'
import ProgressBar from './ProgressBar'
import { useTestDbContext } from '../../context/testsDbContext'
import { useNavigate } from 'react-router-dom'
import LogoNularBlanco from '../../icons/svg/LogoNularBlanco'
import LogoNularVioleta from '../../icons/svg/LogoNularVioleta'
// import LogoNularBlanco from '../icons/svg/LogoNularBlanco';

const ProgressBarContainer = ({ prev }) => {

    const { cantResponses, lengthResponses, setLengthResponses } = useTestDbContext()
    const [porcentaje, setPorcentaje] = useState(0)
    const navigate = useNavigate()

    useEffect(() => {

        if (prev) {

            setPorcentaje(100)
            if (porcentaje >= 100) {
                localStorage.getItem('test') && localStorage.removeItem('test')
                setLengthResponses(0)
                cantResponses.current = 0
                navigate('/fin-test')
            }


        } else {
            let cuenta = Math.floor(lengthResponses * 100 / cantResponses.current)

            setPorcentaje(cuenta)
            if (porcentaje >= 100) {
                localStorage.getItem('test') && localStorage.removeItem('test')
                setLengthResponses(0)
                cantResponses.current = 0
                navigate('/fin-test')
            }
        }

    }, [cantResponses, porcentaje, navigate, lengthResponses])





    return (
        <Center
            minH={'100vh'}
            flexDirection={'column'}
            justifyContent={'center'}
            padding={'50px'}
            maxW={{ sm: '100%', md: '455px', lg: '590px', xl: '590px', base: '100%' }}
            zIndex={'1000'} //OJOTA
        >
            <Box>
                <Flex
                    textAlign={'center'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    marginBottom={{ sm: '100px', md: '100px', lg: '100px', xl: '100px', base: '100px' }}
                >
                    <LogoNularVioleta w={'190px'} h={'80px'} />
                </Flex>
                <Box
                    textAlign={'center'}
                >
                    <Text
                        fontSize={{ sm: '1rem', md: '1rem', lg: '1.375rem', xl: '1.375rem', base: '1rem' }}
                        lineHeight={{ sm: '25px', md: '25px', lg: '35px', xl: '35px', base: '25px' }}
                        marginY={'5px'}
                        fontWeight={'500'}
                        color={'#6C5280'}
                    >
                        No cierres esto ni bloquees la pantalla hasta que no termine de subir la grabación.
                    </Text>
                </Box>
                <ProgressBar value={porcentaje} />
            </Box>


        </Center>
    )
}

export default ProgressBarContainer