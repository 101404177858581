import React from 'react'
import { Stack, Container, Center, Text, Button, Box, Flex } from '@chakra-ui/react'
import { useTestContext } from '../../context/testContext'
import { useTestDbContext } from '../../context/testsDbContext'
import { isIOS, isMobile } from 'react-device-detect'



const Permisos = ({ cicleComprobacion, setCicleComprobacion, typeTest, bienvenida, titulo }) => {

    const { handleCicle, access, setAccess, escenario } = useTestContext()
    const { setTrackPermisos } = useTestDbContext()
    const [err, setErr] = React.useState(false)



    const handlePermisos = () => {


        navigator.mediaDevices.getUserMedia({
            audio: true
        })

            .then(stream => {
                setTrackPermisos(stream)
                setAccess(true)
                setErr(false)
            })
            .catch(err => {
                setErr(true)
            })
    }

    return (
        <Container
            as={'section'}
            className='container-permisos'
            margin={0}
            padding={0}
            w={{ sm: '100%', md: '500px', lg: '500px', xl: '500px', base: '100%' }}
            height={'100%'}
            minHeight={isMobile ? (isIOS ? '-webkit-fill-available' : `calc(var(--vh, 1vh) * 100)`) : '100vh'}
            justifyContent={'center'}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            paddingY={'50px'}
        >
            {titulo &&
                <Text
                    as={'h1'}
                    width={'100%'}
                    fontSize={{ sm: '18px', md: '18px', lg: '1.25rem', xl: '1.25rem', base: '18px' }}
                    fontWeight={'700'}
                    lineHeight={{ sm: '1.5rem', md: '1.5rem', lg: '2.125rem', xl: '2.125rem', base: '1.5rem' }}
                    marginBottom={'25px'}
                    textAlign={'left'}
                    color={'#6C5280'}
                >
                    {titulo.split('\n').map((item, key) => {
                        return <span key={key}>{item}<br /></span>
                    })}
                </Text>
            }
            <Text
                as={'p'}
                width={'100%'}
                fontSize={{ sm: '1rem', md: '1rem', lg: '1rem', xl: '1rem', base: '1rem' }}
                fontWeight={'500'}
                lineHeight={{ sm: '1.5rem', md: '1.5rem', lg: '1.75rem', xl: '1.75rem', base: '1.5rem' }}
                marginBottom={'20px'}
                textAlign={'left'}
                color={'#6C5280'}
            >

                {bienvenida.split('\n').map((item, key) => {
                    return <span key={key}>{item}<br /></span>
                })}
            </Text>

            {access && <Text
                as={'span'}
                width={'100%'}
                fontSize={{ sm: '1rem', md: '1rem', lg: '1rem', xl: '1rem', base: '1rem' }}
                lineHeight={{ sm: '1.5rem', md: '1.5rem', lg: '1.75rem', xl: '1.75rem', base: '1.5rem' }}
                textAlign={'left'}
                fontWeight={'600'}
                marginBottom={'40px'}
                color={'#F57535'}
            >
                ¡Genial! ya puedes avanzar.
            </Text>}

            {err && <Text
                as={'span'}
                width={'100%'}
                fontSize={{ sm: '1rem', md: '1rem', lg: '1rem', xl: '1rem', base: '1rem' }}
                lineHeight={{ sm: '1.5rem', md: '1.5rem', lg: '1.75rem', xl: '1.75rem', base: '1.5rem' }}
                textAlign={'left'}
                fontWeight={'600'}
                marginBottom={'40px'}
                color={'#F57535'}
            >
                El acceso a tu micrófono está bloqueado. Para avanzar, permite el acceso al mismo en tu navegador y recarga la página.
            </Text>}


            <Flex
                w={'100%'}
            >
                <Button
                    w={'auto'}
                    height={'50px'}
                    fontSize={{ sm: '1rem', md: '1rem', lg: '1rem', xl: '1rem', base: '1rem' }}
                    fontWeight={'600'}
                    borderRadius={'30px'}
                    backgroundColor={'#7A628C'}
                    color={'#FFFFFF'}
                    paddingY={'15px'}
                    paddingX={'30px'}
                    onClick={access ? (typeTest === 'prototipo-de-figma' ? () => { setCicleComprobacion(cicleComprobacion + 1) } : handleCicle) : handlePermisos}
                    _hover={{ backgroundColor: '#9983AA' }}
                    _active={{ backgroundColor: '#5F4B73' }}
                    _focusVisible={{ backgroundColor: '#5F4B73' }}
                    type='submit'
                    marginTop={err || access ? '0px' : '35px'}
                >
                    {access ? 'Continuar' : 'Permitir el uso del micrófono'}
                </Button>
            </Flex>

        </Container>
    )
}

export default Permisos