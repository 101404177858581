const preguntas = [
    
    {
        pregunta: [
            {
                label: "Nombre",
                id: "nombre",
                name: "nombre",
                type: "text",
                obligatorio: true,
                key: 1,
            },
            {
                label: "Apellido",
                id: "apellido",
                name: "apellido",
                type: "text",
                obligatorio: true,
                key: 2,
            },
            {
                label: "E-mail",
                id: "email",
                name: "email",
                type: "email",
                obligatorio: true,
                key: 3,
            },
            {
                label: "Contraseña",
                id: "password",
                name: "password",
                type: "password",
                obligatorio: true,
                key: 4,
            },
        ],
    },
    {
        pregunta: [
            {
                label: "Genero",
                id: "genero",
                name: "genero",
                type: "radio",
                opciones: ['Hombre', 'Mujer', 'No Binario'],
                obligatorio: true,
                key: 5,
            },
            {
                label: "Fecha de nacimiento",
                id: "fechaNacimiento",
                name: "fechaNacimiento",
                type: "date",
                obligatorio: true,
                key: 6,

            },
            {
                label: "Pais",
                id: "pais",
                name: "pais",
                type: "select",
                obligatorio: true,
                opciones: [
                    "Argentina"
                    , "Bolivia"
                    , "Brasil"
                    , "Colombia"
                    , "Costa Rica"
                    , "Cuba"
                    , "Chile"
                    , "Ecuador"
                    , "El Salvador"
                    , "España"
                    , "Guatemala"
                    , "Haití"
                    , "Honduras"
                    , "México"
                    , "Nicaragua"
                    , "Panamá"
                    , "Paraguay"
                    , "Perú"
                    , "República Dominicana"
                    , "Uruguay"
                    , "Venezuela"
                    , "-"
                    , "Afganistán"
                    , "Albania"
                    , "Alemania"
                    , "Andorra"
                    , "Angola"
                    , "Antigua y Barbuda"
                    , "Arabia Saudita"
                    , "Argelia"
                    , "Argentina"
                    , "Armenia"
                    , "Australia"
                    , "Austria"
                    , "Azerbaiyán"
                    , "Bahamas"
                    , "Bangladés"
                    , "Barbados"
                    , "Baréin"
                    , "Bélgica"
                    , "Belice"
                    , "Benín"
                    , "Bielorrusia"
                    , "Birmania/Myanmar"
                    , "Bolivia"
                    , "Bosnia y Herzegovina"
                    , "Botsuana"
                    , "Brasil"
                    , "Brunéi"
                    , "Bulgaria"
                    , "Burkina Faso"
                    , "Burundi"
                    , "Bután"
                    , "Cabo Verde"
                    , "Camboya"
                    , "Camerún"
                    , "Canadá"
                    , "Catar"
                    , "Chad"
                    , "Chile"
                    , "China"
                    , "Chipre"
                    , "Ciudad del Vaticano"
                    , "Colombia"
                    , "Comoras"
                    , "Corea del Norte"
                    , "Corea del Sur"
                    , "Costa de Marfil"
                    , "Costa Rica"
                    , "Croacia"
                    , "Cuba"
                    , "Dinamarca"
                    , "Dominica"
                    , "Ecuador"
                    , "Egipto"
                    , "El Salvador"
                    , "Emiratos Árabes Unidos"
                    , "Eritrea"
                    , "Eslovaquia"
                    , "Eslovenia"
                    , "España"
                    , "Estados Unidos"
                    , "Estonia"
                    , "Etiopía"
                    , "Filipinas"
                    , "Finlandia"
                    , "Fiyi"
                    , "Francia"
                    , "Gabón"
                    , "Gambia"
                    , "Georgia"
                    , "Ghana"
                    , "Granada"
                    , "Grecia"
                    , "Guatemala"
                    , "Guyana"
                    , "Guinea"
                    , "Guinea ecuatorial"
                    , "Guinea-Bisáu"
                    , "Haití"
                    , "Honduras"
                    , "Hungría"
                    , "India"
                    , "Indonesia"
                    , "Irak"
                    , "Irán"
                    , "Irlanda"
                    , "Islandia"
                    , "Islas Marshall"
                    , "Islas Salomón"
                    , "Israel"
                    , "Italia"
                    , "Jamaica"
                    , "Japón"
                    , "Jordania"
                    , "Kazajistán"
                    , "Kenia"
                    , "Kirguistán"
                    , "Kiribati"
                    , "Kuwait"
                    , "Laos"
                    , "Lesoto"
                    , "Letonia"
                    , "Líbano"
                    , "Liberia"
                    , "Libia"
                    , "Liechtenstein"
                    , "Lituania"
                    , "Luxemburgo"
                    , "Macedonia del Norte"
                    , "Madagascar"
                    , "Malasia"
                    , "Malaui"
                    , "Maldivas"
                    , "Malí"
                    , "Malta"
                    , "Marruecos"
                    , "Mauricio"
                    , "Mauritania"
                    , "México"
                    , "Micronesia"
                    , "Moldavia"
                    , "Mónaco"
                    , "Mongolia"
                    , "Montenegro"
                    , "Mozambique"
                    , "Namibia"
                    , "Nauru"
                    , "Nepal"
                    , "Nicaragua"
                    , "Níger"
                    , "Nigeria"
                    , "Noruega"
                    , "Nueva Zelanda"
                    , "Omán"
                    , "Países Bajos"
                    , "Pakistán"
                    , "Palaos"
                    , "Panamá"
                    , "Papúa Nueva Guinea"
                    , "Paraguay"
                    , "Perú"
                    , "Polonia"
                    , "Portugal"
                    , "Reino Unido"
                    , "República Centroafricana"
                    , "República Checa"
                    , "República del Congo"
                    , "República Democrática del Congo"
                    , "República Dominicana"
                    , "República Sudafricana"
                    , "Ruanda"
                    , "Rumanía"
                    , "Rusia"
                    , "Samoa"
                    , "San Cristóbal y Nieves"
                    , "San Marino"
                    , "San Vicente y las Granadinas"
                    , "Santa Lucía"
                    , "Santo Tomé y Príncipe"
                    , "Senegal"
                    , "Serbia"
                    , "Seychelles"
                    , "Sierra Leona"
                    , "Singapur"
                    , "Siria"
                    , "Somalia"
                    , "Sri Lanka"
                    , "Suazilandia"
                    , "Sudán"
                    , "Sudán del Sur"
                    , "Suecia"
                    , "Suiza"
                    , "Surinam"
                    , "Tailandia"
                    , "Tanzania"
                    , "Tayikistán"
                    , "Timor Oriental"
                    , "Togo"
                    , "Tonga"
                    , "Trinidad y Tobago"
                    , "Túnez"
                    , "Turkmenistán"
                    , "Turquía"
                    , "Tuvalu"
                    , "Ucrania"
                    , "Uganda"
                    , "Uruguay"
                    , "Uzbekistán"
                    , "Vanuatu"
                    , "Venezuela"
                    , "Vietnam"
                    , "Yemen"
                    , "Yibuti"
                    , "Zambia"
                    , "Zimbabue"
                ],
                key: 7
            },
            {
                label: "Ciudad",
                id: "ciudad",
                name: "ciudad",
                type: "text",
                obligatorio: true,
                key: 8
            },
            {
                label: "Número de WhatsApp",
                id: "whatsapp",
                name: "whatsapp",
                type: "telefono",
                obligatorio: true,
                key: 9
            },
            {
                label: "Estado civil",
                id: "estadoCivil",
                name: "estadoCivil",
                type: "select",
                opciones: ['Soltero/a', 'Casado/a', 'En una relación'],
                obligatorio: true,
                key: 10
            }

        ]
    },
    {
        pregunta: [
            {
                label: "¿Cuál es tu nivel de conocimiento de UX, diseño web y/o desarrollo web?",
                id: "conocimientos",
                name: "conocimientos",
                type: "select",
                opciones: ['No tengo conocimientos', 'Principiante','Intermedio', 'Avanzado'],
                obligatorio: true,
                key: 11
            },
            {
                label: "Dispositivos que posees y puedes usar para realizar tests, entrevistas y encuestas (puedes seleccionar más de uno)",
                id: "dispositivos",
                name: "dispositivos",
                type: "checkbox",
                opciones: ['Computadora de escritorio o laptop', 'Teléfono Android', 'iPhone', 'Tablet Android', 'iPad', 'Apple Watch', 'Otros smart watches'],
                obligatorio: true,
                key: 12
            },
            {
                label: "¿Qué lenguajes hablas fluido?",
                id: "lenguajes",
                name: "lenguajes",
                type: "select",
                obligatorio: true,
                opciones: ['Español',
                    'Inglés',
                    '-',
                    'Alemán',
                    'Árabe',
                    'Bengalí',
                    'Catalán',
                    'Chino cantonés',
                    'Chino mandarín',
                    'Coreano',
                    'Danés',
                    'Español',
                    'Francés',
                    'Griego',
                    'Hindi',
                    'Indonesio',
                    'Inglés',
                    'Italiano',
                    'Japonés',
                    'Latín',
                    'Maratí',
                    'Portugués',
                    'Ruso',
                    'Sueco',
                    'Telugú',
                    'Turco',
                    'Urdu',
                    'Vietnamita'],
                key: 13
            },
        ]
    },
    {
        pregunta: [
            {
                label: "Nivel de educación",
                id: "nivelEducacion",
                name: "nivelEducacion",
                type: "select",
                opciones: ['Primario', 'Secundario','Terciario', "Universitario"],
                obligatorio: true,
                key: 14
            },
            {
                label: "¿Tienes hijos?",
                id: "hijos",
                name: "hijos",
                type: "radio",
                opciones: ['Si', 'No'],
                obligatorio: true,
                key: 15
            },
            {
                label: "Tipo de empleo",
                id: "tipoEmpleo",
                name: "tipoEmpleo",
                type: "select",
                opciones: ['Independiente', 'Dueño/a de negocio', 'Trabajo para una empresa pequeña (Menos de 100 empleados)', 'Trabajo para una empresa mediana (Entre 100 y 999 empleados)', 'Trabajo para una empresa grande (1000 empleados)', 'Desempleado/a'],
                obligatorio: true,
                key: 17
            },
            {
                label: "Puesto laboral",
                id: "cargoLaboral",
                name: "cargoLaboral",
                type: "text-laboral",
                obligatorio: true,
                key: 16
            },
            {
                label: "Industria en la que trabajas",
                id: "industria",
                name: "industria",
                type: "select",
                opciones: [
                    'Administración gubernamental'
                    , 'Aeronáutica/Aviación'
                    , 'Agricultura'
                    , 'Alimentación y bebidas'
                    , 'Almacenamiento'
                    , 'Animación'
                    , 'Apuestas y casinos'
                    , 'Arquitectura y planificación'
                    , 'Artículos de consumo'
                    , 'Artículos de lujo y joyas'
                    , 'Artículos deportivos'
                    , 'Artesanía'
                    , 'Artes escénicas'
                    , 'Asuntos internacionales'
                    , 'Atención a la salud mental'
                    , 'Atención sanitaria y hospitalaria'
                    , 'Automación industrial'
                    , 'Banca de inversiones'
                    , 'Bellas Artes'
                    , 'Bibliotecas'
                    , 'Bienes inmobiliarios'
                    , 'Bienes inmobiliarios comerciales'
                    , 'Biotecnología'
                    , 'Capital de riesgo y capital privado'
                    , 'Construcción naval'
                    , 'Consultoría de estrategia y operaciones'
                    , 'Contabilidad'
                    , 'Cosmética'
                    , 'Cristal, cerámica y hormigón'
                    , 'Cumplimiento de la ley'
                    , 'Departamento de defensa y del espacio exterior'
                    , 'Deportes'
                    , 'Derecho'
                    , 'Desarrollo de programación'
                    , 'Desarrollo y comercio internacional'
                    , 'Diseño'
                    , 'Diseño gráfico'
                    , 'Dotación y selección de personal'
                    , 'Educación primaria/secundaria'
                    , 'Ejército'
                    , 'E-learning'
                    , 'Electrónica de consumo'
                    , 'Embalaje y contenedores'
                    , 'Energía renovable y medio ambiente'
                    , 'Enseñanza superior'
                    , 'Entretenimiento'
                    , 'Envío de paquetes y carga'
                    , 'Equipos informáticos'
                    , 'Escritura y edición'
                    , 'Filantropía'
                    , 'Formación profesional y capacitación'
                    , 'Fotografía'
                    , 'Gabinetes estratégicos'
                    , 'Ganadería'
                    , 'Gestión de inversiones'
                    , 'Gestión de organizaciones sin ánimo de lucro'
                    , 'Gestión educativa'
                    , 'Hostelería'
                    , 'Importación y exportación'
                    , 'Imprenta'
                    , 'Industria aeroespacial y aviación'
                    , 'Industria farmacéutica'
                    , 'Industria textil y moda'
                    , 'Ingeniería civil'
                    , 'Ingeniería industrial o mecánica'
                    , 'Instalaciones y servicios recreativos'
                    , 'Instituciones religiosas'
                    , 'Interconexión en red'
                    , 'Internet'
                    , 'Investigación'
                    , 'Investigación de mercado'
                    , 'Judicial'
                    , 'Lácteos'
                    , 'Logística y cadena de suministro'
                    , 'Manufactura eléctrica/electrónica'
                    , 'Manufactura ferroviaria'
                    , 'Maquinaria'
                    , 'Marketing y publicidad'
                    , 'Materiales de construcción'
                    , 'Material y equipo de negocios'
                    , 'Medicina alternativa'
                    , 'Medios de comunicación en línea'
                    , 'Medios de difusión'
                    , 'Mercados de capital'
                    , 'Minería y metalurgia'
                    , 'Mobiliario'
                    , 'Música'
                    , 'Museos e instituciones'
                    , 'Nanotecnología'
                    , 'Naval'
                    , 'Ocio, viajes y turismo'
                    , 'Oficina ejecutiva'
                    , 'Oficina legislativa'
                    , 'Organización cívica y social'
                    , 'Organización política'
                    , 'Películas y cine'
                    , 'Periódicos'
                    , 'Petróleo y energía'
                    , 'Piscicultura'
                    , 'Plásticos'
                    , 'Política pública'
                    , 'Producción alimentaria'
                    , 'Producción multimedia'
                    , 'Productos de papel y forestales'
                    , 'Productos químicos'
                    , 'Profesiones médicas'
                    , 'Protección civil'
                    , 'Publicaciones'
                    , 'Recaudación de fondos'
                    , 'Recursos humanos'
                    , 'Relaciones gubernamentales'
                    , 'Relaciones públicas y comunicaciones'
                    , 'Resolución de conflictos por terceras partes'
                    , 'Restaurantes'
                    , 'Sanidad, bienestar y ejercicio'
                    , 'Sector automovilístico'
                    , 'Sector textil'
                    , 'Seguridad del ordenador y de las redes'
                    , 'Seguridad e investigaciones'
                    , 'Seguros'
                    , 'Semiconductores'
                    , 'Servicio al consumidor'
                    , 'Servicio de información'
                    , 'Servicios de eventos'
                    , 'Servicios financieros'
                    , 'Servicios infraestructurales'
                    , 'Servicios jurídicos'
                    , 'Servicios médicos'
                    , 'Servicios medioambientales'
                    , 'Servicios para el individuo y la familia'
                    , 'Servicios públicos'
                    , 'Servicios y tecnologías de la información'
                    , 'Software'
                    , 'Subcontrataciones/Offshoring'
                    , 'Supermercados'
                    , 'Tabaco'
                    , 'Tecnología inalámbrica'
                    , 'Telecomunicaciones'
                    , 'Textil'
                    , 'Traducción y localización'
                    , 'Transporte por carretera o ferrocarril'
                    , 'Venta al por mayor'
                    , 'Venta al por menor'
                    , 'Veterinaria'
                    , 'Videojuegos'
                    , 'Vinos y licores'
                ],
                obligatorio: true,
                key: 18
            },
            {
                label: "Empresa en donde trabajas (Opcional)",
                id: "empresa",
                name: "empresa",
                type: "text",
                obligatorio: false,
                key: 19
            },
            {
                label: "Ingresos mensuales del hogar (Opcional)",
                id: "ingresos",
                name: "ingresos",
                type: "select",
                opciones: ['$29.999 o menos', '$30.000 a $49.999', '$50.000 a $69.999', '$70.000 a $99.999', '$100.000 a $129.999', '$130.000 a $159.999', '$160.000 a $199.999', '$200.000 a $249.999', '$250.000 o más'],
                obligatorio: false,
                key: 20
            },
            {
                parrafo: "Los dos últimos campos son opcionales. Si nos compartes estos datos podrás participar de más tests, entrevistas y encuestas.",
                id: "aclaracion",
                name: "aclaracion",
                type: "parrafo",
                obligatorio: false,
                key: 21
            }
        ]
    },
    {
        pregunta: [
            {
                label: "URL de perfil de LinkedIn (Opcional)",
                id: "linkedin",
                name: "linkedin",
                type: "text",
                obligatorio: false,
                key: 24
            },
            {
                parrafo: "Si nos compartes este dato podrás participar de más tests, entrevistas y encuestas.\n Ingrésalo con el formato \n http://www.linkedin.com/in/nombredeusuario",
                id: "aclaracionLinkedin",
                name: "aclaracion",
                type: "parrafo",
                obligatorio: false,
                key:23
            }
        ]
    },
    {
        pregunta: [
            {
                label: "¿Estás abierto/a a participar de investigaciones de marcas para adultos?",
                id: "participacionAdultos",
                name: "participacionAdultos",
                type: "radio",
                opciones: ['Si', 'No'],
                obligatorio: true,
                key: 22
            }
        ]
    },
    {
        pregunta: [
            {
                id: "terminos",
                name: "terminos",
                type: "checkbox",
                opciones: ['Acepto los términos y condiciones de la plataforma'],
                obligatorio: true,
                key: 23
            }
        ]
    }

]

export default preguntas;

