import React from 'react'
import { Center, Container, Flex, Stack } from '@chakra-ui/react'
import ScreenShare from './ScreenShare'
import { isIOS, isMobile } from 'react-device-detect'




const Pantalla = ({ cicleComprobacion, setCicleComprobacion, typeTest }) => {


    return (
        <Container
            as={'section'}
            className='container-compartir-pantalla'
            margin={'0px'}
            padding={'0px'}
            width={'auto'}
            height={'100%'}
            minHeight={isMobile ? (isIOS ? '-webkit-fill-available' : `calc(var(--vh, 1vh) * 100)`) : '100vh'}
            justifyContent={'center'}
            display={'flex'}
            flexDirection={'column'}
        >
            <Flex
                height={'100%'}
                flexDirection={'column'}
            >
                <ScreenShare cicleComprobacion={cicleComprobacion} setCicleComprobacion={setCicleComprobacion} typeTest={typeTest} />
            </Flex>
        </Container>
    )
}

export default Pantalla