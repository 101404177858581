import { Button, Box, Heading, Text, Stack } from '@chakra-ui/react'
import React, { useState, useEffect } from 'react'
import preguntas from './preguntas'
import QuizItem from './QuizItem'
import { useAuth } from '../../context/authContext'

const Quiz = () => {
    const [preguntaActual, setPreguntaActual] = useState(0)
    const [stateQuiz, setStateQuiz] = useState(false)
    const [error, setError] = useState(false)
    const { userNew, setUserNew, signUp, createUser, logOut } = useAuth()
    const [opacity, setOpacity] = useState('100%')
    window.dataLayer = window.dataLayer || [];




    const handleEmailInvalido = async (value) => {
        handleError('email', false)

        if (value.includes('@')) {
            document.querySelector('.email-invalido').style = 'display:none !important'
            return false
        } else {
            document.querySelector('.email-invalido').style = 'display:block !important'
            return true
        }

    }

    const opacityInputs = (nro) => {
        Array.from(document.getElementsByTagName('input')).forEach(input => {
            input.style.opacity = nro
        })
    }

    const handlePassword = async (valor) => {
        handleError('password', false)

        if ((valor.length > 6) && (/[A-Z]/).test(valor) && (/[a-z]/).test(valor) && (/[0-9]/).test(valor)) {
            document.querySelector('.password-invalido').style = 'display:none !important'
            return false
        } else {
            document.querySelector('.password-invalido').style = 'display:block !important'
            return true
        }

    }



    const handleError = async (id, cond) => {

        Array.from(document.getElementsByClassName('invalid')).forEach(p => {
            if (p.dataset.target === id) {
                p.style = cond ? 'display:block !important' : 'display:none !important'
            }
        })

        document.getElementById(id).ariaInvalid = cond
        if (id === 'whatsapp') { document.getElementById('caracteristica').ariaInvalid = cond }


    }

    const handleValue = async (type, id) => {
        switch (type) {
            case 'email':
                if (document.getElementById(id).value === '') {
                    handleError(id, true)
                    return false
                } else if (await handleEmailInvalido(document.getElementById(id).value)) {
                    return false
                } else {
                    handleError(id, false)
                    return true
                }

            case 'password':
                if (document.getElementById(id).value === '') {
                    handleError(id, true)
                    return false
                } else if (await handlePassword(document.getElementById(id).value)) {
                    return false
                } else {
                    handleError(id, false)
                    return true
                }

            case 'select':
                if (document.getElementById(id).value === '') {
                    handleError(id, true)
                    return false
                } else {
                    handleError(id, false)
                    return true
                }
            case 'text':
                if (document.getElementById(id).value === '') {
                    handleError(id, true)
                    return false
                } else {
                    handleError(id, false)
                    return true
                }

            case 'text-laboral':
                if (document.getElementById(id).value === '') {
                    handleError(id, true)
                    return false
                } else {
                    handleError(id, false)
                    return true
                }

            case 'radio':
                let devolucion = false
                for (let input of Array.from(document.querySelectorAll(`input[name=${id}]`))) {
                    if (input.checked) {
                        handleError(id, false)
                        devolucion = true
                        break
                    }
                }

                if (!devolucion) {
                    handleError(id, true)
                }

                return devolucion

            case 'checkbox':
                let acumCheckboxs = ''
                Array.from(document.getElementsByClassName(id)).forEach((el) => {
                    if (el.children[0].checked) {
                        acumCheckboxs += el.children[0].value + ','
                    }
                })

                if (acumCheckboxs.length > 0) {
                    handleError(id, false)
                    return true
                } else {
                    handleError(id, true)
                    return false
                }

            case 'date':
                if (document.getElementById(id).value === '') {
                    handleError(id, true)
                    return false
                } else {
                    handleError(id, false)
                    return true
                }

            case 'telefono':
                if ((document.getElementById(id).value === '') || (document.getElementById('caracteristica').value === '')) {
                    handleError(id, true)
                    return false
                } else {
                    handleError(id, false)
                    return true
                }

            default:
                break
        }

    }

    const handleErrorFirebase = (data) => {
        document.querySelector('.email-usado').style = 'display:block !important'
    }

    const handlePreguntaActual = async () => {
        if (preguntaActual === 0) {
            const sign = await signUp(userNew.email, userNew.password)
            opacityInputs('100%')
            sign ? setPreguntaActual(preguntaActual + 1) : handleErrorFirebase(sign)
        } else {
            if (preguntaActual < preguntas.length - 1) {
                setPreguntaActual(preguntaActual + 1)
            } else {
                const user = await createUser(userNew)
                setStateQuiz(true)
            }
        }
    }

    const handleCondicionPreguntaActual = async (e) => {
        let condicion = []
        opacityInputs('50%')
        if (document.querySelector('.email-usado')) document.querySelector('.email-usado').style = 'display:none !important'

        const ids = preguntas[preguntaActual].pregunta.map(el => {
            if (el.name === 'industria') {
                const obl = userNew.tipoEmpleo === 'Desempleado/a' ? false : true
                return { id: el.name, type: el.type, value: el.value, obligatorio: obl }
            } else if (el.name === 'cargoLaboral') {
                const obl = userNew.tipoEmpleo === 'Desempleado/a' ? false : true
                return { id: el.name, type: el.type, value: el.value, obligatorio: obl }
            } else {
                return { id: el.id, type: el.type, obligatorio: el.obligatorio }
            }
        })


        for (const id of ids) {
            if (id.obligatorio) {
                condicion.push(await handleValue(id.type, id.id))
            }
        }


        const search = condicion.find(el => el === false)

        search === undefined ? handlePreguntaActual() : opacityInputs('100%')

    }

    const handleAnterior = () => {
        setPreguntaActual(preguntaActual - 1)
    }

    const handleDataLayer = () => {
        window.dataLayer.push({ 'event': 'registroTesteador', });
    }

    useEffect(() => {
        setOpacity('100%')
    }, [])

    return (

        <Stack
            background={'#ECDFEA'}
            width={'100%'}
            marginY={'40px'}
            display={'flex'}
            flexDirection={'column'}
            borderRadius={'30px'}
        >
            {stateQuiz ?
                <Box
                    paddingX={{ md: '120px', sm: '40px', xl: '250px' }}
                    paddingY={'80px'}
                    textAlign={'center'}
                >
                    <Heading
                        fontSize={'2.2rem'}
                        lineHeight={'4rem'}
                        color={'#6C5280'}
                        marginBottom={'10px'}
                    >
                        ¡Muchas gracias por registrarte!
                    </Heading>
                    <Text
                        marginTop={'40px'}
                        fontSize={'1.1rem'}
                        lineHeight={'1.5rem'}
                        color={'#6C5280'}
                    >
                        Te enviamos un e-mail para verificar tu cuenta.
                    </Text>
                    {handleDataLayer()}
                    {/* <Button  h={'50px'} value={'back'} fontSize={'xl'} paddingX={'35px'} borderRadius={'0px'} background={'#c0005a'}><Link to={'/'}>Iniciar Sesión</Link></Button> */}
                </Box>
                :

                <Box paddingX={{ md: '120px', sm: '0px', xl: '250px' }} paddingTop={'80px'}>

                    <Heading marginBottom={'60px'} textAlign={'center'} color={'#6C5280'}>Regístrate como participante</Heading>
                    {preguntas[preguntaActual].pregunta.map(el =>
                        <QuizItem setError={setError} key={el.key} elemento={el} />
                    )}

                </Box>

            }
            {error && <Box py={'5px'} w={'100%'} background={'#c0005a'}><Text textAlign={'center'} fontSize={'xl'}>Hay campos incompletos</Text></Box>}

            {!stateQuiz &&
                <Box paddingX={{ md: '120px', sm: '0px', xl: '250px' }} paddingBottom={'80px'} w={'100%'} display={'flex'} justifyContent={preguntaActual > 0 ? 'space-between' : 'flex-end'}>
                    <Box w={'75%'} marginX={'auto'} display={'flex'} justifyContent={preguntaActual > 0 ? 'space-between' : 'center'}>
                        {preguntaActual > 0 &&
                            <Button
                                marginRight={'10px'}
                                h={'50px'}
                                value={'back'}
                                fontSize={'1rem'}
                                paddingX={'40px'}
                                borderRadius={'30px'}
                                backgroundColor={'#F57535'}
                                color={'#ffffff'}
                                onClick={handleAnterior}
                                _hover={{ backgroundColor: '#F57535' }}
                                _active={{ backgroundColor: '#F57535' }}
                                _focusVisible={{ backgroundColor: '#F57535' }}
                            >
                                Anterior
                            </Button>}

                        <Button
                            h={'50px'}
                            fontWeight={'600'}
                            marginLeft={'10px'}
                            fontSize={'1rem'}
                            value={'next'}
                            paddingX={'40px'}
                            borderRadius={'30px'}
                            backgroundColor={'#F57535'}
                            color={'#ffffff'}
                            onClick={handleCondicionPreguntaActual}
                            _hover={{ backgroundColor: '#F57535' }}
                            _active={{ backgroundColor: '#F57535' }}
                            _focusVisible={{ backgroundColor: '#F57535' }}

                        >
                            Siguiente
                        </Button>
                    </Box>
                </Box>
            }
        </Stack >


    )
}

export default Quiz