import { createContext, useContext } from "react";
import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    onAuthStateChanged,
    signOut,
    GoogleAuthProvider,
    signInWithPopup,
    sendEmailVerification,
    signInAnonymously
} from 'firebase/auth';
import { auth_participantes } from '../../firebase'
import { useState, useEffect } from 'react';
import { collection, setDoc, addDoc, getFirestore, doc, getDocs, query, where, updateDoc } from "firebase/firestore";
import generateRandomCode from '../functions/randomCode';
import getIp from '../functions/ip';
import Swal from 'sweetalert2';
import {db_participantes} from '../../firebase'
// import {db_clientes} from '../../firebase'

export const authContext = createContext();

export const useAuth = () => {
    const context = useContext(authContext);
    if (!context) {
        throw new Error("useAuth must be used within a AuthProvider");
    }
    return context;
}

export const AuthProvider = ({ children }) => {



    //DATABASE USUARIOS

    //FUNCTION TO CREATE USER WITH EMAIL AND PASSWORD
    const signUp = async (email, password) => {
        const sign = await createUserWithEmailAndPassword(auth_participantes, email, password)
            .then(async (user) => {
                await sendEmailVerification(user.user)
                setUserNew({ ...userNew, id: user.user.uid });
                return true;
            }).catch(error => {
                Swal.fire({
                    icon: 'error',
                    title: 'Error:',
                    text: `${error}`,
                    footer: '<p style="text-align:center">Por favor, envía este mensaje de error por email a soporte@greydive.com</p>',
                    showConfirmButton: false,
                    showCloseButton: true,
                })
                return false
            })

        return sign



    }

    const [loading, setLoading] = useState(true)

    //FUNCTION TO SIGN IN WITH EMAIL AND PASSWORD
    const logIn = (email, password) => {
        setLoading(true)
        return signInWithEmailAndPassword(auth_participantes, email, password)
    }

    const logInAnonimo = () => {
        setLoading(true)
        return signInAnonymously(auth_participantes)
    }

    const loginWithGoogle = () => {
        setLoading(true)
        const googleProvider = new GoogleAuthProvider();
        return signInWithPopup(auth_participantes, googleProvider);
    }

    //FUNCTION TO SIGN OUT
    const logOut = () => {
        setLoading(true)
        return signOut(auth_participantes)
    }





    //USER WITH EMAIL AND PASSWORD
    const [user, setUser] = useState(null)
    const [userProfile, setUserProfile] = useState({})
    const [userNew, setUserNew] = useState({
        dispositivos: [],
        whatsapp: "",
        condicion: 'apto',
        tipoEmpleo: ""
    })
    
    const queryUser = () => {
        if (user) {
            const q = query(collection(db_participantes, 'usuarios'), where('id', '==', user.uid))
            getDocs(q).then((snapshots) => {
                if (snapshots.size === 0) {
                    console.log('no existe')
                } else {
                    snapshots.docs[0].data()
                }
            })

        }

    }


    useEffect(() => {


        const unsuscribe = onAuthStateChanged(auth_participantes, (currentUser) => {
            setUser(currentUser)
            setLoading(false)
        })

        return () => unsuscribe()
    }, [])

    useEffect(() => {


        if (user) {
            const q = query(collection(db_participantes, 'usuarios'), where('id', '==', user.uid))
            getDocs(q).then((snapshots) => {
                if (snapshots.size === 0) {
                    setUserProfile({})
                } else {
                    setUserProfile(snapshots.docs[0].data())
                }
            })
        }
    }, [db_participantes, user])






    //CREAR USUARIO EN DATABASE

    const crearUsuario = (obj) => {

        const q = query(collection(db_participantes, 'usuarios'), where('id', '==', user.uid))
        getDocs(q).then((snapshots) => {
            if (snapshots.size === 0) {
                addDoc(collection(db_participantes, 'usuarios'), obj)
            } else {
                updateDoc(doc(db_participantes, "usuarios", snapshots.docs[0].id), {
                    ...obj
                });
            }
        })

    }

    const handleSession = async () => {
        const ip = await getIp('v4');

        const q = query(collection(db_participantes, 'usuarios'), where('ip', '==', ip))
        const querySnapshot = await getDocs(q);
        if (querySnapshot.size === 0) {
            return false
        } else {
            if (localStorage.getItem('idSession')) {
                return true
            } else {
                return false
            }
        }
    }

    const createUser = async (user) => {
        delete user.password
        user.yaCompleto = await handleSession()
        user.ip = await getIp('v4')


        const userRef = collection(db_participantes, 'usuarios')
        const userQuery = await setDoc(doc(userRef, user.id), user)
            .then((data) => {
                return true
            }).catch(error => {
                Swal.fire({
                    icon: 'error',
                    title: 'Error:',
                    text: `${error}`,
                    footer: '<p style="text-align:center">Por favor, envía este mensaje de error por email a soporte@greydive.com</p>',
                    showConfirmButton: false,
                    showCloseButton: true,
                })
                return false
            })

        signOut(auth_participantes)
        return userQuery
    }

    const addFedback = async (feedback) => {
        await signInAnonymously(auth_participantes)
        const feedbackObj = {
            feedback: feedback,
            usuario: user ? user.email : '',
        }


        const feedbackRef = collection(db_participantes, 'feedback')
        const feedbackQuery = await setDoc(doc(feedbackRef, generateRandomCode()), feedbackObj)
            .then((data) => {

                return true
            }
            ).catch(error => {
                Swal.fire({
                    icon: 'error',
                    title: 'Error:',
                    text: `${error}`,
                    footer: '<p style="text-align:center">Por favor, envía este mensaje de error por email a soporte@greydive.com</p>',
                    showConfirmButton: false,
                    showCloseButton: true,
                })
                return false
            })
        await logOut()
        return feedbackQuery
    }

    const addClientFeedback = async (feedback) => {
        await signInAnonymously(auth_participantes) //Acá no se si debería registrarlo dentro de participantes o clientes
        const feedbackObj = {
            feedback: feedback,
            usuario: user ? user.email : '',
        }
        const feedbackRef = collection(db_participantes, 'feedbackClientes')
        // const feedbackRef = collection(db_clientes, 'feedbackClientes') //Acá no se si debería mandarlo a la base de Clientes en vez de participantes, pero dejo 
        const feedbackQuery = await setDoc(doc(feedbackRef, generateRandomCode()), feedbackObj)
            .then((data) => {
                return true
            }
            ).catch(error => {
                Swal.fire({
                    icon: 'error',
                    title: 'Error:',
                    text: `${error}`,
                    footer: '<p style="text-align:center">Por favor, envía este mensaje de error por email a soporte@greydive.com</p>',
                    showConfirmButton: false,
                    showCloseButton: true,
                })
                return false
            })
        return feedbackQuery
    }





    return (
        <authContext.Provider value={{ 
            signUp, 
            createUser, 
            logIn, 
            user, 
            logOut, 
            loading, 
            setLoading, 
            loginWithGoogle, 
            crearUsuario, 
            db_participantes, 
            queryUser, 
            userProfile, 
            setUserNew, 
            userNew, 
            addFedback,
            addClientFeedback,
            logInAnonimo 
            }}>
            {children}
        </authContext.Provider>
    );
}