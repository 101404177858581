import React, { useEffect, useRef, useState } from 'react'
import { getOperatingSystem } from '../../functions/getOperatingSystem'
import { Client } from 'figma-js'
import { Box, Center, Spinner, Text } from '@chakra-ui/react'
import { checkPaths } from '../../functions/checkPath'
import Loader from '../../icons/LoaderEclipse'
import { getStartingPointFromApiFigma } from '../../functions/getStartingPoint'
import { isIOS } from 'react-device-detect';


const IframeComponent = ({
    link,
    test,
    cicleTest,
    recorder,
    disabledTarea,
    pointsByNodeId,
    setPointsByNodeId,
    showTasks,
    setShowTasks,
    setTextForBtnHide,
    i,
    hidden,
    setHidden,
    comprobacion,
    arrayOrden,
    setTextBtnContinue,
    nodesAndTimeOfFigma,
    seconds,
    heightBarraDeTareas,
    updateHeight
}) => {

    const isMobile = getOperatingSystem()

    const [maxValue, setMaxValue] = useState(0);
    const [nodeId, setNodeId] = useState(undefined);
    const [maxYValue, setMaxYValue] = useState(0);
    // const [url, setUrl] = useState(undefined);
    const [successScreen, setSuccessScreen] = useState(undefined);
    const [finishTask, setFinishTask] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [nodeIdForRender, setNodeIdForRender] = useState('LOADING');
    const [startingPoint, setStartingPoint] = useState();
    const [loadingApi, setLoadingApi] = useState(true)

    // const [presentedNodeId, setPresentedNodeId] = useState('');
    // const [initTime, setInitTime] = useState(0);

    const timeOfNodeInScreen = useRef(0)
    const intervalForTimeOfNodeInScreen = useRef(null)
    const objectForRenderMobile = useRef({})

    const URL_REGEX = /https:\/\/([\w\.-]+\.)?figma.com\/(file|proto)\/([0-9a-zA-Z]{22,128})(?:\/.*)?$/
    const regex_starting = /starting-point-node-id=([^&]+)/;
    const currentPrototype = useRef('');
    const cicleForLoadPrototype = useRef(0);

    const [APIfigma, setAPIfigma] = useState([]);

    const { tareas } = test;
    const { tipoTarea } = tareas[cicleTest];

    const client = Client({
        personalAccessToken: process.env.REACT_APP_FIGMA_KEY
    });

    const messageHandler = useRef(null);

    useEffect(() => {

        const handleResize = () => {
            if (!messageHandler.current) {
                messageHandler.current = (e) => {
                    if (e.origin !== window.location.origin && e.data.type === "MOUSE_PRESS_OR_RELEASE") {
                        if ((showTasks && tipoTarea !== 'accion') || (showTasks && tipoTarea !== 'pruebaFigma') || (showTasks && tipoTarea !== 'pruebaPrototipo')) {
                            setShowTasks(false)
                            setTextForBtnHide("Ver tarea");
                        }
                    }
                };
                window.addEventListener("message", messageHandler.current);
            }
        };

        i.current !== 'final' && handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);

    }, [showTasks, setShowTasks, setTextForBtnHide, i, tipoTarea, arrayOrden]);

    useEffect(() => {

        const absoluteboundingbox = (targetNode, presentNode) => {
            const searchNode = (node) => {
                if (node.id === presentNode) {
                    if (node.absoluteBoundingBox) {
                        presentNodeX = node.absoluteBoundingBox.x;
                        presentNodeY = node.absoluteBoundingBox.y;
                    }
                    node.children.forEach((childNode) => {
                        searchNode(childNode);
                    });
                } else if (node.id === targetNode && node.absoluteBoundingBox) {
                    targetNodeX = node.absoluteBoundingBox.x;
                    targetNodeY = node.absoluteBoundingBox.y;
                } else if (node.children) {
                    node.children.forEach((childNode) => {
                        searchNode(childNode);
                    });
                }
            };
            let targetNodeX = undefined;
            let targetNodeY = undefined;
            let presentNodeX = undefined;
            let presentNodeY = undefined;
            searchNode(APIfigma);
            return {
                targetNodeX,
                targetNodeY,
                presentNodeX,
                presentNodeY,
            };
        };

        const saveArrayOrden = (e) => {
            if (e.data.type === "PRESENTED_NODE_CHANGED") {
                if (arrayOrden.current[arrayOrden.current.length - 1] !== e.data.data.presentedNodeId) {
                    arrayOrden.current.push(e.data.data.presentedNodeId)
                    if (e.data.data.presentedNodeId === successScreen) {
                        tareas[cicleTest].resultado = checkPaths(arrayOrden.current, tareas[cicleTest].caminos)
                        setFinishTask(true)
                        cicleForLoadPrototype.current++
                        setTextBtnContinue('Continuar')
                    }
                }
            }
        }

        const handleMessage = event => {
            event.data.type === 'PRESENTED_NODE_CHANGED' && !isLoaded && setIsLoaded(true)
            saveArrayOrden(event)
            const { origin, data: { data, type } } = event;
            if (origin !== "https://www.figma.com") return;
            //only for mobile
            // if (isMobile) objectForRenderMobile.current.nodeId = event.data.data.presentedNodeId
            if (isMobile) {

                if (type === 'PRESENTED_NODE_CHANGED') {
                    if (comprobacion) {
                        objectForRenderMobile.current.end = seconds.current
                        nodesAndTimeOfFigma.current.push(objectForRenderMobile.current)
                        objectForRenderMobile.current = {}
                    }
                    setNodeIdForRender(event.data.data.presentedNodeId)
                }

            }
            ///////////////////////
            if (!data.nearestScrollingFrameMousePosition && !data.targetNodeMousePosition) return;
            const nodeId = data.presentedNodeId;
            let x = undefined;
            let y = undefined;
            if (data.nearestScrollingFrameMousePosition) {
                x = parseInt((data.nearestScrollingFrameMousePosition.x).toFixed(0));
                y = parseInt((data.nearestScrollingFrameMousePosition.y).toFixed(0));
                if (data.nearestScrollingFrameOffset.y > 600) {
                    y = data.nearestScrollingFrameOffset.y + data.nearestScrollingFrameMousePosition.y;
                }
            } else if (data.targetNodeMousePosition) {
                if (data.targetNodeId === data.presentedNodeId) {
                    x = parseInt(data.targetNodeMousePosition.x.toFixed(0));
                    y = parseInt(data.targetNodeMousePosition.y.toFixed(0));
                } else {
                    const targetNodeId = data.targetNodeId;
                    let finalTargetNodeId = undefined
                    if (targetNodeId.includes(';')) {
                        finalTargetNodeId = targetNodeId.split(';')[0]
                        let soloNumeros = finalTargetNodeId.replace(/[A-Za-z]/g, '')
                        finalTargetNodeId = soloNumeros
                    } else {
                        finalTargetNodeId = targetNodeId
                    }
                    const auxX = data.targetNodeMousePosition.x;
                    const auxY = data.targetNodeMousePosition.y;
                    const boundingboxs = absoluteboundingbox(finalTargetNodeId, data.presentedNodeId)
                    const { targetNodeX, targetNodeY, presentNodeX, presentNodeY } = boundingboxs
                    x = parseInt(((targetNodeX + auxX) - presentNodeX).toFixed(0));
                    y = parseInt(((targetNodeY + auxY) - presentNodeY).toFixed(0));
                }
            }
            const currentPoints = pointsByNodeId[nodeId]?.data || [];
            const index = currentPoints.findIndex((point) => point.x === x && point.y === y);
            let max = pointsByNodeId[nodeId]?.max || 0;
            if (index !== -1) {
                const point = currentPoints[index];
                point.value++;
                max = Math.max(max, point.value);
            }
            else {
                currentPoints.push({ x, y, value: 1 });
                max = Math.max(max, 1);
            }
            let maxY = 0;
            currentPoints.forEach(point => {
                if (point.y > maxY) {
                    maxY = point.y + 35;
                }
            });
            setPointsByNodeId({ ...pointsByNodeId, [nodeId]: { data: currentPoints, max } });
            setMaxValue(Math.max(maxValue, max));
            setMaxYValue(maxY);
            setNodeId(nodeId);
        }


        window.addEventListener("message", handleMessage);


        return () => {
            window.removeEventListener("message", handleMessage);
        };


    }, [APIfigma, pointsByNodeId, maxValue, maxYValue, nodeId, comprobacion, successScreen]);



    /***NUEVO CODIGO */

    useEffect(() => {

        // setAPIfigma([]) // ESTO HACE QUE SE RECARGUE EL PROTO
        // setHidden(true)
        // setLoadingApi(true)
        setFinishTask(false)

        if (!link) return

        if (tareas[cicleTest].tipoTarea === 'pruebaPrototipo') {
            // setHidden(false)
            const { caminos } = tareas[cicleTest]

            const starting = new URL(link).searchParams.get('starting-point-node-id') //TENDRIA QUE LLEGAR UNA PROPIEDAD CON EL STARTING QUE SETEE EL CLIENTE PARA CADA TAREA

            if (arrayOrden.current.length === 0) arrayOrden.current.push(starting)

            document.getElementById("iframe")?.contentWindow.postMessage(
                {
                    data: { nodeId: starting },
                    type: "NAVIGATE_TO_FRAME_AND_CLOSE_OVERLAYS",
                },
                "*"
            );

            /** MODIFICAR POR EL STARTING EN CADA TAREA */

            // client.file(link.match(URL_REGEX)[3])
            //     .then((response) => {
            //         // console.log(starting)
            //         // console.log(response)
            //         response.data.document.children.forEach((element) => {
            //             if (element.prototypeStartNodeID === starting) {
            //                 setLoadingApi(false)
            //                 // setHidden(false)
            //                 setAPIfigma(element)
            //             }
            //         })
            //         // setAPIfigma(response.data.document.children[0])
            //     });

            /********************************************* */

            if (caminos && (Object.keys(caminos).length > 0)) {

                Object.keys(caminos).forEach((caminoKey) => {
                    const camino = caminos[caminoKey]; // Obtener el arreglo de nodos para el camino actual
                    const ultimoNodo = camino[camino.length - 1]; // Obtener el último nodo del arreglo

                    // Llamar a la función setSuccessScreen con el último nodo
                    setSuccessScreen(ultimoNodo);
                });


            }

        }

    }, [link, cicleTest])


    /***** */

    useEffect(() => {
        if (recorder) {

            if (recorder.state === 'recording') {
                intervalForTimeOfNodeInScreen.current = setInterval(() => {
                    timeOfNodeInScreen.current++
                }, 1000)
            } else if (recorder.state === 'paused') {
                clearInterval(intervalForTimeOfNodeInScreen.current)
            }
        }
    }, [recorder ? recorder.state : ''])

    useEffect(() => {

        if (isMobile) {


            if (isLoaded) {
                //SI EL PROTOTIPO YA SE CARGO
                if (comprobacion) {
                    objectForRenderMobile.current.init = seconds.current
                    objectForRenderMobile.current.nodeId = nodeIdForRender
                }
            } else {

                //SI EL PROTOTIPO NO SE CARGO

                if (comprobacion) {

                    objectForRenderMobile.current.init = seconds.current
                    objectForRenderMobile.current.nodeId = nodeIdForRender

                }
            }


            if (finishTask) {
                nodesAndTimeOfFigma.current.push(objectForRenderMobile.current)
                objectForRenderMobile.current = {}
            }

        }


    }, [isLoaded, nodeIdForRender, comprobacion, finishTask])



    // useEffect(() => {

    //     const { tareas } = test
    //     const { caminos } = tareas[cicleTest]

    //     // caminos && caminos.forEach((camino) => {

    //     //     setSuccessScreen(camino.nodos[camino.nodos.length - 1])

    //     // })

    //     if (caminos) {
    //         caminos.forEach((camino) => {
    //             setSuccessScreen(camino.nodos[camino.nodos.length - 1])
    //         })
    //     } else {
    //         setSuccessScreen(undefined)
    //     }

    // }, [cicleTest])

    useEffect(() => {

        const { tareas } = test

        if (comprobacion) {
            (tareas[cicleTest].tipoTarea === 'pruebaFigma' || tareas[cicleTest].tipoTarea === 'pruebaPrototipo') && setHidden(false)
        }

        if (comprobacion) {
            if (tareas[cicleTest].tipoTarea === 'pruebaFigma' || tareas[cicleTest].tipoTarea === 'pruebaPrototipo') {
                updateHeight()
            }
        }

    }, [comprobacion, cicleTest])

    useEffect(() => {

        if (link && link !== '') {

            const starting = new URL(link).searchParams.get('starting-point-node-id')

            client.file(link?.match(URL_REGEX)[3])
                .then((response) => {
                    let match = false
                    response.data.document.children.forEach((element) => {

                        if (element.prototypeStartNodeID === starting || element.id === starting) {
                            setLoadingApi(false)
                            // setHidden(false)
                            setAPIfigma(element)
                            match = true
                        }
                    })

                    if (!match) {
                        function findAndProcessMatchingChild(starting, children, el) {

                            children.forEach((child) => {
                                if (child.id === starting || child.prototypeStartNodeID === starting) {
                                    match = true;
                                    setLoadingApi(false)
                                    setAPIfigma(el)
                                }
                            });

                            if (!match) {
                                // Si no se encuentra coincidencia, continuar la búsqueda en los children de cada child
                                children.forEach((child) => {
                                    if (child.children) {
                                        findAndProcessMatchingChild(starting, child.children, el);
                                    }
                                });
                            }
                        }

                        // Llamar a la función en tu componente
                        response.data.document.children.forEach((el) => {
                            findAndProcessMatchingChild(starting, el.children, el);
                        });

                    }
                    // setAPIfigma(response.data.document.children[0])
                });
        }
    }, [link])




    return (
        <>
            {APIfigma.length === 0 ?
                <Center
                    as='div'
                    className='loader-iframe'
                    backgroundColor={'#F4EEF3'}
                    h={'100vh'}
                    w={'100%'}
                    display={hidden ? 'none' : 'flex'}>
                    <Loader color={'#7A628C'} />
                </Center>
                :
                <Box
                    as='article'
                    flexGrow={hidden ? 0 : 1}
                    visibility={hidden ? 'hidden' : 'visible'}
                    height={heightBarraDeTareas === 0 || heightBarraDeTareas === undefined ? '100vh' : 'auto'}
                    position={heightBarraDeTareas === 0 || heightBarraDeTareas === undefined ? 'absolute' : 'relative'}
                    className='article-iframe'
                    // marginTop={heightBarraDeTareas === 0 || heightBarraDeTareas === undefined ? '0px' : (isMobile ? '65px' : '76px')}
                    marginTop={heightBarraDeTareas === 0 || heightBarraDeTareas === undefined ? '0px' : '76px'}

                >
                    {

                        link &&

                        <iframe
                            id="iframe"
                            title='iframe-nular'
                            src={link}
                            allowFullScreen
                            width="100%"
                            height={'100%'}
                            className='iframe-test'
                            style={{
                                pointerEvents: disabledTarea || finishTask ? 'none' : 'auto',
                                opacity: disabledTarea || finishTask ? '0.3' : '1',
                                position: 'absolute',
                                top: hidden ? '-100000px' : '0px',
                            }}
                        />

                    }


                    {/* BOX FOR SUCCESSSCREEN */}
                    {finishTask &&
                        <Box
                            background={'#F4EEF3F2'}
                            position={'absolute'}
                            display={'flex'}
                            flexDirection={'column'}
                            justifyContent={'center'}
                            alignContent={'center'}
                            id="finalizastelatarea"
                            h={'100%'}
                            w={'100%'}
                            gap={'5px'}
                        >
                            <Text
                                textAlign={'center'}
                                fontSize={{
                                    sm: '1rem',
                                    md: '20px',
                                    lg: '1.25rem',
                                    xl: '1.25rem',
                                    base: '1rem',
                                }}
                                lineHeight={'2.125rem'}
                                fontWeight={'600'}
                                color={'#6C5280'}
                            >
                                ¡Haz finalizado esta tarea!
                            </Text>

                            <Text
                                color={'#F28853'}
                                textAlign={'center'}
                                fontSize={{
                                    sm: '1rem',
                                    md: '20px',
                                    lg: '1.25rem',
                                    xl: '1.25rem',
                                    base: '1rem',
                                }}
                                fontWeight={'600'}
                                lineHeight={'2.125rem'}
                            >
                                Puedes continuar con la prueba.
                            </Text>
                        </Box>
                    }
                    {/* //////////////////// */}

                    {
                        loadingApi &&
                        <Box background={'rgba(89, 77, 123, 0.9)'} position={'absolute'} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignContent={'center'} id="finalizastelatarea" h={'100%'} w={'100%'} gap={'5px'}>

                            <Loader />
                        </Box>
                    }


                </Box>
            }
        </>
    )
}

export default IframeComponent
