import {
    Box,
    Flex,
    IconButton,
    useColorModeValue,
    Image,
    Text,
    Center,
    Link
} from '@chakra-ui/react';
import { AiOutlinePoweroff } from 'react-icons/ai'
import logo from '../img/LOGO-simulacr-blanco.png';
import { useAuth } from '../context/authContext';
import LogoNularBlanco from '../icons/svg/LogoNularBlanco';

const NavBar = () => {



    return (
        <Box>
            <Flex
                //bg={'#7A628C'}
                bg={'#292643'}
                color={useColorModeValue('gray.600', 'white')}
                minH={'10vh'}
                py={{ base: 2 }}
                px={{ base: 4 }}
                borderBottom={0.5}
                borderStyle={'solid'}
                borderColor={'#c1a8bea6'}
                padding={'25px'}
                align={'center'}
                display={[{ sm: 'block' }, { base: 'flex' }]}
                alignItems={'center'}
                justifyContent={'space-between'}>

                <Center paddingX={{ base: '0px', sm: '60px' }} w={'100%'} justifyContent={'center'} alignItems={'center'} flexDirection={{ base: 'column', md: 'row' }}>


                    <Box maxWidth={'150px'}>
                            <Link href={'/'} >
                            <Image src={logo} alt={'logo'} />
                            {/* <LogoNularBlanco /> */}
                            </Link>
                    </Box>



                </Center>
            </Flex>
        </Box>
    );
}

export default NavBar;