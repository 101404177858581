import React from 'react'

const AlertaCruz = ({ screen }) => {
    return (
        <>
            {
                !screen ?
                    <svg width="104" height="104" viewBox="0 0 104 104" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M52.0003 95.3327C75.9327 95.3327 95.3336 75.9317 95.3336 51.9993C95.3336 28.067 75.9327 8.66602 52.0003 8.66602C28.068 8.66602 8.66699 28.067 8.66699 51.9993C8.66699 75.9317 28.068 95.3327 52.0003 95.3327Z" stroke="#ED9B72" strokeWidth="8.66667" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M65.6394 39.2129L39.2131 65.6391" stroke="#ED9B72" strokeWidth="8.66951" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M39.2131 39.2129L65.6394 65.6391" stroke="#ED9B72" strokeWidth="8.66951" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    :
                    <svg width="95" height="95" viewBox="0 0 95 95" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M47.5003 87.0846C69.3615 87.0846 87.0836 69.3625 87.0836 47.5013C87.0836 25.64 69.3615 7.91797 47.5003 7.91797C25.639 7.91797 7.91699 25.64 7.91699 47.5013C7.91699 69.3625 25.639 87.0846 47.5003 87.0846Z" stroke="#F28853" strokeWidth="10.1667" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M59.9587 35.8203L35.8193 59.9596" stroke="#F28853" strokeWidth="10.17" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M35.8193 35.8203L59.9587 59.9596" stroke="#F28853" strokeWidth="10.17" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
            }
        </>
    )
}

export default AlertaCruz


/*

<svg width="95" height="95" viewBox="0 0 95 95" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M47.5003 87.0846C69.3615 87.0846 87.0836 69.3625 87.0836 47.5013C87.0836 25.64 69.3615 7.91797 47.5003 7.91797C25.639 7.91797 7.91699 25.64 7.91699 47.5013C7.91699 69.3625 25.639 87.0846 47.5003 87.0846Z" stroke="#F28853" stroke-width="10.1667" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M59.9587 35.8203L35.8193 59.9596" stroke="#F28853" stroke-width="10.17" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M35.8193 35.8203L59.9587 59.9596" stroke="#F28853" stroke-width="10.17" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

*/