import { Navigate } from "react-router-dom";
import { getOperatingSystem } from "../functions/getOperatingSystem";
//import Loader from "./Loader/Loader";
import { useTestDbContext } from "../context/testsDbContext";

export function ProtectedTest({ children }) {

    const { audioR, video} = useTestDbContext();
    if(audioR === null || video === null){
        const mobile = getOperatingSystem();
        // const mobile = true;
        if(!mobile){
            return <Navigate to="/" />
        }else{
            if(audioR === null){
                return <Navigate to="/" />
            }
        }
    }

    return <> {children} </>
}