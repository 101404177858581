import { Text } from '@chakra-ui/react'
import React from 'react'

const TextComponent = ({ tareas, cicleTest, disabledTarea, showTasks }) => {

    return (
        <Text
            maxWidth={{ sm: 'auto', md: 'auto', lg: '620px', xl: '620px' }}
            width={'100%'}
            fontSize={'1rem'}
            fontWeight={'500'}
            lineHeight={'1.5rem'}
            color={'#6C5280'}
            whiteSpace={'break-spaces'}
            display={showTasks ? 'block' : (tareas[cicleTest].tipoTarea !== 'pruebaPrototipo' ? 'block' : 'none')}
            transition={'all 0.3s ease'}
            disabled={disabledTarea}
            _disabled={{ opacity: '0.5', cursor: 'not-allowed', pointerEvents: 'none' }}
        >
            {tareas[cicleTest].text}
            {tareas[cicleTest].tipoTarea === 'respuestaVerbal' && '\nAntes de continuar, responde en voz alta esta pregunta.'}
            {tareas[cicleTest].tipoTarea === 'pruebaPrototipo' && '\nCuenta en voz alta lo que haces, ves y piensas mientras realizas esta tarea.'}
        </Text>
    )

}

export default TextComponent