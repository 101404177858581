import React from 'react'
import AlertaIcon from '../icons/svg/AlertaIcon'
import { Flex, Text } from '@chakra-ui/react'

const ErrorCompartirPantalla = () => {
    return (
        <Flex
            backgroundColor={'#F4EEF3'}
            flexDirection={'column'}
            minH={'100vh'}
            color={'#6C5280'}
            justify={'center'}
            alignItems={'center'}
            padding={'10px'}
        >

            <AlertaIcon stroke={'#F69260'} />

            <Text
                marginTop={'20px'}
                color={'#F69260'}
                fontSize={'1.875rem'}
                fontWeight={'600'}
                lineHeight={'2.5rem'}
                letterSpacing={'-0.0375rem'}
                textAlign={'center'}
            >
                ¡Atención!
            </Text>

            <Text
                w={'480px'}
                color={'#6C5280'}
                fontSize={'1.125rem'}
                fontWeight={'600'}
                textAlign={'center'}
                lineHeight={'1.75rem'}
                marginTop={'20px'}
            >
                Dejaste de compartir tu pantalla. <br />
                Para que podamos ver la prueba y sea considerada válida, necesitamos que vuelvas a empezar.
            </Text>
        </Flex>
    )
}

export default ErrorCompartirPantalla