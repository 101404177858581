import { Box, Flex, Text } from '@chakra-ui/react';
import React, { useEffect, useRef } from 'react'

const MultipleChoice = ({
    tarea,
    disabledTarea,
    typeTest,
    respuestaTareas,
    setRespuestaTareas,
    cicleTest,
    // setStateTextarea,
    // setResponseMultipleChoice,
    // responseMultipleChoice,
    setDisabledButton,
    disabledButton
}) => {

    const responseMultipleChoice = useRef([])

    const { opciones, eleccionMultiple, text } = tarea

    const handleTaskRespuestaMultipleChoice = (task, value, respuesta) => {

        const existe = respuesta.includes(value);

        if (existe) {
            respuesta = respuesta.filter(e => e !== value);
        } else {
            respuesta.push(value);
        }


        // setResponseMultipleChoice(respuesta);
        responseMultipleChoice.current = respuesta;
        setRespuestaTareas(respuestaTareas, respuestaTareas[cicleTest] = respuesta);
        setDisabledButton(respuesta.length === 0);

    }

    useEffect(() => {
        document.querySelectorAll('input').forEach(e => e.checked = false)
        responseMultipleChoice.current = []
        setDisabledButton(true)
    }, [cicleTest])

    const handleChange = (e) => {
        if (eleccionMultiple) {
            switch (e.target.tagName) {
                case 'INPUT':
                    handleTaskRespuestaMultipleChoice(tarea, e.target.value, responseMultipleChoice.current)
                    if (e.target.checked) {
                        e.target.parentElement.classList.add('selected')
                    } else {
                        e.target.parentElement.classList.remove('selected')
                    }

                    break;
                case 'LABEL':
                    break;
                case 'DIV':
                    handleTaskRespuestaMultipleChoice(tarea, e.target.children[0].value, responseMultipleChoice.current)
                    if (e.target.children[0].checked) {
                        e.target.children[0].checked = false;
                        e.target.classList.remove('selected')

                    } else {
                        e.target.children[0].checked = true;
                        e.target.classList.add('selected')
                    }

                    break;
                default:
                    break;
            }
        } else {
            switch (e.target.tagName) {
                case 'INPUT':
                    document.querySelector('.selected') && document.querySelector('.selected').classList.remove('selected')
                    e.target.parentElement.classList.add('selected')
                    setRespuestaTareas(respuestaTareas, respuestaTareas[cicleTest] = e.target.value)
                    // setStateTextarea(e.target.value)
                    disabledButton && setDisabledButton(false)
                    break;
                case 'LABEL':
                    break;
                case 'DIV':
                    document.querySelector('.selected') && document.querySelector('.selected').classList.remove('selected')
                    e.target.classList.add('selected')
                    e.target.children[0].checked = true;
                    setRespuestaTareas(respuestaTareas, respuestaTareas[cicleTest] = e.target.children[0].value)
                    // setStateTextarea(e.target.children[0].value)
                    disabledButton && setDisabledButton(false)
                    break;
                default:
                    break;
            }
        }




    }

    return (
        <>
            {eleccionMultiple ?
                <Flex
                    flexDirection={'column'}
                >
                    <Text
                        w={{ sm: 'auto', md: 'auto', lg: '600px', xl: '600px' }}
                        fontWeight={'500'}
                        fontSize={'1rem'}
                        color={'#6C5280'}
                        textAlign={typeTest === 'prototipo-de-figma' ? 'left' : 'center'}
                        style={{ whiteSpace: "break-spaces" }}
                        disabled={disabledTarea}
                        _disabled={{ opacity: '0.5' }}
                    >
                        {text}
                    </Text>
                    <Text
                        fontWeight={'500'}
                        fontSize={'1rem'}
                        color={'#6C5280'}
                        disabled={disabledTarea}
                        _disabled={{ opacity: '0.5' }}
                    >
                        Puedes seleccionar más de una opción.
                    </Text>
                    <Box
                        display={'flex'}
                        width={'100%'}
                        maxW={{ sm: 'none', md: '450px', lg: '516px', xl: '516px', base: 'none' }}
                        flexDirection={{ sm: 'column', md: 'row', lg: 'row', xl: 'row', base: 'column' }}
                        alignItems={'center'}
                        color={'#6C5280'}
                        defaultValue={respuestaTareas[cicleTest]}
                        disabled={disabledTarea}
                        _disabled={{ opacity: '0.5' }}
                    >
                        <Box
                            id="multiple-choice"
                            w={'100%'}
                        >
                            {Object.keys(opciones).map((opcion, index) => (

                                <div key={index} onClick={(e) => handleChange(e)} >
                                    <input name="multipleChoice" type="checkbox" id={index} value={opciones[opcion]} />
                                    <label htmlFor={index}>{opciones[opcion]}</label>
                                </div>))}
                        </Box>
                    </Box>
                </Flex>
                :
                <Flex
                    flexDirection={'column'}
                >
                    <Text
                        w={{ sm: 'auto', md: 'auto', lg: '450px', xl: '450px' }}
                        fontSize={'1rem'}
                        color={'#6C5280'}
                        fontWeight={'500'}
                        textAlign={typeTest === 'prototipo-de-figma' ? 'left' : 'center'}
                        style={{ whiteSpace: "break-spaces" }}
                        disabled={disabledTarea}
                        _disabled={{ opacity: '0.5' }}
                    >
                        {text}
                    </Text>
                    <Box
                        disabled={disabledTarea}
                        _disabled={{ opacity: '0.5' }}
                        id="single-choice"
                        color={'#6C5280'}
                        fontWeight={'500'}
                        lineHeight={'1.625rem'}
                        fontSize={'1rem'}
                    >
                        {Object.keys(opciones).map((opcion, index) => (
                            <div key={index} onClick={(e) => handleChange(e)} >
                                <input name="yesornot" type="radio" id={index} value={opciones[opcion]} />
                                <label htmlFor={index}>{opciones[opcion]}</label>
                            </div>))}
                    </Box>

                </Flex>}
        </>
    )
}

export default MultipleChoice