const PlayIcon = ({ w, h }) => {

    return (
        <svg
            width={w || 'auto'}
            height={h || 'auto'}
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M16.6946 8.81757C16.6946 13.1679 13.1679 16.6946 8.81757 16.6946C4.46721 16.6946 0.94054 13.1679 0.94054 8.81757C0.94054 4.46721 4.46721 0.94054 8.81757 0.94054C13.1679 0.94054 16.6946 4.46721 16.6946 8.81757Z" stroke="#6C5280" strokeWidth="1.88108" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7.0542 5.87891L11.7569 8.8181L7.0542 11.7573V5.87891Z" fill="#6C5280" stroke="#6C5280" strokeWidth="1.17568" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )
}

export default PlayIcon
