import { Center, Flex } from '@chakra-ui/react'
import React from 'react'
import '../../css/loader.css'


const Loader = () => {
    return (
        <Flex flexDirection={'column'} bgGradient={'linear(to-b,#292643,#41416D)'} h={'100vh'} color={'white'} >
            <Center h={'100vh'}>
                <div className="lds-ring">
                    <div>
                    </div>
                    <div>
                    </div>
                    <div>
                    </div>
                    <div>
                    </div>
                </div>
            </Center>
        </Flex>
    )
}

export default Loader