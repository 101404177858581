function LogoNularColor({ width }) {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 1200 265.2" style={{ enableBackground: 'new 0 0 1200 265.2' }} width={width ? width : '140px'} xmlSpace="preserve">
            <style type="text/css">
                {`.st0{fill:#292643;}`}
                {`.st1{fill:#ED9B72;}`}
                {`.st2{fill:#16162E;}`}
                {`.st3{fill:#41416D;}`}
                {`.st4{fill:#7A628C;}`}
                {`.st5{fill:#0DFFB1;}`}
                {`.st6{fill:#2F2F54;}`}
                {`.st7{display:none;}`}
                {`.st8{display:inline;}`}
                {`.st9{display:inline;fill:#292643;}`}
            </style>
            <g>
                <g>
                    <path className="st0" d="M1185,192.7c-6.3-6-14-9-23.1-9c-8.9,0-16.6,3-23,9s-9.6,14-9.6,23.8c0,9.4,3.2,17.1,9.6,23.3
			c6.4,6.1,14,9.2,23,9.2c9.2,0,16.9-3.1,23.1-9.2c6.3-6.1,9.4-13.9,9.4-23.3C1194.4,206.7,1191.3,198.8,1185,192.7z"/>
                    <path className="st0" d="M461.6,74.4c-11.3-5.7-24.1-8.5-38.4-8.5c-15.4,0-29.2,3.2-41.2,9.7c-7.1,3.8-13.3,8.7-18.4,14.4V68.6h-49.9
			v180.3h52.3v-89.1c0-11.4,1.8-20.6,5.5-27.5c3.7-6.9,8.8-12.2,15.4-15.7c6.6-3.6,14-5.4,22.3-5.4c11.6,0,20.6,3.5,27,10.4
			c6.4,6.9,9.6,17.7,9.6,32.2v95.2h52.3V145.6c0-18.3-3.2-33.3-9.7-45.1C481.7,88.8,472.9,80.1,461.6,74.4z"/>
                    <path className="st0" d="M655.9,158c0,10.7-1.8,19.7-5.5,26.8c-3.7,7.2-8.7,12.5-14.9,16.1c-6.3,3.6-13.4,5.4-21.4,5.4
			c-11.6,0-20.6-3.5-27-10.6c-6.4-7-9.6-18.1-9.6-33.3V94.7h-0.1c0-14.4-11.7-26.1-26.1-26.1c-14.4,0-26.1,11.7-26.1,26.1v75.8
			c0,18.3,3.2,33.5,9.7,45.6c6.5,12.1,15.5,21,27.1,26.8c11.6,5.8,25,8.7,40.2,8.7c13.8,0,26.6-3.2,38.2-9.6
			c6.9-3.8,12.8-8.6,17.8-14.5v21.4h49.6V68.6h-51.9V158z"/>
                    <path className="st0" d="M1140.2,114.5V65.9c-17,0-31.4,3.3-43.2,9.9c-7.6,4.2-13.8,9.7-18.8,16.5V68.6h-49.9v180.3h52.3v-85.1
			c0-17.2,4.4-29.9,13.1-38.2c8.7-8.3,20.4-12.4,35.2-12.4c1.8,0,3.6,0.1,5.5,0.3C1136.2,113.7,1138.2,114,1140.2,114.5z"/>
                    <path className="st0" d="M973.9,85.3c-15.2-13-36.5-19.4-64-19.4c-14.3,0-28.4,1.8-42.2,5.5c-8.5,2.3-16.3,5.2-23.3,8.8
			c-2.2,1-4.2,2.3-6,4.1c-7.8,7.9-7.7,20.8,0.3,28.9c6.9,7,17.4,8,25.2,3c3.4-1.6,6.9-3,10.7-4.2c9.3-3,18.7-4.5,28.3-4.5
			c14.1,0,24.5,3.1,31.3,9.4c6.8,6.3,10.2,15,10.2,26.1v0.3h-41.2c-18.5,0-33.5,2.2-44.9,6.7c-11.4,4.5-19.7,10.8-25,18.9
			c-5.3,8.2-7.9,17.5-7.9,28c0,1.5,0.1,3,0.2,4.5c-3.7,3.4-9.6,7.5-18.1,7.5c-19.7,0-18.3-20-18.3-20V2.9h-52.3v189
			c0,19.9,5.7,35.2,17.1,46.1c11.4,10.8,27.3,16.3,47.6,16.3c22.1,0,37.1-7.9,47.3-14.4c2.5,1.6,5.1,3.2,8,4.5
			c10.1,4.8,22,7.2,35.9,7.2c15.9,0,28.8-3.1,38.9-9.2c6.8-4.2,12.1-9.5,16.1-16v22.5h48.9V146C996.7,118.5,989.1,98.3,973.9,85.3z
			 M944.4,191.9c-2.9,8.3-7.9,14.4-14.9,18.4c-7,4-15,6-24,6c-8.9,0-16-2-21.3-5.9c-5.3-3.9-7.9-9.2-7.9-15.9c0-6,2.3-11.1,6.9-15.1
			c4.6-4,13-6,25.3-6h35.9V191.9z"/>
                </g>
                <g>
                    <path className="st1" d="M125.7,183.8l-12.6-22.7c-1-1.6-2-3.1-3.6-4.6c-1.5-1.5-5.6-4.2-5.6-4.2l-21.6-11.9c0,0-47.4-29.3-64-21.8
			c-8.2,4.6-13,13-12.7,21.9c-0.1,3.1,0.4,6.3,1.7,9.4c1.2,3.1,3.1,5.8,5.4,8.1c1.5,1.5,3,2.5,4.6,3.6l0.8,0.5l26.4,14.4
			c2.1,1.3,15.1,9.2,29.3,23.1c4.3,4.6,8.6,9.9,12.6,16l17.5,32.1c4.5,8,12.7,12.7,21.8,12.7c0,0,0,0,0,0c9.2,0,17.5-4.9,21.8-12.7
			C157,233,125.7,183.8,125.7,183.8z"/>
                    <path className="st0" d="M233.2,118.7c-16.6-7.5-64,21.8-64,21.8l-21.6,11.9c0,0-4.1,2.7-5.6,4.2c-1.5,1.5-2.5,3-3.6,4.6l-12.6,22.7
			c0,0-31.3,49.2-21.8,64c4.3,7.8,12.6,12.7,21.8,12.7c0,0,0,0,0,0c9.2,0,17.3-4.7,21.8-12.7l17.5-32.1c4-6,8.2-11.3,12.5-15.9
			c14.8-14.6,28.3-22.7,29.6-23.4l26.1-14.3l0.8-0.5c1.6-1.1,3.1-2.1,4.6-3.6c2.4-2.3,4.2-5,5.4-8.1c1.2-3.1,1.8-6.3,1.7-9.4
			C246.1,132.2,241.3,123.3,233.2,118.7z"/>
                    <path className="st2" d="M233.2,118.7c-16.6-7.5-64,21.8-64,21.8l-14.1,7.7c1,0.6,47.7,30.2,63.2,22.2l15.2-8l0.7-0.8
			c1.6-1.1,3.1-2.1,4.6-3.6c2.4-2.3,4.2-5,5.4-8.1c1.2-3.1,1.8-6.3,1.7-9.4C245.8,132.3,241.3,123.3,233.2,118.7z"/>
                    <path className="st3" d="M245.8,140.7c0.1-3.1-0.4-6.3-1.7-9.4c-1.2-3.1-3.2-6.2-5.5-8.5c-1.5-1.5-3-2.5-4.6-3.6l-0.8-0.5l-26.1-14
			c-1.6-1-15.4-9.2-30.2-24c0,0,0,0,0,0c-4-4.4-8-9.4-11.8-15.1l-17.5-32.1c-4.5-8-12.7-12.3-21.9-12.3c-9.2,0-17.4,4.7-21.7,12.5
			c-9.5,14.8,21.7,63.8,21.7,63.8l12.6,22.7c1,1.6,2,3.1,3.6,4.6c1.5,1.5,5.6,4.2,5.6,4.2l21.6,11.9c0,0,47.4,29.3,64,21.8
			C241.3,158,246.2,149.6,245.8,140.7z"/>
                    <g>
                        <path className="st4" d="M90.2,132.6l3.5,1.9l10.2-5.6c0,0,4.1-2.7,5.6-4.2c1.5-1.5,2.5-3,3.6-4.6l12.6-22.7c0,0,31.3-49.2,21.8-64
				c-4.3-7.8-12.6-12.7-21.8-12.7c0,0,0,0,0,0c-9.2,0-17.3,4.7-21.8,12.7L86.4,65.5c-3.8,5.7-7.9,10.8-11.9,15.2
				c-15.3,15.3-29.5,23.6-30.3,24l-9.1,4.9C55.5,111.1,90.2,132.6,90.2,132.6z"/>
                    </g>
                    <path className="st5" d="M131.4,107.6" />
                    <path className="st6" d="M133.9,112.1c0.1-0.1,28.7-45.1,22.8-62l-9.2-16.8c-4.5-8-12.7-12.3-21.9-12.3c0,0,0,0,0,0
			c-9.2,0-17.1,4.6-21.4,12.4c-9.5,14.8,21.5,63.9,21.5,63.9l8.3,14.9C134,112.2,133.9,112.2,133.9,112.1z"/>
                    <path className="st4" d="M115.9,22.6c-5,2.1-9.2,5.8-12,10.8l0,0c-9.9,15.2,20.8,64.7,21.8,64c0.1-0.1,31.3-49.2,21.8-64l0,0
			c-2.8-5-7-8.7-12-10.8c-2.9-1.2-6.1-1.9-9.3-1.9c-0.2,0-0.3,0-0.5,0l-0.2,0c-0.2,0-0.1,0-0.3,0C122,20.7,118.8,21.4,115.9,22.6z"
                    />
                </g>
            </g>
            <g className="st7">
                <g className="st8">
                    <path className="st0" d="M1184.6,192.8c-6.3-6-14-9-23.1-9c-8.9,0-16.6,3-22.9,9c-6.4,6-9.5,14-9.5,23.8c0,9.4,3.2,17.1,9.5,23.3
			c6.4,6.1,14,9.2,22.9,9.2c9.2,0,16.9-3.1,23.1-9.2c6.2-6.1,9.4-13.9,9.4-23.3C1194,206.7,1190.9,198.8,1184.6,192.8z"/>
                    <path className="st0" d="M461.6,74.5c-11.3-5.7-24.1-8.5-38.3-8.5c-15.4,0-29.1,3.2-41.2,9.7c-7.1,3.8-13.2,8.7-18.4,14.4V68.7h-49.9
			v180.2H366v-89.1c0-11.4,1.8-20.5,5.5-27.5c3.7-6.9,8.8-12.2,15.4-15.7c6.6-3.6,14-5.4,22.3-5.4c11.6,0,20.6,3.5,27,10.4
			c6.4,6.9,9.5,17.6,9.5,32.2v95.1h52.2V145.7c0-18.3-3.2-33.3-9.7-45C481.7,88.9,472.9,80.2,461.6,74.5z"/>
                    <path className="st0" d="M655.8,158.1c0,10.7-1.8,19.7-5.5,26.8c-3.7,7.1-8.7,12.5-14.9,16.1c-6.3,3.6-13.4,5.4-21.4,5.4
			c-11.6,0-20.6-3.5-27-10.5c-6.4-7-9.5-18.1-9.5-33.3V94.8h-0.1c0-14.4-11.7-26.1-26.1-26.1c-14.4,0-26.1,11.7-26.1,26.1v75.7
			c0,18.3,3.2,33.5,9.7,45.5c6.5,12.1,15.5,21,27.1,26.8c11.6,5.8,25,8.7,40.2,8.7c13.8,0,26.6-3.2,38.2-9.5
			c6.9-3.8,12.8-8.6,17.8-14.5v21.4h49.6V68.7h-51.9V158.1z"/>
                    <path className="st0" d="M1139.8,114.6V66c-17,0-31.4,3.3-43.2,9.9c-7.6,4.2-13.8,9.7-18.8,16.5V68.7H1028v180.2h52.2v-85.1
			c0-17.2,4.4-29.9,13.1-38.2c8.7-8.3,20.4-12.4,35.2-12.4c1.8,0,3.6,0.1,5.5,0.3C1135.9,113.8,1137.8,114.1,1139.8,114.6z"/>
                    <path className="st0" d="M973.6,85.4c-15.2-12.9-36.5-19.4-64-19.4c-14.3,0-28.4,1.8-42.2,5.5c-8.5,2.3-16.3,5.2-23.3,8.8
			c-2.2,1-4.2,2.3-6,4.1c-7.8,7.9-7.7,20.8,0.3,28.9c6.9,7,17.4,8,25.2,3c3.3-1.6,6.9-3,10.7-4.2c9.3-3,18.7-4.5,28.3-4.5
			c14.1,0,24.5,3.1,31.3,9.4c6.8,6.3,10.2,15,10.2,26.1v0.3h-41.2c-18.5,0-33.5,2.2-44.9,6.7c-11.4,4.5-19.7,10.8-25,18.9
			c-5.2,8.2-7.9,17.5-7.9,28c0,1.5,0.1,3,0.2,4.5c-3.7,3.4-9.6,7.5-18.1,7.5c-19.6,0-18.3-20-18.3-20V3h-52.2v188.9
			c0,19.9,5.7,35.2,17.1,46c11.4,10.8,27.2,16.2,47.6,16.2c22.1,0,37.1-7.9,47.3-14.4c2.5,1.6,5.1,3.2,8,4.5c10,4.8,22,7.2,35.8,7.2
			c15.9,0,28.8-3.1,38.8-9.2c6.8-4.2,12.1-9.5,16.1-16v22.5h48.9V146C996.4,118.6,988.8,98.4,973.6,85.4z M944.1,191.9
			c-2.9,8.3-7.9,14.4-14.9,18.4s-15,6-23.9,6c-8.9,0-16-2-21.3-5.9c-5.2-3.9-7.9-9.2-7.9-15.9c0-6,2.3-11.1,6.9-15.1
			c4.6-4,13-6,25.3-6h35.8V191.9z"/>
                </g>
                <path className="st9" d="M241.8,126.8c-0.9-1.4-1.9-2.8-3.1-3.9c-0.8-0.8-1.7-1.5-2.5-2.1c-0.7-0.5-1.4-1-2.2-1.5l-0.7-0.5l-17.8-9.5
		l-9.6-5.2c-3.4-2.1-12.2-7.9-22.4-17.1c-6.5-6.2-13.4-14.2-19.6-23.9l-16.1-29.6c-2.2-3.9-5.3-7.1-9.2-9.3
		c-3.8-2.2-8.1-3.4-12.5-3.4c-0.1,0-0.2,0-0.3,0c-4.4,0-8.7,1.2-12.5,3.4c-3.8,2.2-7,5.4-9.2,9.3L92.5,54.8
		c-9.6,17.8-21.9,30.5-31.8,38.7c-5.4,4.4-10,7.5-12.9,9.3c-1.1,0.7-2.1,1.3-2.8,1.7l-2.1,1.1l0,0h0l-7.4,4.1
		c20.4,1.6,54.9,22.9,54.9,22.9l3.5,1.9l-0.1,0.1l0.9-0.5l9.5-5.2c0,0,0.5-0.3,1.3-0.8c3.7-2.2,6.7-5.4,8.6-9.1l2-3.6l9.9-17.8l0,0
		l0,0l9.7,17.4l2.2,4c1.1,2.1,2.5,4.1,4.2,5.8c0,0,0,0,0.1,0.1c0.1,0.1,0.3,0.3,0.5,0.5c1.2,1.1,2.5,2.1,3.9,2.9
		c0.7,0.5,1.3,0.9,1.3,0.9l21.3,11.7l-21.3,11.7c0,0-4.1,2.7-5.6,4.2c-1.5,1.5-2.5,3-3.5,4.6l-12.5,22.6l0,0l0,0l-8.8-15.9l-0.3-0.6
		l-0.5-0.9l-2.2-3.9c-1.9-3.9-5-7.1-8.7-9.3c-0.7-0.5-1.2-0.8-1.2-0.8l-15.8-8.7l-0.1,0.1l-5.8-3.2c0,0-47.3-29.3-63.9-21.8
		c-1,0.5-1.9,1.1-2.7,1.8l-0.1-0.1c-3.5,2.6-6.2,6.1-7.8,9.9c-0.1,0.3-0.3,0.7-0.4,1c-0.1,0.3-0.2,0.6-0.4,1
		c-0.2,0.7-0.5,1.5-0.6,2.2c0,0.1,0,0.2-0.1,0.3c-0.4,1.9-0.6,3.8-0.5,5.7c-0.1,3.1,0.4,6.3,1.7,9.4c1.2,3.1,3,5.8,5.4,8.1
		c0.9,0.9,1.8,1.6,2.7,2.2c0.4,0.3,0.8,0.6,1.2,0.8c0.2,0.2,0.5,0.3,0.7,0.5l0.8,0.5l26.3,14.4c1.9,1.2,13.1,8,25.8,19.8
		c6,6.1,12.2,13.6,17.8,22.5l15.7,28.8c0.4,0.8,0.9,1.5,1.4,2.2c1.4,2,3.1,3.8,5,5.3c4.3,3.3,9.6,5.2,15.4,5.2c0,0,0,0,0,0
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c5.7,0,11.1-1.9,15.4-5.2c1.9-1.5,3.6-3.3,5-5.3c0.5-0.7,1-1.4,1.4-2.2l17.5-32.1
		c4.4-6.5,9-12.2,13.6-17.1c11.3-11,21.8-18.1,26.3-20.9l20.7-11.3l7.4-3.9c0,0,0,0,0.1,0c0,0,0.1-0.1,0.1-0.1l0.1-0.1l0,0
		c7.2-4.2,11.8-11.4,12.3-19.2c0-0.1,0-0.2,0-0.3c0-0.7,0.1-1.5,0-2.3c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0
		C246,135.6,244.5,130.8,241.8,126.8z"/>
            </g>
        </svg>


    )
}
export default LogoNularColor