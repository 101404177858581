import { Flex, VStack, Heading, Box, Button } from "@chakra-ui/react"
import { useNavigate } from "react-router-dom"
import ErrorIcon from "../icons/svg/ErrorIcon"

const ErrorPage = () => {

    const navigate = useNavigate();

    const refreshPage = () => {
        navigate(0);
    }

    return (

        <Flex
            flexDirection={'column'}
            backgroundImage={'linear-gradient(180deg,#131313,#292929 100%,#292929)'}
            color={'white'}
            minH={'100vh'}
            align={'center'}
            justify={'center'}
        >
            <VStack h={'100%'} textAlign={'center'}>

                <Box pb={5} pt={5}>
                    <ErrorIcon />
                </Box>
                <Box pb={5}>
                    <Heading as={'h1'} size='2xl'>El test ha sido finalizado</Heading>
                </Box>
                <Box>
                    <Heading as={'h2'} size='lg'>Este test únicamente se puede realizar desde una computadora de escritorio</Heading>
                    <Heading as={'h2'} size='lg'>Aumenta el tamaño de tu pantalla para continuar</Heading>
                </Box>
                <Button
                    size='md'
                    textAlign={'center'}
                    borderRadius={'0px'}
                    color="white"
                    backgroundColor={'#D4145A'}
                    colorScheme={'transparent'}
                    padding={3}
                    mt={5}
                    onClick={() => { refreshPage() }}>
                    <Heading
                    >Rehacer test
                    </Heading>
                </Button>

            </VStack>
        </Flex>

    )

}
export default ErrorPage