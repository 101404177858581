function ErrorIcon() {
    return (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="128px"
            height="128px" viewBox="0 0 512 512" style={{ enableBackground: "new 0 0 512 512" }} xmlSpace="preserve">
            <g id="E410_x2C__Error_x2C__Media_x2C__media_player_x2C__multimedia">
                <g>
                    <g>
                        <path fill="#D4145A" d="M418.63,93.37c89.83,89.82,89.83,235.439,0,325.26c-89.82,89.83-235.44,89.83-325.26,0
				c-89.83-89.82-89.83-235.44,0-325.26C183.19,3.54,328.81,3.54,418.63,93.37z"/>
                        <path fill="#FFFFFF" d="M270.143,256l77.778-77.779c3.905-3.905,3.905-10.237,0-14.143
				c-3.906-3.905-10.236-3.905-14.143,0L256,241.857l-77.779-77.779c-3.905-3.905-10.237-3.905-14.143,0
				c-3.905,3.905-3.905,10.237,0,14.143L241.857,256l-77.778,77.778c-3.905,3.905-3.905,10.237,0,14.143
				c1.953,1.952,4.512,2.929,7.071,2.929c2.559,0,5.119-0.977,7.071-2.929L256,270.143l77.778,77.778
				c1.953,1.952,4.512,2.929,7.071,2.929s5.118-0.977,7.071-2.929c3.905-3.905,3.905-10.237,0-14.143L270.143,256z"/>
                    </g>
                </g>
            </g>
            <g id="Layer_1">
            </g>
        </svg>
    )
}
export default ErrorIcon