import { useEffect, useRef, useState } from "react";
import { useTestDbContext } from '../../context/testsDbContext'
import { useTestContext } from '../../context/testContext'
import { Box, Text, Button, List, ListItem, ListIcon, Flex, UnorderedList } from '@chakra-ui/react'
import PantallaCompartida from "./PantallaCompartida";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import AlertaCruz from "../../icons/svg/AlertaCruz";
import {
    isMobile,
    deviceType,
    isFirefox,
    isChromium,
    isChrome,
    isIE,
    isOpera,
    isSafari,
    isEdge,
    deviceDetect
}
    from "react-device-detect";

function ScreenShare({ cicleComprobacion, setCicleComprobacion, typeTest }) {
    const [screen, setScreen] = useState()
    const videoRef = useRef(null);
    // const streamRef = useRef(null);
    const [error, setError] = useState(false);
    const { video, setVideo, audioR, trackPermisos } = useTestDbContext()
    const { handleCicle } = useTestContext()
    const [stateVideo, setStateVideo] = useState('')
    const [isSharing, setIsSharing] = useState(false)
    const [share, setShare] = useState(false)
    const navigate = useNavigate()


    const handleShareScreen = async () => {
        try {
            const stream = await navigator.mediaDevices.getDisplayMedia({
                audio: false,
                video: {
                    "displaySurface": "monitor",
                    "mediaSource": "window",
                    "frameRate": { "max": 29, "ideal": 29 },
                }
            });

            // setError(false)


            setScreen(stream)

        } catch (err) {
            console.log(err)
            setError(true)
        }

    }

    useEffect(() => {
        if (screen) {
            videoRef.current.srcObject = screen
            screen.getTracks()[0].onended = () => {
                screen.getTracks().forEach(el => {
                    el.stop()
                })

                video && video.getTracks().forEach(el => {
                    el.stop()
                })

                audioR && audioR.getTracks().forEach(el => {
                    el.stop()
                })

                trackPermisos && trackPermisos.getTracks().forEach(el => {
                    el.stop()
                })

                navigate('/error-compartir-pantalla')
            }



            if (isChrome || isEdge || isOpera) return configChromium({}, screen)
            if (isFirefox) return configFirefox({}, screen)
            if (isSafari) return configSafari({}, screen)
            if (isIE) return configIE({}, screen)


            if (screen.getTracks()[0].getSettings().displaySurface) {
                configUnknownBrowserWithDisplaySurface({}, screen)
            } else {
                configUnknownBrowserWithoutDisplaySurface({}, screen)
            }

        }
    }, [screen])

    const configFirefox = (config, stream) => {
        const display = stream.getTracks()[0].label


        if (display.toLowerCase().includes('monitor') || display === '') {
            setVideo(screen)
            setError(false)
            setStateVideo('monitor')
        } else {
            setError(true)
            stream.getTracks().forEach(el => {
                el.stop()
            })
        }
    }

    const configChromium = (config, stream) => {

        const display = stream.getTracks()[0].getSettings().displaySurface
        if (display.toLowerCase() === 'monitor') {
            setVideo(screen)
            setError(false)
            setStateVideo(display.toLowerCase())
        } else {
            setError(true)
            stream.getTracks().forEach(el => {
                el.stop()
            })
        }

    }

    const configSafari = (config, stream) => {
        const display = stream.getTracks()[0].getSettings().displaySurface


        //SAFARI NO TIENE PARA COMPARTIR SOLO UNA PESTAÑA

        if (display.toLowerCase() === 'monitor') {
            setVideo(screen)
            setError(false)
            setStateVideo(display.toLowerCase())
        } else {
            setError(true)
            stream.getTracks().forEach(el => {
                el.stop()
            })
        }

    }

    const configIE = (config, stream) => {
        console.log('Abri el edge corazon')
        console.log(stream)
    }

    const configUnknownBrowserWithDisplaySurface = (config, stream) => {
        const display = stream.getTracks()[0].getSettings().displaySurface

        if (display.toLowerCase().includes('monitor')) {
            setVideo(stream)
            setError(false)
            setStateVideo(display.toLowerCase())
        } else {
            setError(true)
            stream.getTracks().forEach(el => {
                el.stop()
            })
        }
    }

    const configUnknownBrowserWithoutDisplaySurface = (config, stream) => {
        const display = stream.getTracks()[0].label

        if (display.toLowerCase().includes('monitor')) {
            setVideo(stream)
            setError(false)
            setStateVideo(display.toLowerCase())
        } else {
            setError(true)
            stream.getTracks().forEach(el => {
                el.stop()
            })
        }

    }


    return (
        <>
            {error &&
                <Flex
                    flexDirection={'column'}
                    alignItems={'center'}
                    maxWidth={'430px'}
                >
                    <AlertaCruz screen={true} />
                    <Text
                        as={'h1'}
                        color={'#F69260'}
                        fontSize={'1.875rem'}
                        fontWeight={'600'}
                        lineHeight={'2.5rem'}
                        letterSpacing={'-0.0375rem'}
                        marginTop={'10px'}
                    >
                        ¡Atención!
                    </Text>
                    <Text
                        as={'h2'}
                        marginTop={'20px'}
                        textAlign={'center'}
                        fontSize={'1.125rem'}
                        fontWeight={'600'}
                        lineHeight={'1.75rem'}
                        width={'100%'}
                        color={'#6C5280'}
                    >
                        No podemos acceder a tu pantalla completa.
                    </Text>
                    <Text
                        as={'p'}
                        textAlign={'center'}
                        fontSize={'1.125rem'}
                        fontWeight={'600'}
                        lineHeight={'1.75rem'}
                        width={'100%'}
                        color={'#6C5280'}
                    >
                        Para avanzar, selecciona <span style={{ color: "#F69260" }}>Compartir pantalla completa</span> en la configuración de tu navegador y vuelve a intentarlo.
                    </Text>
                </Flex>}
            {!error &&
                <Text
                    as={'h1'}
                    textAlign={'left'}
                    fontSize={'1.125rem'}
                    lineHeight={'1.875rem'}
                    fontWeight={'600'}
                    width={'100%'}
                    maxW={'280px'}
                    color={'#6C5280'}
                >
                    Necesitamos que compartas tu pantalla completa
                </Text>
            }
            <Box
                className="box-video-compartido"
                border={'6px solid #C1A8BE'}
                borderRadius={'10px'}
                background={'#E5D7E3'}
                width={'450px'}
                my={'20px'}
                display={stateVideo === 'monitor' ? 'block' : 'none'}
            >
                <PantallaCompartida videoRef={videoRef} />
            </Box>
            {stateVideo === 'monitor' &&
                <Flex
                    flexDirection={'column'}
                    color={'#F57535'}
                    fontWeight={'600'}
                    fontSize={'1rem'}
                    width={'360px'}
                    className="box-sugerencias"
                >
                    <Text
                        as={'h2'}
                        margin={'0px'}
                        width={'100%'}
                        lineHeight={'1.625rem'}
                        textAlign={'left'}
                        mb={'20px'}
                    >
                        Por tu privacidad te sugerimos que cierres u ocultes todo lo que no quieras que quede grabado en el video:
                    </Text>
                    <UnorderedList
                        spacing={1}
                        w={'100%'}
                        margin={'0px'}
                        textAlign={'left'}
                        lineHeight={'1.5625rem'}
                        marginLeft={'20px'}
                    >
                        <ListItem>Ventanas</ListItem>
                        <ListItem>Programas</ListItem>
                        <ListItem>Notificaciones</ListItem>
                    </UnorderedList>
                </Flex>}

            {stateVideo === 'monitor' ?
                <Flex
                    width={'100%'}
                    className="box-button"
                >
                    <Button
                        width={'230px'}
                        height={'50px'}
                        marginTop={'40px'}
                        borderRadius={'30px'}
                        color={'#FFFFFF'}
                        fontSize={'1rem'}
                        fontWeight={'600'}
                        backgroundColor={'#7A628C'}
                        _hover={{ backgroundColor: '#9983AA' }}
                        _activeLink={{ backgroundColor: '#7A628C' }}
                        _focus={{ backgroundColor: '#7A628C' }}
                        type='submit'
                        onClick={typeTest === 'prototipo-de-figma' ? () => { setCicleComprobacion(cicleComprobacion + 1) } : handleCicle}
                    >
                        Continuar
                    </Button>
                </Flex>
                :
                <Flex
                    width={'100%'}
                    justifyContent={error ? 'center' : 'flex-start'}
                    className="box-button"
                >
                    <Button
                        width={'230px'}
                        height={'50px'}
                        marginTop={'40px'}
                        borderRadius={'30px'}
                        color={'#FFFFFF'}
                        fontSize={'1rem'}
                        fontWeight={'600'}
                        backgroundColor={'#7A628C'}
                        _hover={{ backgroundColor: '#9983AA' }}
                        _activeLink={{ backgroundColor: '#9983AA' }}
                        _focus={{ backgroundColor: '#9983AA' }}
                        type='submit'
                        onClick={() => { handleShareScreen() }}
                    >
                        Compartir Pantalla
                    </Button>
                </Flex>
            }
        </>
    );
}

export default ScreenShare;