import { Box, Input, Text, Textarea } from '@chakra-ui/react'
import React, { useState, useEffect } from 'react'

const RespuestaEscrita = ({
    tarea,
    respuestaTareas,
    setRespuestaTareas,
    stateRecorder,
    setDisabledButton,
    typeTest,
    disabledTarea,
    cicleTest
}) => {

    const [stateTextarea, setStateTextarea] = useState('')

    const { tipoCampo, text } = tarea

    const handleChange = (e) => {
        setRespuestaTareas(respuestaTareas, respuestaTareas[cicleTest] = e.target.value)
        setStateTextarea(e.target.value)
        if (stateRecorder === 'recording') {
            if (e.target.value !== '') {
                setDisabledButton(false)
            } else {
                setDisabledButton(true)
            }
        }
    }

    useEffect(() => {
        setStateTextarea('')
    }, [cicleTest])

    return (
        tipoCampo === 'una-linea' ?
            <Box>
                <Text
                    fontSize={'1rem'}
                    fontWeight={'500'}
                    color={'#6C5280'}
                    w={{ sm: 'auto', md: 'auto', lg: '550px', xl: '550px' }}
                    textAlign={typeTest === 'prototipo-de-figma' ? 'left' : 'center'}
                    style={{ whiteSpace: "break-spaces" }}
                    disabled={disabledTarea}
                    _disabled={{ opacity: '0.5' }}
                >
                    {text}
                </Text>
                <Input
                    width={{ sm: '100%', md: '100%', lg: '600px', xl: '600px' }}
                    maxWidth={'600px'}
                    height={'50px'}
                    color={'#5E4670'}
                    backgroundColor={'#E1D0DE'}
                    fontWeight={'600'}
                    lineHeight={'1.625rem'}
                    whiteSpace={'pre-line'}
                    marginTop={'16px'}
                    border={'0px'}
                    borderRadius={'10px'}
                    value={stateTextarea}
                    data-position={cicleTest}
                    onChange={handleChange}
                    disabled={disabledTarea}
                    _disabled={{ opacity: '0.5' }}
                    _focus={{
                        outline: 'none'
                    }}
                    _focusVisible={{
                        outline: 'none'
                    }}
                />
            </Box>

            :

            <Box>
                <Text
                    fontSize={'1rem'}
                    fontWeight={'500'}
                    color={'#6C5280'}
                    width={{ sm: 'auto', md: 'auto', lg: '550px', xl: '550px' }}
                    textAlign={typeTest === 'prototipo-de-figma' ? 'left' : 'center'}
                    style={{ whiteSpace: "break-spaces" }}
                    disabled={disabledTarea}
                    _disabled={{ opacity: '0.5' }}
                >
                    {text}
                </Text>
                <Textarea
                    width={{ sm: '100%', md: '100%', lg: '600px', xl: '600px' }}
                    maxWidth={'600px'}
                    minHeight={'150px'}
                    color={'#5E4670'}
                    borderRadius={'10px'}
                    fontWeight={'600'}
                    lineHeight={'1.625rem'}
                    whiteSpace={'pre-line'}
                    marginTop={'16px'}
                    backgroundColor={'#E1D0DE'}
                    border={'0px'}
                    value={stateTextarea}
                    onChange={handleChange}
                    data-position={cicleTest}
                    disabled={disabledTarea}
                    _disabled={{ opacity: '0.5' }}
                    _focus={{
                        outline: 'none'
                    }}
                    _focusVisible={{
                        outline: 'none'
                    }}
                />

            </Box>


    )
}

export default RespuestaEscrita