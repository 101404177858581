import { Flex, Stack, Center, Heading, Box, FormControl, FormLabel, Input, Button, VStack, Image, Container, Text, SimpleGrid } from '@chakra-ui/react'
import React, { useState } from 'react'
import NavBar from '../components/NavBar'
import { useAuth } from '../../front/context/authContext'
import { useNavigate } from 'react-router-dom'
import Loader from '../components/Loader/Loader'
import logo from '../img/LOGO-simulacr-blanco.png'
import { Link } from 'react-router-dom'

// import multiplica from './img/multiplica-byn.png'
// import azteca from './img/Banco_Azteca-byn.png'
// import kavak from './img/Kavak-byn.png'
// import ml from './img/MercadoLibre-byn.png'
// import movistar from './img/Movistar-byn.png'
// import doppler from './img/doppler-byn.png'
// import vtex from './img/Vtex-byn.png'
// import uala from './img/uala-byn.png'
// import pedidosya from './img/pedidosya-byn.png'
// import globant from './img/Globant-byn.png'
// import interseguro from './img/interseguro-byn.png'
// import santander from './img/Banco_Santander-byn.png'
// import pomelo from './img/pomelo-byn.png'

// import '../css/greydive.webflow.css'
// import '../css/style.css'
// import '../css/webflow.css'
// import '../css/normalize.css'
import Quiz from '../components/Register/Quiz'
import Swal from 'sweetalert2'


const Register = () => {

    const navigate = useNavigate()

    const [userNew, setUserNew] = useState({
        email: '',
        password: '',
    })



    const { signUp, loading } = useAuth()

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            await signUp(userNew.email, userNew.password)
            navigate(`/`)

        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error:',
                text: `Datos incorrectos`,
                showConfirmButton: false,
                showCloseButton: true,
            })
        }

    }


    return (
        <>
            {
                loading ? <Loader />
                    :
                    <Flex color={'white'} style={{ backgroundImage: 'repeating-radial-gradient(circle farthest-corner at 0% 0%, #46004e, #a71f51 59%, #c0005a)' }} direction="column" minheight="100vh">

                        <Container maxW='1100px' py={'40px'}>
                            <Link to={'/'}><Image marginX={'20px'} src={logo} w={'170px'} /></Link>

                            <Heading marginTop={'110px !important'} fontSize={{ base: "2rem", md: "3.6rem", lg: '3.6rem' }} lineHeight={{ base: "2.5rem", md: "4rem", lg: '4rem' }} color={'white'} fontWeight={"400"} marginBottom={'10px'}>Gana entre $2000<br /> y $8000 por hora<br /> dando feedback a otros UX
                            </Heading>

                            <Text marginTop={'40px'} fontSize={'1.2rem'} lineHeight={'1.5rem'} color={'white'} maxW={'670px'}>
                                Ayuda a mejorar los sitios web, aplicaciones, productos y experiencias de las marcas más grandes de Latinoamérica y España.
                                <br /><br />
                                Descubre productos que aún no se lanzaron.
                                <br /><br />
                                Todos los días hay nuevas sesiones de feedback.
                                <br /><br />
                                Gana desde $2000 hasta $8000 por sesión de feedback.
                                <br /><br />
                                ¡Es muy fácil y divertido!
                                <br /><br />
                                Puedes cobrar todas las semanas directamente en tu cuenta bancaria, o elegir una ONG a la que desees donar el dinero.
                            </Text>

                            {/* <Box display={'flex'} flexDirection={'column'} w={'100%'} alignContent={'center'} justifyContent={'center'}>
                <Heading textAlign={'center'} fontSize={'2rem'} marginBottom={'70px !important'} marginTop={'70px !important'} color={'white'} >
                  Participa de investigaciones de marcas como estas:
                </Heading>
                <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                  <img src={azteca} loading="lazy" alt="" className="azteca" />
                  <img src={kavak} loading="lazy" sizes="(max-width: 479px) 75.9609375px, (max-width: 991px) 91.15625px, 106.34375px" alt="" className="kavak" />
                  <img src={ml} loading="lazy" sizes="(max-width: 479px) 140.84375px, 144.6484375px" alt="" className="mercadolibre" />
                  <img src={movistar} loading="lazy" sizes="(max-width: 479px) 136.9296875px, 148.34375px" alt="" className="movistar" />
                  <img src={doppler} loading="lazy" sizes="(max-width: 479px) 124.578125px, 141.1875px" alt="" className="doppler" />
                </Box>

                <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                  <img src={vtex} loading="lazy" sizes="(max-width: 479px) 102.5390625px, 110.8515625px" alt="" className="vtex" />
                  <img src={uala} loading="lazy" alt="" className="uala" />
                  <img src={pedidosya} loading="lazy" sizes="(max-width: 479px) 135.609375px, 139.71875px" alt="" className="pedidosya" />
                  <img src={globant} loading="lazy" sizes="(max-width: 479px) 119.625px, 133.4296875px" alt="" className="globant" />
                  <img src={interseguro} loading="lazy" alt="" className="interseguro" />
                  <img src={santander} loading="lazy" sizes="(max-width: 479px) 141.5859375px, (max-width: 991px) 158.578125px, 175.5703125px" alt="" className="santander" />
                </Box>

                <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>

                  <img src={pomelo} loading="lazy" sizes="(max-width: 479px) 122.0625px, 130.78125px" alt="" className="pomelo" />
                  <img src={multiplica} loading="lazy" sizes="(max-width: 479px) 141.140625px, 150.546875px" alt="" className="multiplica" />
                </Box>

              </Box> */}

                            <Quiz />

                        </Container>


                    </Flex>
            }
        </>
    )
}

export default Register