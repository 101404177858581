import { createContext, useContext } from 'react';
import { useState } from 'react';
// import { db_clientes } from '../../firebase';




export const testContext = createContext();
export const useTestContext = () => {
    const context = useContext(testContext);

    if (!context) {
        throw new Error("useTestContext must be used within a TestContext");
    }
    return context;
}


export const TestProvider = ({ children }) => {

    const [i, setI] = useState(0);
    const [access, setAccess] = useState(false)
    const [stateVideo, setStateVideo] = useState('')
    const [escenario, setEscenario] = useState(`
    ¡Te seleccionamos para participar de un test!<br>
    Tus comentarios son muy valiosos y nos ayudan a mejorar.<br><br>

    Recuerda que no hay respuestas incorrectas. Este es probablemente el único lugar en donde no tienes que preocuparte por no cometer errores.<br><br>

    No nos sentiremos mal con tus comentarios. Queremos mejorar, así que necesitamos tu sinceridad.<br><br>

    Necesitamos que des permiso para usar tu micrófono.
    `)

    const handleCicle = (e) => {
        if (e){
            e.target.value === 'Siguiente' ? setI(i + 1) : setI(0)
        }else{
            setI(i + 1)
        }
        
    }



    return (
        <testContext.Provider value={{ i, access, setAccess, handleCicle, stateVideo, setStateVideo, setI, escenario }}>
            {children}
        </testContext.Provider>
    )
}
