import React, { useState, useEffect } from 'react'
import { Text, Button, Box, Flex, Textarea } from '@chakra-ui/react'
import { useAuth } from '../context/authContext'
import { useTestDbContext } from '../context/testsDbContext'
import { useTestContext } from '../context/testContext'
import { useNavigate } from 'react-router-dom'
import LogoNularVioleta from '../icons/svg/LogoNularVioleta'

const TestFinalizado = () => {

    const [feedBack, setfeedBack] = useState('')
    const [enviado, setEnviado] = useState(false)

    const { setAudioR, setVideo, cantResponses, responses } = useTestDbContext()
    const { setI, setAccess, setStateVideo } = useTestContext()
    const { logInAnonimo, logOut } = useAuth()

    const navigate = useNavigate()


    useEffect(() => {
        logInAnonimo()
        setAudioR(null)
        setVideo(null)
        cantResponses.current = 0
        responses.current = []
        setI(0)
        setAccess(false)
        setStateVideo('')
    }, [])



    const { addFedback, setLoading } = useAuth()

    const handleChangeFeedback = (e) => {
        setfeedBack(e.target.value)
    }

    const handleEnviar = async () => {
        setEnviado(await addFedback(feedBack.split('\n')))
    }

    const navigateToRegister = () => {
        setLoading(false)
        logOut()
        navigate('/registro')
    }

    return (
        <Flex
            as={'section'}
            flexDirection={'column'}
            backgroundColor={'#F4EEF3'}
            color={'#6C5280'}
            minH={'100vh'}
            align={'center'}
            justify={'flex-start'}
            padding={['0px', '0px', '30px', '30px']}
            justifyContent={'center'}
            alignItems={'center'}
            paddingX={'10px'}
        >

            <LogoNularVioleta w={'190px'} h={'80px'} />

            {/* {
                enviado ?
                    <Box
                        maxW={{ md: '100%', sm: '100%', lg: '507px', xl: '507px', base: '100%' }}
                        textAlign={'center'}
                        marginTop={'60px'}
                        translate='no'
                        as='section'
                    >

                        <Text
                            fontSize={{ sm: '1.25rem', md: '1.375rem', lg: '1.8125rem', xl: '1.8125rem', base: '1.125rem' }}
                            lineHeight={{ sm: '1.9375rem', md: '2.125rem', lg: '2.6875rem', xl: '2.6875rem', base: '1.5625rem' }}
                            fontWeight={'600'}
                            as={'h1'}
                        >
                            Prueba finalizada
                        </Text>
                        <Text
                            fontSize={{ sm: '1.25rem', md: '1.375rem', lg: '1.8125rem', xl: '1.8125rem', base: '1rem' }}
                            lineHeight={{ sm: '1.9375rem', md: '2.125rem', lg: '2.6875rem', xl: '2.6875rem', base: '1.5625rem' }}
                            fontWeight={'600'}
                            color={'#F28853'}
                            as={'h2'}
                        >
                            ¡Muchas gracias por participar!
                        </Text>
                        <Text
                            as={'p'}
                            marginTop={'30px'}
                            fontSize={{ sm: '1rem', md: '1rem', lg: '1.175rem', xl: '1.175rem', base: '1rem' }}
                            lineHeight={{ sm: '1.5625rem', md: '1.5625rem', lg: '2.125rem', xl: '2.125rem', base: '1.5625rem' }}
                            fontWeight={'500'}
                            maxW={'507px'}
                        >
                            Regístrate para ganar dinero participando de investigaciones, pruebas y encuestas pagas.
                        </Text>

                        <Button
                            w={{ sm: '160px', md: '160px', lg: '220px', xl: '220px', base: '160px' }}
                            h={{ sm: '40px', md: '40px', lg: '50px', xl: '50px', base: '40px' }}
                            fontSize={'1rem'}
                            fontWeight={'600'}
                            background={'#F28853'}
                            borderRadius={'30px'}
                            colorScheme='transparent'
                            marginTop={'30px'}
                            _hover={{ background: '#F57535' }}
                            _active={{ background: '#F57535' }}
                            _activeLink={{ background: '#F57535' }}
                            onClick={() => navigateToRegister()}
                        >
                            Registrarme
                        </Button>

                    </Box>
                    :
                    <Box
                        minW={{ sm: 'auto', md: 'auto', lg: '545px', xl: '545px', base: 'auto' }}
                        maxW={{ sm: '100%', md: '450px', lg: '545px', xl: '545px', base: '100%' }}
                        textAlign={'center'}
                        marginTop={'60px'}
                        translate='no'
                        as='section'
                    >
                        <Text
                            marginY={'20px'}
                            fontSize={{ sm: '1.125rem', md: '1.125rem', lg: '1.5rem', xl: '1.5rem', base: '1.125rem' }}
                            lineHeight={{ sm: ' 1.5625rem', md: '1.5625rem', lg: '2.1875rem', xl: '2.1875rem', base: '1.5625rem' }}
                            fontWeight={'600'}
                            as={'h1'}
                        >
                            ¡Nos interesan tus comentarios!
                        </Text>
                        <Text
                            marginY={'20px'}
                            fontSize={{ sm: '1rem', md: '1rem', lg: '1.175rem', xl: '1.175rem', base: '1rem' }}
                            lineHeight={{ sm: '1.35rem', md: '1.35rem', lg: '2.1875rem', xl: '2.1875rem', base: '1.5625rem' }}
                            fontWeight={'500'}
                            as={'h2'}
                        >
                            Si tuvieses una varita mágica, <br /> ¿le cambiarías algo a la prueba que realizaste?
                        </Text>
                        <Textarea
                            color={'#5E4670'}
                            backgroundColor={'#E1D0DE'}
                            outline={'none'}
                            border={'none'}
                            maxW={'536px'}
                            borderRadius={'10px'}
                            minH={'150px'}
                            paddingX={'20px'}
                            paddingY={'10px'}
                            fontWeight={'600'}
                            lineHeight={'1.625rem'}
                            _focus={{
                                outline: 'none'
                            }}
                            _focusVisible={{
                                outline: 'none'
                            }}
                            onChange={handleChangeFeedback}
                        />

                        <Box
                            marginTop={{ sm: '30px', md: '30px', lg: '40px', xl: '40px', base: '30px' }}
                            display={'flex'}
                            justifyContent={'space-between'}
                        >
                            <Button
                                borderRadius={'30px'}
                                background={'#ECDFEA'}
                                marginRight={'20px'}
                                w={{ sm: '137px', md: '137px', lg: '197px', xl: '197px', base: '100%' }}
                                h={{ sm: '40px', md: '40px', lg: '50px', xl: '50px', base: '40px' }}
                                fontSize={'1rem'}
                                lineHeight={{ sm: '1.7635rem', md: '1.7635rem', lg: '1.8rem', xl: '1.8rem', base: '1.8rem' }}
                                fontWeight={'600'}
                                _hover={{ background: '#E2CFDF' }}
                                _active={{ background: '#E2CFDF' }}
                                _activeLink={{ background: '#E2CFDF' }}
                                onClick={() => setEnviado(true)}
                            >
                                No
                            </Button>

                            <Button
                                w={{ sm: '130px', md: '130px', lg: '188px', xl: '188px', base: '100%' }}
                                h={{ sm: '40px', md: '40px', lg: '50px', xl: '50px', base: '40px' }}
                                background={'#F28853'}
                                color={'#FFFFFF'}
                                borderRadius={'30px'}
                                fontSize={'1rem'}
                                fontWeight={'600'}
                                _hover={{ background: '#F57535' }}
                                _active={{ background: '#F57535' }}
                                _activeLink={{ background: '#F57535' }}
                                onClick={() => handleEnviar()}
                            >
                                Enviar
                            </Button>
                        </Box>
                    </Box>

            } */}

<Box
                        maxW={{ md: '100%', sm: '100%', lg: '507px', xl: '507px', base: '100%' }}
                        textAlign={'center'}
                        marginTop={'60px'}
                        translate='no'
                        as='section'
                    >

                        <Text
                            fontSize={{ sm: '1.25rem', md: '1.375rem', lg: '1.8125rem', xl: '1.8125rem', base: '1.125rem' }}
                            lineHeight={{ sm: '1.9375rem', md: '2.125rem', lg: '2.6875rem', xl: '2.6875rem', base: '1.5625rem' }}
                            fontWeight={'600'}
                            as={'h1'}
                        >
                            Prueba finalizada
                        </Text>
                        <Text
                            fontSize={{ sm: '1.25rem', md: '1.375rem', lg: '1.8125rem', xl: '1.8125rem', base: '1rem' }}
                            lineHeight={{ sm: '1.9375rem', md: '2.125rem', lg: '2.6875rem', xl: '2.6875rem', base: '1.5625rem' }}
                            fontWeight={'600'}
                            color={'#F28853'}
                            as={'h2'}
                        >
                            ¡Muchas gracias por participar!
                        </Text>
                        <Text
                            as={'p'}
                            marginTop={'30px'}
                            fontSize={{ sm: '1rem', md: '1rem', lg: '1.175rem', xl: '1.175rem', base: '1rem' }}
                            lineHeight={{ sm: '1.5625rem', md: '1.5625rem', lg: '2.125rem', xl: '2.125rem', base: '1.5625rem' }}
                            fontWeight={'500'}
                            maxW={'507px'}
                        >
                            Regístrate para ganar dinero participando de investigaciones, pruebas y encuestas pagas.
                        </Text>

                        <Button
                            w={{ sm: '160px', md: '160px', lg: '220px', xl: '220px', base: '160px' }}
                            h={{ sm: '40px', md: '40px', lg: '50px', xl: '50px', base: '40px' }}
                            fontSize={'1rem'}
                            fontWeight={'600'}
                            background={'#F28853'}
                            borderRadius={'30px'}
                            colorScheme='transparent'
                            marginTop={'30px'}
                            _hover={{ background: '#F57535' }}
                            _active={{ background: '#F57535' }}
                            _activeLink={{ background: '#F57535' }}
                            onClick={() => navigateToRegister()}
                        >
                            Registrarme
                        </Button>

                    </Box>


        </Flex>

    )
}

export default TestFinalizado