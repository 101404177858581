import React, { useEffect, useState } from 'react'
import AlertaCruz from '../../icons/svg/AlertaCruz'
import { Box, Flex, Text } from '@chakra-ui/react'
import { isIOS, isMobile, isSafari } from 'react-device-detect'

const DeviceError = ({ deviceUser, deviceExpected }) => {

    const [device, setDevice] = useState('')
    const [stringDeviceExpected, setStringDeviceExpected] = useState('')

    useEffect(() => {
        let texto = ''
        Object.keys(deviceExpected).forEach((key) => {
            if (deviceExpected[key] === true) {

                switch (key) {
                    case 'browser':
                    case 'desktop':
                        if (texto.length > 0) {
                            texto = texto + ' o una computadora de escritorio o laptop'
                        } else {
                            texto = 'una computadora de escritorio o laptop'
                        }
                        break;
                    case 'tablet':
                        if (texto.length > 0) {
                            texto = texto + ' o una tablet'
                        } else {
                            texto = 'una tablet'
                        }
                        break;
                    case 'mobile':
                        if (texto.length > 0) {
                            texto = texto + ' o un teléfono móvil'
                        } else {
                            texto = 'un teléfono móvil'
                        }
                        break;
                    default:
                        break;
                }
            }

        })

        setStringDeviceExpected(texto)

        switch (deviceUser) {

            case 'browser':
            case 'desktop':
                setDevice('computadora de escritorio o laptop')
                break;
            case 'tablet':
                setDevice('tablet')
                break;
            case 'mobile':
                setDevice('teléfono móvil')
                break;
            default:
                setDevice('dispositivo desconocido')
                break;
        }
    }, [deviceUser])



    return (
        <Flex
            backgroundColor={'#F4EEF3'}
            flexDirection={'column'}
            height={isMobile ? (isIOS ? '-webkit-fill-available' : `calc(var(--vh, 1vh) * 100)`) : (isSafari ? '-webkit-fill-available' : '100%')}
            justify={'center'}
            align={'center'}
            w={'100%'}
            translate='no'
            padding={'20px'}
        >
            <Flex
                width={'100%'}
                flexDirection={'column'}
                overflowY={'auto'}
                alignItems={'center'}
                sx={{
                    '::-webkit-scrollbar': {
                        width: '0px',
                    },
                    '::-webkit-scrollbar-track': {
                        background: 'transparent',
                    },
                    '::-webkit-scrollbar-thumb': {
                        background: 'transparent',
                        borderRadius: '3px',
                    },
                    '::-webkit-scrollbar-thumb:hover': {
                        background: 'transparent',
                    },
                }}
            >
                <AlertaCruz />
                <Text
                    color={'#F69260'}
                    fontSize={'2.125rem'}
                    fontWeight={'600'}
                    lineHeight={'2.5rem'}
                    letterSpacing={'-0.0375rem'}
                    marginTop={'10px'}
                >
                    ¡Atención!
                </Text>

                <Text
                    color={'#6C5280'}
                    fontSize={{ sm: '18px', md: '20px', lg: '20px', xl: '20px' }}
                    fontWeight={'600'}
                    textAlign={'center'}
                    w={'100%'}
                    maxWidth={'646px'}
                    lineHeight={'1.75rem'}
                    marginY={'20px'}
                >
                    Esta prueba no debe realizarse en<br />{device}
                </Text>
                <Flex
                    backgroundColor={'#E1D0DE'}
                    borderRadius={'20px'}
                    padding={'20px'}
                    width={'100%'}
                    maxWidth={'550px'}
                    justifyContent={'center'}
                    alignItems={'center'}
                >
                    <Text
                        color={'#6C5280'}
                        fontSize={{ sm: '18px', md: '20px', lg: '20px', xl: '20px' }}
                        fontWeight={'500'}
                        textAlign={'center'}
                        width={'100%'}
                        maxW={{
                            sm: '300px',
                            md: '300px',
                            lg: '646px',
                            xl: '646px'
                        }}
                        lineHeight={'1.5rem'}>
                        Ingresa desde {stringDeviceExpected}.
                    </Text>
                </Flex>
            </Flex>
        </Flex>
    )
}

export default DeviceError