import { createContext, useContext, useRef } from 'react';
import { useState } from 'react';
import { useAuth } from './authContext';
import { collection, doc, getDocs, getDoc, updateDoc, setDoc, deleteDoc, writeBatch } from "firebase/firestore";
import generateRandomCode from '../functions/randomCode';
import Swal from 'sweetalert2';
// import { db_clientes } from '../../firebase';
import { db_participantes } from '../../firebase';

export const testDbContext = createContext();
export const useTestDbContext = () => {
    const context = useContext(testDbContext);
    if (!context) {
        throw new Error("useTestDbContext must be used within a TestDbContext");
    }
    return context;
}

const endpointEndvideo = process.env.REACT_APP_HEROKU_ENDVIDEO
const endpointUpload = process.env.REACT_APP_HEROKU_UPLOAD
const endpointTest = process.env.REACT_APP_HEROKU_TEST


export const TestDbProvider = ({ children }) => {

    //State base de datos
    const { user, logOut } = useAuth()
    const [dbTests, setDbTests] = useState([])
    const [dbPrimerTest, setDbPrimerTest] = useState({})
    const [dbTestsPractica, setDbTestsPractica] = useState([])
    // const [arrayVideos, setArrayVideos] = useState([])
    const arrayVideos = useRef([])
    const contexto = useRef(false)
    const responses = useRef([])
    const cantResponses = useRef(0)
    const [lengthResponses, setLengthResponses] = useState(0)
    const errorFetchs = useRef([])


    //States video y audio
    const [video, setVideo] = useState(null)
    const [audioR, setAudioR] = useState(null)
    const [trackPermisos, setTrackPermisos] = useState(null)


    // States cronometro
    const [timer, setTimer] = useState(null)
    const [timerTarea, setTimerTarea] = useState(null)
    const [acumTimerTarea, setAcumTimerTarea] = useState([])
    const duration = useRef(0)


    function generateUUID() {
        var d = new Date().getTime();
        var uuid = 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c === 'x' ? r : (r & (0x3 | 0x8))).toString(16);
        });
        return uuid;
    }

    const getTest = async (id, session) => {

        const response = await fetch(`${endpointTest}/${id}/${session}`, { method: "GET" })
        const data = await response.json()
        return data
    }

    const crearTest = (obj) => {
        try {
            const q = collection(db_participantes, 'testPersonalizado')
            setDoc(doc(q, obj.id), obj)
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error:',
                text: `${error}`,
                footer: '<p style="text-align:center">Por favor, envía este mensaje de error por email a soporte@greydive.com</p>',
                showConfirmButton: false,
                showCloseButton: true,
            })
        }
    }

    const resquestData = async (blob, cliente, fragmento, idVideo, test, isMobile, format, previsualizador, idPrueba, idEquipo) => {

        if (previsualizador) {
            return null
        }

        const participante = user ? (user.displayName || `anonimo-${idVideo}`) : `anonimo-${idVideo}`
        const uuid = generateUUID()
        const fileName = `${participante}-${uuid}.${format}`

        const formData = new FormData()
        formData.append("testeador", participante);
        formData.append("idVideo", idVideo);
        formData.append("cliente", cliente);
        formData.append("fragmento", fragmento);
        formData.append("video", blob);
        formData.append("isMobile", isMobile);
        formData.append('fileName', fileName);
        formData.append('idPrueba', idPrueba);
        formData.append('idEquipo', idEquipo);

        const backupForError = {
            testeador: participante,
            idVideo,
            cliente,
            fragmento,
            video: blob,
            isMobile,
            fileName
        }

        try {
            arrayVideos.current.push(fileName)
            const response = await fetch(endpointUpload, {
                method: "POST",
                body: formData,
                contentType: "multipart/form-data",
            })
            responses.current.push(response)
            setLengthResponses(responses.current.length)
        } catch (error) {
            console.log('Se guardo una peticion en errores')
            console.log('-----------------')
            errorFetchs.current.push(backupForError)
        }

    }

    const setTaskByResponse = async (tareas, respuestaTareas) => {

        let sumaAcumulada = 0;

        for (let i = 0; i < tareas.length; i++) {
            const respuesta = respuestaTareas[i] || 'respuesta verbal'
            const tiempo = acumTimerTarea[i] || '00:00:00'
            const timeBeginTarea = i === 0 ? 0 : sumaAcumulada;
            sumaAcumulada += convertirASegundos(acumTimerTarea[i]) || 0;
            tareas[i] = { ...tareas[i], respuesta, tiempo, timeBeginTarea }
        }
    }

    const finishVideo = async (
        test,
        idVideo,
        isMobile,
        mimeType,
        respuestaTareas,
        renderForMobile,
        format,
        seconds,
        previsualizador,
        idPrueba,
        idEquipo
    ) => {


        if (previsualizador) {
            console.log('No se envia nada porque es previsualizador')
            console.log(test)
            return null
        }

        if (errorFetchs.current.length <= 0) {
            if (!test || !idVideo || !respuestaTareas) {
                throw new Error('Faltan parámetros de entrada')
            }

            const { tareas } = test
            // test.timeTest = Date.now() - duration.current
            test.timeTest = seconds
            test.renderForMobile = renderForMobile
            test.fechaRealizado = Date.now()
            test.isMobile = isMobile

            await setTaskByResponse(tareas, respuestaTareas)

            // const durationFull = Date.now() - duration.current
            const participante = user ? (user.displayName || `anonimo-${idVideo}`) : `anonimo-${idVideo}`

            // const session = JSON.parse(localStorage.getItem('session'))

            const formData = new FormData()

            // console.log(durationFull, 'durationFull')

            formData.append("duration", seconds); //duracion del video de la prueba
            formData.append("cliente", test.cliente); // id del cliente
            formData.append("idVideo", idVideo); // id del video
            formData.append("testeador", participante); //participante
            formData.append("idTest", test.uid);
            formData.append("isMobile", isMobile);
            formData.append("mimeType", mimeType);
            formData.append("test", JSON.stringify(test));
            formData.append("arrayVideos", JSON.stringify(arrayVideos.current));
            formData.append("format", format);
            formData.append('idPrueba', idPrueba);
            formData.append('idEquipo', idEquipo);

            try {
                const response = await fetch(endpointEndvideo, {
                    method: "POST",
                    body: formData,
                    contentType: "multipart/form-data",
                })

                arrayVideos.current = []
            } catch (error) {
                console.log(error)
            } finally {
                localStorage.getItem('session') && localStorage.removeItem('session')
            }

        } else {
            if (errorFetchs.current.length > 0) {
                try {
                    for (const error of errorFetchs.current) {
                        const { testeador, idVideo, cliente, fragmento, video, isMobile, fileName } = error
                        const formData = new FormData()
                        formData.append("testeador", testeador);
                        formData.append("idVideo", idVideo);
                        formData.append("cliente", cliente);
                        formData.append("fragmento", fragmento);
                        formData.append("video", video);
                        formData.append("isMobile", isMobile);
                        formData.append('fileName', fileName);
                        try {
                            const response = await fetch(endpointUpload, {
                                method: "POST",
                                body: formData,
                                contentType: "multipart/form-data",
                            })
                            responses.current.push(response)
                            setLengthResponses(responses.current.length)
                            const errors = errorFetchs.current.filter(e => e !== error)
                            // setErrorFetchs(errors)
                            errorFetchs.current = errors
                        } catch (error) {
                            console.log('Volvio a pasar un error en la peticion')
                        }
                    }
                } catch (error) {
                    console.log(error)
                } finally {
                    if (errorFetchs.current.length <= 0) {
                        console.log('Se termino de enviar las peticiones')
                        finishVideo(test, idVideo, isMobile, mimeType, respuestaTareas, renderForMobile, format, seconds)
                    }
                }
            }
        }
    }


    function convertirASegundos(valor) {
        if (valor) {
            const partes = valor.split(':');
            const horas = parseInt(partes[0]) * 3600;
            const minutos = parseInt(partes[1]) * 60;
            const segundos = parseInt(partes[2]);
            const milisegundos = parseInt(partes[3]);

            const totalSegundos = horas + minutos + segundos + (milisegundos / 1000);
            return totalSegundos;
        } else {
            return 0
        }
    }




    return (
        <testDbContext.Provider value={{
            dbTests,
            setDbTests,
            generateUUID,
            dbPrimerTest,
            crearTest,
            getTest,
            setVideo,
            setAudioR,
            video,
            audioR,
            dbTestsPractica,
            setDbTestsPractica,
            resquestData,
            finishVideo,
            timer,
            setTimer,
            timerTarea,
            setTimerTarea,
            acumTimerTarea,
            setAcumTimerTarea,
            duration,
            cantResponses,
            responses,
            lengthResponses,
            setLengthResponses,
            contexto,
            trackPermisos,
            setTrackPermisos
        }}>
            {children}
        </testDbContext.Provider>
    )
}