import { Flex, Heading, Box, HStack, Image, Container, Text, Select } from '@chakra-ui/react'
import React, { useState, useEffect, useId } from 'react'
import { useAuth } from '../../front/context/authContext'
import { useNavigate, useParams } from 'react-router-dom'
import Loader from '../icons/LoaderEclipse'
import logo from '../img/LOGO-simulacr-blanco.png'
import { Link } from 'react-router-dom'
import Quiz from '../components/Register/Quiz'
import Swal from 'sweetalert2'
import useGanancias from '../hooks/useGanancias'
import LogoNularColor from '../icons/svg/LogoNularColor'
import '../css/register.css'


const Register = () => {

  const navigate = useNavigate()
  const { pais } = useParams()
  let countrySelected;
  const { ganancias } = useGanancias()

  const [userNew, setUserNew] = useState({
    email: '',
    password: '',
  })

  const ids = useId();


  const { signUp, loading, logInAnonimo, logOut, setLoading } = useAuth()

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      await signUp(userNew.email, userNew.password)
      navigate(`/`)

    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error:',
        text: `Datos incorrectos`,
        showConfirmButton: false,
        showCloseButton: true,
      })

    }

  }


  if (localStorage) {
    countrySelected = localStorage.getItem("countrySelected")
    if (countrySelected === null) {
      localStorage.setItem("countrySelected", "argentina")
    }
  }

  const handleChange = (value) => {
    navigate(`/registro/${value}`)
    if (countrySelected === null) {
      countrySelected = value;
      localStorage.setItem("countrySelected", value)
    } else {
      countrySelected = value;
      localStorage.setItem("countrySelected", value)
    }
  }



  return (
    <>
      {
        loading ?

          <Flex
            className='REGISTERLOADER'
            backgroundColor={'#F4EEF3'}
            flexDirection={'column'}
            minH={'100vh'}
            color={'white'}
            justify={'center'}
            align={'center'}
          >
            <Loader color={'#7A628C'} />
          </Flex>
          :
          <Flex
            color={'#41416D'}
            direction="column"
            minheight="100vh"
          >
            <Container maxW='1100px' py={'40px'}>
              <Box >
                <HStack
                  display={'flex'}
                  justifyContent={'space-between'}
                >
                  <Link to={'/'} href='Link hacia la home de Nular.'><LogoNularColor /></Link>
                  <select
                    style={{
                      width: '140px',
                      height: '40px',
                      borderRadius: '10px',
                      color: '#6C5280',
                      fontWeight: '600',
                      border: '1px solid #6C5280',
                      outline: 'none',
                      cursor: 'pointer',
                      paddingLeft: '10px',
                    }}
                    className='select-register'
                    onChange={event => handleChange(event.target.value)}
                    value={localStorage.getItem("countrySelected")}
                  >
                    <option value={"argentina"} style={{ color: '#6C5280', backgroundColor: '#6c5280' }}>Argentina</option>
                    <option value={"colombia"} style={{ color: '#6C5280' }}>Colombia</option>
                    <option value={"chile"} style={{ color: '#6C5280' }}>Chile</option>
                    <option value={"peru"} style={{ color: '#6C5280' }}>Perú</option>
                    <option value={"uruguay"} style={{ color: '#6C5280' }}>Uruguay</option>
                    <option value={"españa"} style={{ color: '#6C5280' }}>España</option>
                    <option value={"mexico"} style={{ color: '#6C5280' }}>México</option>
                    <option value={"ecuador"} style={{ color: '#6C5280' }}>Ecuador</option>
                    <option value={"panama"} style={{ color: '#6C5280' }}>Panamá</option>
                    <option value={"bolivia"} style={{ color: '#6C5280' }}>Bolivia</option>
                    <option value={"guatemala"} style={{ color: '#6C5280' }}>Guatemala</option>
                  </select>
                </HStack>
              </Box>
              {ganancias?.filter((item) => (item.idPais) === pais).map(({ precio1, precio2, maxEntregado, currency }) => (
                <Box key={ids} display={'flex'} flexDirection={'column'}>
                  <Heading
                    fontWeight={'700'}
                    marginTop={'110px'}
                    fontSize={{ base: "2.5em", md: "3.3em", lg: '3.6em' }}
                    lineHeight={{ base: "2.5rem", md: "4rem", lg: '4rem' }}
                    color={'#41416D'}
                    marginBottom={'10px'}
                  >
                    <Flex>Gana entre&nbsp;{currency === "$" ? <Text>{currency}</Text> : ""}{precio1}{currency === "€" ? <Text >{currency}</Text> : ""}&nbsp;y</Flex>
                    <Flex >{currency === "$" ? <Text >{currency}</Text> : ""}{precio2}{currency === "€" ? <Text >{currency}</Text> : ""}&nbsp;por hora</Flex>
                    <Text>testeando los sitios web</Text>
                    <Text>de las marcas más</Text>
                    <Text>importantes</Text>
                  </Heading>

                  <Text
                    fontWeight={'500'}
                    marginTop={'40px'}
                    marginBottom={'20px'}
                    fontSize={'1.125rem'}
                    lineHeight={'1.8rem'}
                    color={'#41416D'}
                    maxW={'650px'}
                  >
                    Ayuda a mejorar los sitios web, aplicaciones, productos y experiencias de las marcas más grandes de Latinoamérica y España.
                    <br /><br />
                    Prueba sitios web que aún no se lanzaron.
                    <br /><br />
                    Todos los días hay nuevos tests, encuestas y entrevistas en los que puedes participar.
                    <br /><br />
                    Gana desde {currency === "$" ? '$' : ""}{precio1}{currency === "€" ? '€' : ""} hasta {currency === "$" ? "$" : ""}{precio2}{currency === "€" ? "€" : ""} por test, entrevista o encuesta.
                    Ya hemos enviado más de {currency === "$" ? "$" : ""}{maxEntregado}{currency === "€" ? "€" : ""}  en total a testeadores como tú.
                    <br /><br />
                    ¡Es muy fácil y divertido!
                    Únicamente participarás de sesiones individuales.
                    <br /><br />
                    Puedes cobrar todas las semanas directamente en tu cuenta bancaria, o elegir una ONG a la que desees donar el dinero.
                  </Text>
                </Box>
              ))}

              <Quiz />
            </Container>
          </Flex>
      }
    </>
  )
}

export default Register