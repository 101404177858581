export const checkPaths = (pathFromUser, paths) => {

    let foundDirectSuccess = false;

    Object.keys(paths).forEach((el) => {
        if(exitoDirecto(pathFromUser, paths[el])) {
            foundDirectSuccess = true;
            // return 'exito directo'; // Esto no hará que la función devuelva 'exito directo'
        }
    });

    if (foundDirectSuccess) {
        return 'exito directo';
    }else {
        return 'exito indirecto'
    }

}

const exitoDirecto = (pathFromUser, path) => {

    const coincidencia = JSON.stringify(path) === JSON.stringify(pathFromUser)

    if (coincidencia) {
        return true
    } else {
        return false
    }
}

const exitoIndirecto = (pathFromUser, path) => {

    const coincidencia = pathFromUser[0] === path[0] && pathFromUser[pathFromUser.length - 1] === path[path.length - 1]

    if (coincidencia) {
        return true
    } else {
        return false
    }
}

// export const checkPaths = (pathFromUser, paths) => {
//     console.log(paths, 'PATHS');
//     for (const prop in paths) {
//         if (paths.hasOwnProperty(prop)) {
//             const path = paths[prop];
//             if (exitoDirecto(pathFromUser, path.nodos)) {
//                 return 'exito directo';
//             } else if (exitoIndirecto(pathFromUser, path.nodos)) {
//                 return 'exito indirecto';
//             }
//         }
//     }
//     return '';
// };

// const exitoDirecto = (pathFromUser, path) => {
//     const coincidencia = JSON.stringify(path) === JSON.stringify(pathFromUser);

//     return coincidencia;
// };

// const exitoIndirecto = (pathFromUser, path) => {
//     const coincidencia =
//         pathFromUser[0] === path[0] && pathFromUser[pathFromUser.length - 1] === path[path.length - 1];

//     return coincidencia;
// };

