import { Box, Heading, Stack, Flex } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Loader from '../icons/LoaderEclipse'

const SitioEnConstruccion = () => {

    const navigate = useNavigate()

    useEffect(() => {
        setTimeout(() => {
            navigate('/registro')
        }, 1500)
    })

    return (
        <Flex
            className='sitioEnConstruccion LOADER'
            // bgGradient={
            //     {
            //         sm: 'linear-gradient(rgb(122, 98, 140), rgb(92, 81, 124))',
            //         md: 'linear-gradient(rgb(122, 98, 140), rgb(92, 81, 124))',
            //         lg: 'linear-gradient(rgb(122, 98, 140), rgb(92, 81, 124))',
            //         xl: 'linear-gradient(rgb(122, 98, 140), rgb(92, 81, 124))',
            //         base: 'linear-gradient(rgb(122, 98, 140), rgb(92, 81, 124))'
            //     }}
            backgroundColor={'#F4EEF3'}
            flexDirection={'column'} minH={'100vh'} color={'white'} justify={'center'} align={'center'}>
            <Loader color={'#7A628C'} />
        </Flex>
    )
}

export default SitioEnConstruccion