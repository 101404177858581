import React, { useEffect, useState, useRef } from 'react'
import { Button, Box, Text, Flex } from '@chakra-ui/react'
// import { BiMicrophone } from 'react-icons/bi'
import '../../css/test.css'
import '../../css/modal.css'
import { useTestDbContext } from '../../context/testsDbContext'
import { useAuth } from '../../context/authContext'
import CloseModalIcon from '../../icons/svg/CloseModalIcon'
import ContextoIcon from '../../icons/svg/ContextoIcon'
import PauseIcon from '../../icons/svg/PauseIcon'
import PlayIcon from '../../icons/svg/PlayIcon'
import { useNavigate } from 'react-router-dom'


const Recorder = ({
    recorder,
    i,
    cliente,
    id,
    idVideo,
    setDisabledButton,
    setDisabledButtonAbrirSitioWeb,
    cicleTest,
    test,
    setDisabledTarea,
    disabledTarea,
    intervalTime,
    seconds,
    typeTest,
    showTasks,
    secondsIframe,
    respuestaTareas,
    remainingTime,
    intervalForTimeout,
    isMobile,
    renderForMobile,
    mimeType,
    dataAvailableSource,
    bienvenida,
    previsualizador,
    combineMediaStream
}) => {

    const [statePlay, setStatePlay] = useState(<PauseIcon w={'22px'} h={'22px'} />)
    const [statePlayText, setStatePlayText] = useState('Pausar la prueba')
    const { resquestData, setTimer, duration, contexto, finishVideo, video, audioR, trackPermisos } = useTestDbContext()
    const { logInAnonimo, logOut } = useAuth()
    const intervalForRecorder = useRef()
    const navigate = useNavigate()
    const containerRegex = /\/([^;/]+)(?:;|$)/;
    const { idEquipo } = test

    const handleStop = () => {
        setStatePlay(<PlayIcon w={'22px'} h={'22px'} />)
        setStatePlayText('Reanudar la prueba')
        if (recorder.state === 'recording') {
            recorder.pause()
            setStatePlay(<PlayIcon w={'22px'} h={'22px'} />)
            setStatePlayText('Reanudar la prueba')
        } else {
            recorder.resume()
            setStatePlay(<PauseIcon w={'22px'} h={'22px'} />)
            setStatePlayText('Pausar la prueba')
        }
    }

    const handleEscenario = () => {
        document.getElementById('myModal').style.display = 'block';
    }

    const closeContexto = () => {
        document.getElementById('myModal').style.display = 'none';
    }

    useEffect(() => {

        if (recorder) {


            recorder.onstart = () => {
                duration.current = Date.now()
                secondsIframe.current = Date.now()

                if (i.current === 1) {
                    setTimer(new Date())
                }
                intervalForRecorder.current = setInterval(() => {
                    if (recorder.state === "recording")
                        recorder.requestData()
                }, 10000);

                // const testFromStorage = JSON.parse(localStorage.getItem('test'))

                // if (testFromStorage) {
                //     try {

                //         // updateDoc(doc(db_participantes, "testPersonalizado", testFromStorage.cliente), {
                //         //     testsRealizados: testFromStorage.testsRealizados + 1
                //         // });

                //         //TODO: FALTA CREAR UN ENDPOINT EN EL BACK PARA ACTUALIZAR ESTO
                //         console.log('falta crear un endpoint en el back para actualizar los participantes de la prueba')

                //     } catch (err) {
                //         console.log(err)
                //     }
                // }
            }

            recorder.onstop = () => {
                clearInterval(intervalTime.current)
                clearInterval(intervalForTimeout.current)
                clearInterval(intervalForRecorder.current)

                !isMobile && video.getTracks().forEach(el => {
                    el.stop()
                })
                audioR.getTracks().forEach(el => {
                    el.stop()
                })

                combineMediaStream.getTracks().forEach(el => {
                    el.stop()
                })

                trackPermisos && trackPermisos.getTracks().forEach(el => {
                    el.stop()
                })
                // recordingRunning.current = false
                // finishVideo(test, idVideo, cliente, id, isMobile, mimeType)
            }


            recorder.ondataavailable = async (e) => {

                const format = containerRegex.exec(mimeType)[1];

                if (dataAvailableSource.current === 'requestData') {

                    if (e.data.size > 0) {
                        resquestData(e.data, cliente, i.current, idVideo, test, isMobile, format, previsualizador, id, idEquipo)
                    }
                    if ((typeof i.current === 'number') && (e.data.size > 0)) {
                        i.current++
                    }
                } else {

                    await resquestData(e.data, cliente, i.current, idVideo, test, isMobile, format, previsualizador)
                    finishVideo(test, idVideo, isMobile, mimeType, respuestaTareas, renderForMobile.current, format, seconds.current, previsualizador, id, idEquipo)
                }
            }

            recorder.onpause = () => {
                recorder.requestData()
                setDisabledTarea(true)
                setDisabledButton(true)
                setDisabledButtonAbrirSitioWeb(true)

                //DURACION DEL TEST
                duration.current = Date.now() - duration.current
                typeTest === 'prototipo-de-figma' && (secondsIframe.current = Date.now() - secondsIframe.current)
                //////////////////////////////////////


                clearInterval(intervalTime.current)
                clearInterval(intervalForTimeout.current)
                clearInterval(intervalForRecorder.current)
            }

            recorder.onresume = () => {

                //INTERVAL PARA RECORDER REQUEST DATA
                intervalForRecorder.current = setInterval(() => {
                    recorder.requestData();
                }, 10000);

                //BUTTON DISABLED TAREA EN GENERAL
                setDisabledTarea(false)

                //BUTTON ABRIR SITIO WEB (TEST DE 2 PESTAÑAS)
                setDisabledButtonAbrirSitioWeb(false)

                const { tareas } = test
                const { tipoTarea } = tareas[cicleTest]

                setDisabledButton(true)



                //CONDICIONAL PARA BOTON DE SIGUIENTE PREGUNTA


                if (tipoTarea === 'respuestaVerbal' || tipoTarea === 'contexto' || tipoTarea === 'accion' || tipoTarea === 'pruebaFigma' || tipoTarea === 'pruebaPrototipo') {

                    if (remainingTime.current > 0) {
                        intervalForTimeout.current = setInterval(() => {
                            remainingTime.current -= 1000
                            if (remainingTime.current <= 0) {
                                remainingTime.current = 0
                                setDisabledButton(false)
                                clearInterval(intervalForTimeout.current)
                            }
                        }, 1000)
                    } else {
                        setDisabledButton(false)
                    }

                } else {
                    if (respuestaTareas[cicleTest]) {
                        respuestaTareas[cicleTest].length > 0 ? setDisabledButton(false) : setDisabledButton(true)
                    } else {
                        setDisabledButton(true)
                    }
                }


                duration.current = Date.now() - duration.current
                secondsIframe.current = Date.now() - secondsIframe.current

                seconds.current++
                intervalTime.current = setInterval(function () {
                    seconds.current++
                    // var minutes = Math.floor(seconds / 60);
                    let remainingSeconds = seconds.current % 60;
                    if (remainingSeconds < 10) {
                        remainingSeconds = "0" + remainingSeconds;
                    }
                }, 1000)

            }

            if (video) {
                video.getTracks()[0].onended = () => {
                    dataAvailableSource.current = 'stop'
                    video.getTracks().forEach(el => {
                        el.stop()
                    })
                    if (recorder && recorder.state === 'recording') {
                        recorder.stop();
                    }
                    navigate('/error-compartir-pantalla')
                }
            }

            if (audioR) {
                audioR.getTracks()[0].onended = () => {
                    dataAvailableSource.current = 'stop'
                    audioR.getTracks().forEach(el => {
                        el.stop()
                    })
                    if (recorder && recorder.state === 'recording') {
                        recorder.stop();
                    }
                    navigate('/error-compartir-pantalla')
                }
            }

        }

    }, [recorder, cicleTest])

    return (
        <>
            {(recorder.state === 'recording' || recorder.state === 'paused') &&

                <Flex
                    as={'aside'}
                    justifyContent={typeTest === 'prototipo-de-figma' ? 'flex-start' : 'space-around'}
                    w={typeTest === 'prototipo-de-figma' ? (showTasks ? { sm: '10%', xxsm: '10%', md: '230px', lg: '230px', xl: '230px', base: '10%' } : 'auto') : '96%'}
                    // w={typeTest === 'prototipo-de-figma' ? (showTasks ? `{ sm: '20%', xxsm: '20%', md: '100%', lg: '25%', xl: '25%', base: '20%' }` : `{ sm: '20%', xxsm: '20%', premd: '100%', md: '100%', lg: '25%', xl: '25%', base: '20%' }`) : '96%'}
                    maxWidth={typeTest === 'prototipo-de-figma' ? { sm: '305px', md: 'none', lg: 'none', xl: 'none' } : 'none'}
                    marginY={typeTest === 'prototipo-de-figma' ? '0px' : '20px'}
                    marginRight={typeTest === 'prototipo-de-figma' ? (showTasks ? { sm: '10px', xxsm: '10px', md: '0px', lg: '0px', xl: '0px', base: '10px' } : '0px') : '20px'}
                    flexDirection={showTasks ? (typeTest === 'prototipo-de-figma' ? 'column' : 'row') : 'row'}
                    alignItems={showTasks ? 'flex-start' : 'center'}
                    className={'container-recorder'}
                    zIndex={1000}
                    height={'max-content'}
                >

                    <Box
                        className='box-rec'
                        borderRadius={'30px'}
                        paddingTop={showTasks ? '0px' : '8px'}
                        paddingBottom={'8px'}
                        paddingX={'10px'}
                        margin={'0px'}
                        w={typeTest === 'prototipo-de-figma' ? 'auto' : '31%'}
                        display={'flex'}
                        justifyContent={'flex-start'}
                        alignItems={'center'}
                        height={'fit-content'}
                    >
                        <Box
                            display={'flex'}
                            margin={'0px'}
                            padding={'0px'}
                            className={recorder.state === 'recording' ? 'Rec' : 'nothing'}
                            backgroundColor={recorder.state === 'recording' ? '#FF6868' : 'transparent'}
                            borderRadius={'50%'}
                            h={typeTest === 'prototipo-de-figma' ? '22px' : '20px'}
                            w={typeTest === 'prototipo-de-figma' ? '22px' : '20px'}>
                        </Box>
                        <Text
                            id='text-rec'
                            display={{ sm: 'none', md: 'block', lg: 'block', xl: 'block', base: 'none' }}
                            fontSize={'0.875rem'}
                            fontWeight={'500'}
                            color={'#6C5280'}
                            lineHeight={'1.7635rem'}
                            marginLeft={'15px'}
                        >
                            {recorder.state === 'recording' ? 'Grabando' : 'Pausado'}
                        </Text>
                    </Box>

                    <Box
                        className='box-pause'
                        height={'fit-content'}
                        w={typeTest === 'prototipo-de-figma' ? 'auto' : '31%'}
                        borderRadius={'30px'}
                        paddingY={'8px'}
                        paddingX={'10px'}
                        margin={'0px'}
                        alignItems={'center'}
                        display={'flex'}
                        justifyContent={'left'}
                        onClick={handleStop}
                        cursor={'pointer'}
                        color={'#6C5280'}
                        _hover={{ backgroundColor: '#E1D0DE' }}
                        _activeLink={{ backgroundColor: '#E1D0DE' }}
                        _focus={{ backgroundColor: '#E1D0DE' }}
                    >

                        {statePlay}
                        <Button
                            h={'auto'}
                            margin={'0px'}
                            padding={'0px'}
                            textAlign={'center'}
                            fontSize={typeTest === 'prototipo-de-figma' ? '14px' : '16px'}
                            fontWeight={'500'}
                            marginX={'13px'}
                            color={'#6C5280'}
                            backgroundColor={'transparent'}
                            _hover={{ backgroundColor: 'transparent' }}
                            _activeLink={{ backgroundColor: 'transparent' }}
                            _focus={{ backgroundColor: 'transparent' }}
                            display={{ sm: 'none', md: 'block', lg: 'block', xl: 'block', base: 'none' }}
                        >
                            {statePlayText}
                        </Button>

                    </Box>

                    {/* <Box
                        id={'btn-escenario'}
                        borderRadius={'30px'}
                        paddingY={'8px'}
                        paddingX={'10px'}
                        margin={'0px'}
                        w={typeTest === 'prototipo-de-figma' ? 'auto' : '31%'}
                        display={showTasks ? 'flex' : 'none'}
                        justifyContent={'flex-end'}
                        _hover={{ background: 'rgb(211, 189, 209, 20%)' }}
                        onClick={handleEscenario}
                        cursor={'pointer'}
                        // transition={'0.5s'}
                        disabled={disabledTarea}
                        _disabled={{ opacity: '0.5', cursor: 'not-allowed', pointerEvents: 'none' }}
                    >

                        <ContextoIcon w={'22px'} h={'22px'} />

                        <Button
                            h={'auto'}
                            margin={'0px'}
                            padding={'0px'}
                            textAlign={'center'}
                            fontSize={typeTest === 'prototipo-de-figma' ? '14px' : '16px'}
                            fontWeight={'500'}
                            background={'transparent'}
                            colorScheme={'transparent'} marginX={'13px'} display={{ sm: 'none', md: 'block', lg: 'block', xl: 'block', base: 'none' }}

                        >
                            De qué se trata esta prueba
                        </Button>

                    </Box>

                    <div id="myModal" className="modal">
                        <div className="modal-content">
                            <div
                                // onClick={closeContexto}
                                id="btn-close-modal-x">
                                <Box
                                    width={'fit-content'}
                                    onClick={closeContexto}
                                    cursor={'pointer'}
                                >
                                    <CloseModalIcon />
                                </Box>
                            </div>
                            <div className="text-modal">
                                <Text
                                    marginTop={'20px'}
                                    textAlign={'left'}
                                    fontSize={{
                                        sm: '16px',
                                        md: '16px',
                                        lg: '1.125rem',
                                        xl: '1.125rem',
                                        base: '16px'
                                    }}
                                    lineHeight={'1.6rem'}
                                    // letterSpacing={'0.5px'}
                                    fontWeight={'400'}
                                    width={{ sm: 'auto', md: 'auto', lg: '450px', xl: '450px', base: 'auto' }}
                                >
                                    {bienvenida.split('\n').map((item, key) => {
                                        return <span key={key}>{item}<br /></span>
                                    })}
                                </Text>
                                <Button borderRadius={'20px'} onClick={closeContexto} id="btn-cerrar-escenario">Cerrar</Button>
                            </div>
                        </div>
                    </div> */}
                </Flex>
            }
        </>
    )
}

export default Recorder