import { Button, Center, Container, Flex, Heading, Stack, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import { useTestContext } from '../context/testContext'
import { useTestDbContext } from '../context/testsDbContext'
import { useAuth } from '../context/authContext'
import PruebaMic from '../components/Comprobacion/PruebaMic'
import Permisos from '../components/Comprobacion/Permisos'
import Pantalla from '../components/Comprobacion/Pantalla'
import SitioEnConstruccion from './SitioEnConstruccion'
import getSessionId from '../functions/getSessionId'
import { getDeviceType } from '../functions/getDeviceType'
import { deviceType } from 'react-device-detect'
import TestPrototypeComponent from '../components/Tests/TestPrototypeComponent'
import LimiteSuperado from '../components/Comprobacion/LimiteSuperado'
import Loader from '../icons/LoaderEclipse'
import generateRandomCode from '../functions/randomCode'

const ComprobacionTestPersonalizado = ({ previsualizador }) => {

    const { i, handleCicle, setI } = useTestContext()
    const [typeTest, setTypeTest] = useState(null)
    const [isMobile, setIsMobile] = useState()
    const [deviceExpected, setDeviceExpected] = useState()
    const [deviceUser, setDeviceUser] = useState()

    const { setVideo, setAudioR, getTest } = useTestDbContext()
    const { logInAnonimo, logOut, user } = useAuth()

    const { id } = useParams()

    //cliente no existe mas

    const [allLoaded, setAllLoaded] = useState(false)
    const [limitReached, setLimitReached] = useState(false)
    const [exists, setExists] = useState(false)
    const [isParticipantCorrectOperatingSystem, setIsParticipantCorrectOperatingSystem] = useState(false)
    const [prototipo, setPrototipo] = useState('')

    //probando estos estados
    const [prueba, setPrueba] = useState(null)
    const [bienvenida, setBienvenida] = useState('')
    const [titulo, setTitulo] = useState('')

    useEffect(() => {

        // const getTestFromDb = async () => {


        //     // const sessionFromStorage = localStorage.getItem('session')
        //     // const session = sessionFromStorage ? sessionFromStorage : await getSessionId()
        //     // !sessionFromStorage && localStorage.setItem('session', JSON.stringify(session))

        //     // const session = await handleSession(sessionFromStorage)

        //     // console.log(session)

        //     const code = generateRandomCode()
        //     const peticion = await getTest(id, code)


        //     if (peticion.error) {
        //         setAllLoaded(true)
        //         setExists(false)
        //         return null
        //     }

        //     const { prueba, participantesRestantes } = peticion
        //     const { pruebaFinalizada, configuracion, pruebasRealizadas, bienvenida, tituloBienvenida } = prueba

        //     if (participantesRestantes === 0) {
        //         setAllLoaded(true)
        //         setExists(true)
        //         setLimitReached(true)
        //         return null
        //     }

        //     const deviceType = getDeviceType()

        //     setDeviceExpected(configuracion.devices)
        //     setDeviceUser(deviceType)


        //     if (previsualizador) {
        //         setExists(true)

        //         if (pruebasRealizadas >= configuracion.maxParticipantes) {
        //             setAllLoaded(true)
        //             setExists(true)
        //             setLimitReached(true)
        //         } else {
        //             setLimitReached(false)
        //             // setTypeTest(configuracion.tipoPrueba)
        //             switch (deviceType) {
        //                 case 'desktop':
        //                     setIsMobile(false)
        //                     if (configuracion.devices.desktop) {
        //                         setIsParticipantCorrectOperatingSystem(true)
        //                         setTypeTest(configuracion.tipoPrueba)
        //                         setPrueba(prueba)
        //                         setBienvenida(bienvenida)
        //                         setTitulo(tituloBienvenida)
        //                         setAllLoaded(true)
        //                     } else {
        //                         setIsParticipantCorrectOperatingSystem(false)
        //                         setTypeTest(configuracion.tipoPrueba)
        //                         setPrueba(prueba)
        //                         setBienvenida(bienvenida)
        //                         setTitulo(tituloBienvenida)
        //                         setAllLoaded(true)
        //                     }
        //                     break;
        //                 case 'mobile':
        //                     setIsMobile(true)
        //                     if (configuracion.devices.mobile) {
        //                         setIsParticipantCorrectOperatingSystem(true)
        //                         setTypeTest(configuracion.tipoPrueba)
        //                         setPrueba(prueba)
        //                         setBienvenida(bienvenida)
        //                         setTitulo(tituloBienvenida)
        //                         setAllLoaded(true)
        //                     } else {
        //                         setIsParticipantCorrectOperatingSystem(false)
        //                         setTypeTest(configuracion.tipoPrueba)
        //                         setPrueba(prueba)
        //                         setBienvenida(bienvenida)
        //                         setTitulo(tituloBienvenida)
        //                         setAllLoaded(true)
        //                     }
        //                     break;
        //                 case 'tablet':
        //                     setIsMobile(true)
        //                     if (configuracion.devices.tablet) {
        //                         setIsParticipantCorrectOperatingSystem(true)
        //                         setTypeTest(configuracion.tipoPrueba)
        //                         setPrueba(prueba)
        //                         setBienvenida(bienvenida)
        //                         setTitulo(tituloBienvenida)
        //                         setAllLoaded(true)
        //                     } else {
        //                         setIsParticipantCorrectOperatingSystem(false)
        //                         setTypeTest(configuracion.tipoPrueba)
        //                         setPrueba(prueba)
        //                         setBienvenida(bienvenida)
        //                         setTitulo(tituloBienvenida)
        //                         setAllLoaded(true)
        //                     }
        //                     break;
        //                 default:
        //                     setIsMobile(false)
        //                     setIsParticipantCorrectOperatingSystem(false)
        //                     setTypeTest(configuracion.tipoPrueba)
        //                     setPrueba(prueba)
        //                     setBienvenida(bienvenida)
        //                     setTitulo(tituloBienvenida)
        //                     setAllLoaded(true)
        //                     break;

        //             }

        //         }

        //     } else {

        //         if (pruebaFinalizada) {
        //             setExists(true)

        //             if (pruebasRealizadas >= configuracion.maxParticipantes) {
        //                 setAllLoaded(true)
        //                 setExists(true)
        //                 setLimitReached(true)
        //             } else {
        //                 setLimitReached(false)
        //                 // setTypeTest(configuracion.tipoPrueba)
        //                 switch (deviceType) {
        //                     case 'desktop':
        //                         setIsMobile(false)
        //                         if (configuracion.devices.desktop) {
        //                             setIsParticipantCorrectOperatingSystem(true)
        //                             setTypeTest(configuracion.tipoPrueba)
        //                             setPrueba(prueba)
        //                             setBienvenida(bienvenida)
        //                             setTitulo(tituloBienvenida)
        //                             setAllLoaded(true)
        //                         } else {
        //                             setIsParticipantCorrectOperatingSystem(false)
        //                             setTypeTest(configuracion.tipoPrueba)
        //                             setPrueba(prueba)
        //                             setBienvenida(bienvenida)
        //                             setTitulo(tituloBienvenida)
        //                             setAllLoaded(true)
        //                         }
        //                         break;
        //                     case 'mobile':
        //                         setIsMobile(true)
        //                         if (configuracion.devices.mobile) {
        //                             setIsParticipantCorrectOperatingSystem(true)
        //                             setTypeTest(configuracion.tipoPrueba)
        //                             setPrueba(prueba)
        //                             setBienvenida(bienvenida)
        //                             setTitulo(tituloBienvenida)
        //                             setAllLoaded(true)
        //                         } else {
        //                             setIsParticipantCorrectOperatingSystem(false)
        //                             setTypeTest(configuracion.tipoPrueba)
        //                             setPrueba(prueba)
        //                             setBienvenida(bienvenida)
        //                             setTitulo(tituloBienvenida)
        //                             setAllLoaded(true)
        //                         }
        //                         break;
        //                     case 'tablet':
        //                         setIsMobile(true)
        //                         if (configuracion.devices.tablet) {
        //                             setIsParticipantCorrectOperatingSystem(true)
        //                             setTypeTest(configuracion.tipoPrueba)
        //                             setPrueba(prueba)
        //                             setBienvenida(bienvenida)
        //                             setTitulo(tituloBienvenida)
        //                             setAllLoaded(true)
        //                         } else {
        //                             setIsParticipantCorrectOperatingSystem(false)
        //                             setTypeTest(configuracion.tipoPrueba)
        //                             setPrueba(prueba)
        //                             setBienvenida(bienvenida)
        //                             setTitulo(tituloBienvenida)
        //                             setAllLoaded(true)
        //                         }
        //                         break;
        //                     default:
        //                         setIsMobile(false)
        //                         setIsParticipantCorrectOperatingSystem(false)
        //                         setTypeTest(configuracion.tipoPrueba)
        //                         setPrueba(prueba)
        //                         setBienvenida(bienvenida)
        //                         setTitulo(tituloBienvenida)
        //                         setAllLoaded(true)
        //                         break;

        //                 }

        //             }

        //         } else {
        //             setExists(false)
        //             setAllLoaded(true)
        //         }
        //     }

        // }
        const getTestFromDb = async () => {
            const isPrevisualizador = previsualizador || false;
            const code = generateRandomCode();
            const peticion = await getTest(id, code);
            

            if(peticion.error){
                setAllLoaded(true);
                setExists(false);
                return;
            }




            if (!isPrevisualizador && !peticion.prueba.pruebaFinalizada) {
                setAllLoaded(true);
                setExists(false);
                return;
            }


        
            const { prueba, participantesRestantes } = peticion;
            const { configuracion, pruebasRealizadas, bienvenida, tituloBienvenida } = prueba;

            if (pruebasRealizadas >= configuracion.maxParticipantes) {
                setAllLoaded(true);
                setExists(true);
                setLimitReached(true);
                return;
            }
        

            // const deviceTypes = getDeviceType();
        
            const isFinished = pruebasRealizadas >= configuracion.maxParticipantes;
        
            // const shouldShowTest = isPrevisualizador ? true : (!isFinished && configuracion.devices[deviceType]);

            const shouldShowTest = function (){
                if(isFinished) return false

                switch(deviceType){
                    case 'browser':
                    case 'desktop':
                        if(configuracion.devices.desktop) {
                            return true
                        }else{
                            return false
                        }
                    case 'tablet':
                        if(configuracion.devices[deviceType]){ return true }else{ return false }
                    case 'mobile':
                        if(configuracion.devices[deviceType]){ return true }else{ return false }
                    default:
                        return false
                }
            }


            const proto = configuracion.urlFigma || false
        
            // console.log(deviceType, 'nuevo')


            setExists(true);
            setIsMobile(deviceType === 'mobile' || deviceType === 'tablet');
            setDeviceExpected(configuracion.devices)
            setDeviceUser(deviceType)
            setIsParticipantCorrectOperatingSystem(shouldShowTest());
            setTypeTest(configuracion.tipoPrueba);
            setPrototipo(proto)
            setPrueba(prueba);
            setBienvenida(bienvenida);
            setTitulo(tituloBienvenida);
            setAllLoaded(true);
            
        
            if (participantesRestantes === 0) {
                setLimitReached(true);
            }
        };

        getTestFromDb()

    }, []) //getTest, id, user, logInAnonimo

    useEffect(() => {

        if (typeTest === 'website-iframe') {
            handleIframeTest()
        }

    }, [typeTest])


    const handleWebsiteTest = () => {
        switch (i) {

            case 0:
                return (
                    //PERMISOS
                    <Permisos />
                )

            case 1:
                //PANTALLA
                return (
                    <Pantalla />
                )

            case 2:
                //MIC
                return (<PruebaMic typeTest={typeTest} id={id} />)

            case 3:
                return (
                    <Stack margin={'auto'} h={'100%'}>
                        <Container h={'80%'} margin={'auto'}>
                            <Center alignItems={'center'} borderRadius={'30px'} minW={'500px'} justifyContent={'space-evenly'} w={'600px'} padding={'40px 90px 40px 90px'} display={'flex'} flexDirection={'column'} h={'100%'} bgGradient={'linear(to-b,#7A628C,transparent)'}>
                                <Text marginY={'20px'} fontSize={'21px'} fontWeight={'600'} lineHeight={'2rem'}>
                                    Deja dos pestañas abiertas
                                </Text>
                                <Text marginY={'20px'} fontSize={'20px'} fontWeight={'500'} lineHeight={'2rem'} textAlign={'center'}>
                                    En esta pestaña mantienes abierto simulacr.
                                    y en la otra abres el sitio web que te pediremos
                                    de visitar para el test.
                                </Text>
                                <Link to={`/t/${id}`}>
                                    <Button px={'35px'}
                                        borderRadius={'30px'}
                                        marginY={'20px'}
                                        colorScheme={'orange'}
                                        backgroundColor={'#E5773F'}
                                        w={'240px'}
                                        height={'45px'}
                                        fontSize={'20px'}
                                        value={'Siguiente'}
                                    >Comenzar el test</Button>
                                </Link>
                            </Center>
                        </Container>
                    </Stack>
                )



            default:
                return (
                    <Stack margin={'auto'}>
                        <Container>
                            <Center display={'flex'} flexDirection={'column'} h={'100%'}>
                                <Heading marginY={'20px'} fontSize={'4xl'}> Algo salió mal...</Heading>
                            </Center>
                        </Container>
                    </Stack>
                )
        }
    }

    const handleIframeTest = async () => {
        const session = localStorage.getItem('session')
        window.location.href = `https://${prueba.link}?cliente=${prueba.cliente}&test=${id}&session=${session.session}`
    }

    useEffect(() => {
        if (typeTest === 'website-iframe') {
            handleIframeTest()
        }
    }, [typeTest])


    const handleRedireccion = () => {
        const session = localStorage.getItem('session')
        window.location.href = `https://${prueba.link}?cliente=${prueba.cliente}&test=${id}&session=${session.session}`
    }



    return (

        allLoaded ? (
            exists ?
                (
                    limitReached ?
                        <LimiteSuperado condicion={'limite'} />
                        :
                        (
                            isParticipantCorrectOperatingSystem ?
                                <>
                                    {typeTest === null && <Loader />}
                                    {typeTest === 'website' && handleWebsiteTest()}
                                    {typeTest === 'website-iframe' &&
                                        <Text translate='no' marginY={'20px'} fontSize={'20px'} fontWeight={'500'} lineHeight={'2rem'} textAlign={'center'}>
                                            Si no te redirecciona automáticamente, haz click <span onClick={handleRedireccion}>aquí</span>
                                        </Text>
                                    }
                                    {/* {typeTest === 'prototipo-de-figma' && handlePrototypeTest()} */}
                                    {(typeTest === 'prototipo-de-figma' || typeTest === 'prueba-de-prototipo') && <TestPrototypeComponent 
                                    isMobile={isMobile} 
                                    typeTest={typeTest} 
                                    bienvenida={bienvenida} 
                                    titulo={titulo} 
                                    prueba={prueba} 
                                    previsualizador={previsualizador}
                                    prototipo={prototipo} 
                                    />}
                                    {typeTest === 'video-test' && <SitioEnConstruccion />}
                                    {typeTest === 'google-doc' && <SitioEnConstruccion />}
                                </>
                                :
                                <LimiteSuperado condicion={'device error'} deviceExpected={deviceExpected} deviceUser={deviceUser} />
                        )
                )
                :
                <LimiteSuperado condicion={'no existe'} />
        )
            :
            <Flex
                className='COMPROBACIONTESTPERSONALIZADO LOADER'
                backgroundColor={'#F4EEF3'}
                flexDirection={'column'} minH={'100vh'} color={'white'} justify={'center'} align={'center'}>
                <Loader color={'#7A628C'} />
            </Flex>

    )
}

export default ComprobacionTestPersonalizado