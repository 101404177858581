import { Progress, Text, Box } from '@chakra-ui/react'
import React from 'react'

const ProgressBar = ({ value }) => {


    return (
        <Box
            marginTop={{ sm: '33px', md: '35px', lg: '55px', xl: '55px', base: '33px' }}
            w={'100%'}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent={'center'}
        >
            <Progress
                w={'100%'}
                size={'lg'}
                colorScheme={'orange'}
                bg={'#DDCAD9'}
                border={'1px solid #DDCAD9'}
                borderRadius={'lg'}
                value={value} />
            <Text
                marginTop={'20px'}
                fontSize={{ sm: '1rem', md: '1rem', lg: '1.375rem', xl: '1.375rem', base: '1rem' }}
                color={'#FFB088'}
                fontWeight={'700'}
                lineHeight={'21px'}
            >
                {value}%
            </Text>
        </Box>
    )
}

export default ProgressBar