import React, { useEffect, useState } from 'react'
import { Stack, Container, Center, Text, Box, Button, Flex } from '@chakra-ui/react'
import { useTestContext } from '../../context/testContext'
import { useTestDbContext } from '../../context/testsDbContext'
import Swal from 'sweetalert2'
import { getOperatingSystem } from '../../functions/getOperatingSystem'
import AlertaCruz from '../../icons/svg/AlertaCruz'
import { isIOS, isMobile } from 'react-device-detect'


const PruebaMic = ({ typeTest, id, comprobacion, setComprobacion, cicleComprobacion, setCicleComprobacion }) => {

    const { handleCicle } = useTestContext()
    const { setAudioR, audioR } = useTestDbContext()

    const [error, setError] = useState(false)
    const [audio, setAudio] = useState(0)
    const [testAudio, setTestAudio] = useState(false)

    useEffect(() => {
        const handleMicrophone = async () => {
            try {
                const audioStream = await navigator.mediaDevices.getUserMedia({
                    audio: {
                        echoCancellation: true
                    }
                });
                setAudioR(audioStream)


                const audioContext = new AudioContext();
                const audioSource = audioContext.createMediaStreamSource(audioStream);
                const analyser = audioContext.createAnalyser();
                analyser.fftSize = 512;
                analyser.minDecibels = -127;
                analyser.maxDecibels = 0;
                analyser.smoothingTimeConstant = 0.4;
                audioSource.connect(analyser);
                const volumes = new Uint8Array(analyser.frequencyBinCount);



                setInterval(() => {
                    analyser.getByteFrequencyData(volumes);
                    let volumeSum = 0;
                    for (const volume of volumes)
                        volumeSum += volume;
                    const averageVolume = volumeSum / volumes.length;
                    setAudio(averageVolume)

                    const isMobile = getOperatingSystem()

                    if (averageVolume > 30 && isMobile) {
                        setTestAudio(true)
                    } else if (averageVolume > 40 && !isMobile) {
                        setTestAudio(true)
                    }

                }, 30);

            } catch (e) {
                console.log(e)
                setError(true)
            }
        }
        handleMicrophone()

    }, [])

    return (
        <Container
            as={'section'}
            className='container-prueba-mic'
            margin={0}
            padding={0}
            height={'100%'}
            minHeight={isMobile ? (isIOS ? '-webkit-fill-available' : `calc(var(--vh, 1vh) * 100)`) : '100vh'}
            width={'auto'}
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
        >
            <Flex
                // w={{ sm: 'auto', md: '600px', lg: '600px', xl: '600px', base: 'auto' }}
                width={'auto'}
                maxWidth={'600px'}
                justifyContent={'space-evenly'}
                flexDirection={'column'}

            >
                {
                    error ?
                        <Box
                            as='article'
                            className='container-prueba-mic-error'
                            maxWidth={'380px'}
                            display={'Flex'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            flexDirection={'column'}
                        >
                            <AlertaCruz screen={true} />
                            <Text
                                as={'h1'}
                                color={'#F69260'}
                                fontSize={'1.875rem'}
                                fontWeight={'600'}
                                lineHeight={'2.5rem'}
                                letterSpacing={'-0.0375rem'}
                                marginTop={'10px'}
                            >
                                ¡Atención!
                            </Text>
                            <Text
                                as={'h2'}
                                color={'#6C5280'}
                                fontSize={'1.125rem'}
                                fontWeight={'700'}
                                lineHeight={'1.75rem'}
                                textAlign={'center'}
                                marginTop={'20px'}
                                w={'100%'}
                            >
                                Hubo un error en tu navegador al intentar acceder al micrófono.
                            </Text>
                            <Text
                                as={'h2'}
                                color={'#6C5280'}
                                fontSize={'1.125rem'}
                                fontWeight={'600'}
                                lineHeight={'1.75rem'}
                                marginTop={'20px'}
                                textAlign={'center'}
                                w={'100%'}
                            >
                                Recarga la pestaña e inténtalo de nuevo.
                            </Text>

                        </Box>
                        :
                        <Box
                            as='article'
                            className='container-prueba-mic'
                            display={'Flex'}
                            justifyContent={'flex-start'}
                            flexDirection={'column'}
                        >
                            <Box
                                maxWidth={'330px'}
                            >
                                <Text
                                    as={'h1'}
                                    w={'100%'}
                                    fontSize={'1.125rem'}
                                    fontWeight={'600'}
                                    lineHeight={'1.75rem'}
                                    textAlign={'left'}
                                    color={'#6C5280'}
                                >
                                    Prueba tu micrófono
                                </Text>

                                <Text
                                    as={'h2'}
                                    w={'100%'}
                                    fontSize={'1.125rem'}
                                    fontWeight={'500'}
                                    lineHeight={'1.75rem'}
                                    textAlign={'left'}
                                    color={'#6C5280'}
                                >
                                    Podrás avanzar cuando se detecte <br />que tiene buen volumen.
                                </Text>
                            </Box>
                            <Box
                                className='barra-audio-mic'
                                width={{ sm: 'auto', md: 'auto', lg: '400px', xl: '400px', base: 'auto' }}
                                height={'1rem'}
                                backgroundColor={'#DDCAD9'}
                                borderRadius={'20px'}
                                overflow={'hidden'}
                                marginTop={'50px'}
                                marginBottom={'40px'}
                            >
                                <Box
                                    width={audio + '%'}
                                    height={'1rem'}
                                    backgroundColor={'#887099'}
                                >
                                </Box>
                            </Box>
                            {testAudio ?
                                <Box
                                    maxWidth={'338px'}
                                    width={'100%'}
                                    marginBottom={'40px'}
                                >
                                    <Text
                                        as={'p'}
                                        fontSize={'1rem'}
                                        fontWeight={'600'}
                                        textAlign={'left'}
                                        lineHeight={'1.75rem'}
                                        color={'#F57535'}
                                    >
                                        ¡Qué linda voz tienes! Ya puedes avanzar.
                                    </Text>
                                </Box>
                                : ""}

                            {typeTest === 'prototipo-de-figma' ?

                                <Button
                                    w={'190px'}
                                    height={'50px'}
                                    borderRadius={'30px'}
                                    fontSize={'1rem'}
                                    fontWeight={'600'}
                                    backgroundColor={'#7A628C'}
                                    color={'#FFFFFF'}
                                    disabled={testAudio ? false : true}
                                    _hover={testAudio && { backgroundColor: '#9983AA' }}
                                    _activeLink={testAudio && { backgroundColor: '#9983AA' }}
                                    _focus={testAudio && { backgroundColor: '#9983AA' }}
                                    _active={testAudio && { backgroundColor: '#9983AA' }}
                                    _disabled={{ backgroundColor: '#EAE9ED', color: '#9590A2', cursor: 'not-allowed' }}
                                    onClick={() => { setComprobacion(!comprobacion) }}
                                >
                                    Continuar
                                </Button>

                                :
                                <Button
                                    w={'190px'}
                                    height={'50px'}
                                    borderRadius={'30px'}
                                    fontSize={'1rem'}
                                    fontWeight={'600'}
                                    backgroundColor={'#7A628C'}
                                    color={'#FFFFFF'}
                                    disabled={testAudio ? false : true}
                                    _hover={{ backgroundColor: '#9983AA' }}
                                    _activeLink={{ backgroundColor: '#9983AA' }}
                                    _focus={{ backgroundColor: '#9983AA' }}
                                    _disabled={{ backgroundColor: '#EAE9ED', color: '#9590A2', cursor: 'not-allowed' }}
                                    onClick={handleCicle}
                                >
                                    Continuar
                                </Button>
                            }
                        </Box>

                }

            </Flex>
        </Container>

    )
}

export default PruebaMic
