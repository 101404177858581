import { Client } from "figma-js"

const client = Client({
    personalAccessToken: process.env.REACT_APP_FIGMA_KEY
});


export const getStartingPointFromApiFigma = async (url) => {
    const URL_REGEX = /https:\/\/([\w\.-]+\.)?figma.com\/(file|proto)\/([0-9a-zA-Z]{22,128})(?:\/.*)?$/
    const fileid = url.match(URL_REGEX)[3]

    try {
        const response = await client.file(fileid)
        return response.data.document.children[0].prototypeStartNodeID
    } catch (err) {
        console.log(err)
    }

}