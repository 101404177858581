import { Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'
import DeviceError from './DeviceError'
import { isIOS, isMobile } from 'react-device-detect'

const LimiteSuperado = ({ condicion, deviceExpected, deviceUser }) => {

    const handleRedir = () => {
        window.location.href = "https://testeadores.nular.co"
    }

    return (
        <Flex
            backgroundColor={'#F4EEF3'}
            flexDirection={'column'}
            height={isMobile ? (isIOS ? '100%' : `calc(var(--vh, 1vh) * 100)`) : '100vh'}
            color={'white'}
            justify={'center'}
            align={'center'}
            translate='no'
        >
            {condicion === 'limite' &&
                <Flex
                    flexDirection={'column'}
                    alignItems={'center'}
                    textAlign={'center'}
                >
                    <Text
                        fontSize={{ sm: '1.25rem', md: '1.375rem', lg: '1.8125rem', xl: '1.8125rem', base: '1.125rem' }}
                        fontWeight={'700'}
                        color={'#6C5280'}
                        lineHeight={{ sm: '1.5625rem', md: '1.75rem', lg: '2.125rem', xl: '2.125rem', base: '1.5625rem' }}
                    >
                        Esta investigación ha finalizado<br />porque ya se alcanzó el cupo de participantes
                    </Text>
                    <Text
                        marginY={'20px'}
                        fontSize={{ sm: '1.25rem', md: '1.375rem', lg: '1.8125rem', xl: '1.8125rem', base: '1rem' }}
                        fontWeight={'700'}
                        lineHeight={{ sm: '1.5625rem', md: '1.75rem', lg: '2.125rem', xl: '2.125rem', base: '1.5625rem' }}
                        color={'#F69260'}
                    >
                        ¡Muchas gracias por tu interés!
                    </Text>
                    <Text
                        fontSize={{ sm: '1rem', md: '1rem', lg: '1.175rem', xl: '1.175rem', base: '1rem' }}
                        fontWeight={'600'}
                        lineHeight={{ sm: '1.5625rem', md: '1.5625rem', lg: '2.125rem', xl: '2.125rem', base: '1.5625rem' }}
                        color={'#6C5280'}
                        marginBottom={'20px'}
                    >
                        Gana dinero participando de<br />investigaciones y encuestas pagas.
                    </Text>
                    <Link
                        to="/registro"
                        style={{
                            display: 'flex',
                            textDecoration: 'none',
                            borderRadius: '30px',
                            backgroundColor: '#F28853',
                            color: 'white',
                            width: '200px',
                            height: '45px',
                            fontSize: '1rem',
                            fontWeight: '600',
                            textAlign: 'center',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                        onClick={handleRedir}
                    >
                        Registrarme
                    </Link>
                </Flex>
            }

            {condicion === 'no existe' &&
                <Flex
                    flexDirection={'column'}
                    alignItems={'center'}
                    textAlign={'center'}
                >
                    <Text
                        fontSize={{ sm: '1.25rem', md: '1.375rem', lg: '1.8125rem', xl: '1.8125rem', base: '1.125rem' }}
                        fontWeight={'700'}
                        color={'#6C5280'}
                        lineHeight={{ sm: '1.5625rem', md: '1.75rem', lg: '2.125rem', xl: '2.125rem', base: '1.5625rem' }}
                    >
                        Esta investigación no existe<br />o tu sesión ha expirado
                    </Text>

                    <Text
                        marginY={'20px'}
                        fontSize={{ sm: '1.25rem', md: '1.375rem', lg: '1.8125rem', xl: '1.8125rem', base: '1rem' }}
                        fontWeight={'700'}
                        lineHeight={{ sm: '1.5625rem', md: '1.75rem', lg: '2.125rem', xl: '2.125rem', base: '1.5625rem' }}
                        color={'#F69260'}
                    >
                        ¡Muchas gracias por tu interés!
                    </Text>
                    <Text
                        fontSize={{ sm: '1rem', md: '1rem', lg: '1.175rem', xl: '1.175rem', base: '1rem' }}
                        fontWeight={'600'}
                        lineHeight={{ sm: '1.5625rem', md: '1.5625rem', lg: '2.125rem', xl: '2.125rem', base: '1.5625rem' }}
                        color={'#6C5280'}
                        marginBottom={'20px'}
                    >
                        Gana dinero participando de<br />investigaciones y encuestas pagas.
                    </Text>
                    <Link
                        to="/registro"
                        style={{
                            display: 'flex',
                            textDecoration: 'none',
                            borderRadius: '30px',
                            backgroundColor: '#F28853',
                            color: 'white',
                            width: '200px',
                            height: '45px',
                            fontSize: '1rem',
                            fontWeight: '600',
                            textAlign: 'center',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                        onClick={handleRedir}
                    >
                        Registrarme
                    </Link>
                </Flex>
            }

            {condicion === 'device error' && <DeviceError deviceExpected={deviceExpected} deviceUser={deviceUser} />}
        </Flex>
    )
}

export default LimiteSuperado